import * as React from "react";
import {useState, useEffect, Fragment, useContext, Component} from 'react';
import Header from "../components/Header";
import Sidebar from "./../components/sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import {Link, NavLink, Redirect} from "react-router-dom";
import {Card, Tab, Tabs} from "react-bootstrap";
import Graphiquearea from "../components/chart/Graphiquearea";
import Barrearea from "../components/chart/Barrearea";
import Radararea from "../components/chart/Radararea";
import Evolutionarea from "../components/chart/Evolutionarea";
import Spinner from 'react-bootstrap/Spinner';
import app from "./base.js";
import {auth} from "./base.js";
import {db} from "./base.js";
import {
    doc,
    onSnapshot,
    updateDoc,
    setDoc,
    deleteDoc,
    collection,
    serverTimestamp,
    getDocs,
    query,
    where,
    orderBy,
    limit,
} from 'firebase/firestore';
import {AuthContext, useUserAuth} from "./Auth";
import {useFirestoreDocument, useFirestoreQuery} from "@react-query-firebase/firestore";
import HomeScreenAdmin from "./HomeScreenAdmin";
import Sidebartemp from "../components/sidebartemp";
import PhoneNumber from "libphonenumber-js";

class HomeInter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            userid: "",
            superadmin: false,
            roles: [],
            loading: true,
            trouve: false,
        };
    }

    componentDidMount() {
        console.log("########################################");
        console.log(this.context);
        console.log("########################################");

        if(this.context.currentUser && this.context.currentUser.roles){
            let defaultRole = this.context.currentUser.roles[0];
            // this.goToPage(defaultRole.role, defaultRole.ecole, defaultRole.classe);
        }



        const colletionRefusers = collection(db, 'utilisateurs');
        const user = auth.currentUser;
        const parsedNumber = PhoneNumber(user.phoneNumber);
        const countryCode = parsedNumber.country;
        const allNumber = parsedNumber.number;
        const nationalNumber = parsedNumber.nationalNumber;
        const userid = ""

        console.log(user);
        const qusers = query(
            colletionRefusers,
            where('numero_tel', '==', nationalNumber)
        );

        const superadmin = false;
        onSnapshot(qusers, (querySnapshot) => {
            const users = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                users.push(data);
            });
            this.setState({ users });
            users.forEach((doc) => {
                onSnapshot(query( collection(db, 'utilisateurs/'+doc.uid+'/roles')), (querySnapshot) => {
                    const roles = [];
                    querySnapshot.forEach((doc) => {
                        const data = doc.data();
                        roles.push(data);
                    });
                    this.setState({ roles });
                    this.setState({loading : false})
                });
                if (this.state.roles.length){
                    this.setState({trouve : true})
                }else {
                    this.setState({trouve : false})
                }

                if (doc.superadmin === true){
                    this.setState({superadmin : true})
                }else {
                    return false
                }
                this.setState({ userid : doc.uid });
            });
        });
    }

    goToPage = (page, ecole, classe) => {
        if (page === "superadmin"){
            this.props.history.push(`/superadmin`);
        }else if (page === "admin"){
            this.props.history.push(`/admin/${ecole}`);
        }else if (page === "enseignant"){
            this.props.history.push(`/teacher/${ecole}/${classe}`);
        }else if (page === "eleve" ){
            if(this.state.currentUser){
                this.props.history.push(`/eleve/${ecole}/${this.state.currentUtilisateur.uid}/${classe}`);
            } else {
                this.props.history.push(`/eleve/${ecole}/${this.state.userid}/${classe}`);
            }
        }else {
            return false;
        }
    }

    goToPagesuper = () => {
        this.props.history.push(`/superadmin`);
    }

    render() {

        return (
            <div>

                <Sidebartemp/>
                <main className="main-wrap">
                    <Header history={this.props.history}/>

                    <Container>
                        <Row className="justify-content-md-center text-center" >
                            <Col sm={12}>
                                {this.state.users.map((user) => (
                                    <h2 className="title">Bienvenue, <span style={{color: "#1cb323"}}>
                                {user.prenom + " " + user.nom}
                                </span></h2>))}

                            </Col>
                            <Col sm={12}>
                                <h3 className="title">Vous voulez continuer en tant que...</h3>
                            </Col>

                        </Row>

                        <Row className="justify-content-md-center text-center" style={{paddingTop: "80px"}}>
                            {this.state.loading ? <Spinner animation="border" variant="success" /> : null}

                            <Col sm={4}  style={{ display: this.state.superadmin ? "block" : "none" }}>
                                <div
                                    className="card mx-auto"
                                >
                                    <div className="card-body">

                                        <button onClick={() => this.goToPagesuper() } type="button" className="btn btn-seconday text-center">
                                            Espace Super admin
                                        </button>

                                    </div>
                                </div>
                            </Col>
                            {this.state.roles.length ? (
                                <>
                                    {this.state.roles.map((role) => (
                                        <Col sm={4} >
                                            <div
                                                className="card mx-auto"
                                            >
                                                <div className="card-body">

                                                    <button onClick={() => this.goToPage(role.role, role.ecole, role.classe)} type="button" className="btn btn-seconday text-center">
                                                        {role.role} de l'école {role.ecole} {role.classe}
                                                    </button>

                                                </div>
                                            </div>
                                        </Col>
                                    ))}
                                </>
                            ):(
                                <>

                                    <h1  style={{ display: this.state.trouve ? "block" : "none" }} >Roles non trouvés</h1>

                                </>
                            )

                            }

                        </Row>
                    </Container>


                </main>
            </div>
        );
    }

}

HomeInter.contextType = AuthContext;

export default HomeInter;
