import * as React from "react";
import {Component} from "react";
import Header from "../../components/Header";
import Sidebarsuper from "../../components/sidebarsuper";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Form} from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import DataTableBase from '../DataTableBase';
import {
    FirebaseProvider,
    UTILISATEURS_COLLECTION_PATH,
    ECOLES_COLLECTION_PATH,
    CLASSES_COLLECTION_PATH,
    ROLES_COLLECTION_PATH
} from "../../providers/FirebaseProvider";
import {Utilisateur, Ecole, Classe, Role} from '../../models';
import {toTitleCase} from '../../utils/helpers'


class SuperUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            currentUser: new Utilisateur(),
            shouldShowModal: false,
            modalEditable: false,
            shouldShowDelete: false,

            rolesModalEditable: false,
            shouldShowRolesModal: false,

            ecoles: [],
            classes: [],
            currentEcole: new Ecole(),
            currentClasse: new Classe(),
            currentRole: new Role(),
        };

        this.dataTableCols = [
            {
                name: 'Nom',
                selector: row => toTitleCase(row.nom),
                grow: 1.25,
            },
            {
                name: 'Prénom',
                selector: row => toTitleCase(row.prenom),
                grow: 1.25,
            },
            {
                name: 'Sexe',
                selector: row => row.sexe,
            },
            {
                name: 'Pays',
                selector: row => toTitleCase(row.pays),
            },
            {
                name: 'Ville',
                selector: row => toTitleCase(row.ville),
            },
            {
                name: 'Actions',
                grow: 1.5,
                selector: row => {
                    return (
                        <div className="action-btns">
                            <Button variant="success" onClick={async () => this.showModal(row, false)}>
                                <i className="fa fa-eye"></i>
                            </Button>
                            <Button variant="secondary" onClick={async () => this.showModal(row, true)}>
                                <i className="fa fa-edit"></i>
                            </Button>
                            <Button variant="outline-dark" onClick={async () => this.showRolesModal(row, true)}>
                                <i className="fa fa-cog"></i>
                            </Button>
                            <Button variant="danger" onClick={async () => this.showDeleteModal(row)}>
                                <i className="fa fa-trash"></i>
                            </Button>
                        </div>
                    );
                }
            }
        ];
    }

    async componentDidMount() {
        // Init all
        FirebaseProvider.getAllDocsInCollection(UTILISATEURS_COLLECTION_PATH).then((usersObj) => {
            const users = [];
            for(let userId in usersObj){
                const user = new Utilisateur(usersObj[userId]);
                user.ref = UTILISATEURS_COLLECTION_PATH + '/' + userId;
                user.downloadRoles();

                users.push(user);
            }

            this.setState({users: users});
        });

        // Init ecoles and classes
        FirebaseProvider.getAllDocsInCollection(ECOLES_COLLECTION_PATH).then(async (ecolesObj) => {
            const ecoles = [];
            const allClasses = [];
            for(let ecoleId in ecolesObj){
                const ecole = new Ecole(ecolesObj[ecoleId]);
                ecole.ref = ECOLES_COLLECTION_PATH + '/' + ecoleId;

                ecole.classes = [];
                const classesPath = ecole.ref + '/' + CLASSES_COLLECTION_PATH;
                const classesObj = await FirebaseProvider.getAllDocsInCollection(classesPath);
                for(let classeId in classesObj){
                    let classe = classesObj[classeId];
                    classe.ref = classesPath + '/' + classeId;
                    classe = new Classe(classe);
                    
                    ecole.classes.push(classe);
                    allClasses.push(classe);
                }

                ecoles.push(ecole);
            }

            this.setState({ecoles: ecoles, classes: allClasses});
        });
    }

    showModal = (user, editable) => {
        this.setState({
            currentUser: user,
            shouldShowModal: true,
            modalEditable: editable
        });
    }

    closeModal = () => {
        this.setState({ shouldShowModal: false })
    }

    showDeleteModal = (user) => {
        this.setState({ currentUser: user })
        this.setState({ shouldShowDelete: true });
    }

    closeDeleteModal = () => {
        this.setState({ shouldShowDelete: false })
    }

    showRolesModal = (user, editable) => {
        console.log("aaaaaaaaaaaaaaaaaaaaaa", user);
        this.setState({
            currentUser: user,
            shouldShowRolesModal: true,
            rolesModalEditable: editable
        });
    }

    closeRolesModal = () => {
        this.setState({ shouldShowRolesModal: false })
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        const user = this.state.currentUser;
        user[name] = value;

        this.setState({
            currentUser: user
        });
    }

    handleRoleChange = (role, event) => {
        let targetRole = role;
        if(!role){
            targetRole = this.state.currentRole;
        }

        const { name, value } = event.target;
        targetRole[name] = value;

        if(!role){
            this.setState({
                currentRole: targetRole
            });
        } else {
            this.forceUpdate();
        }
    }

    saveUser = async () => {
        const creation = !this.state.currentUser.ref;
        let successMessage = "Utilisateur mis à jour avec succès";
        if(creation){
            successMessage = "Utilisateur créé avec succès";
        }

        try {
            await this.state.currentUser.save();
            const users = [...this.state.users];
            if(creation){
                users.push(this.state.currentUser);
            }

            this.setState({ users: users });
            toast.success(successMessage);
            this.closeModal();
        } catch (error) {
            console.error(error);
            toast.error('Une erreur est survenue');
        }
    }

    saveUserRoles = async () => {
        let successMessage = "Utilisateur mis à jour avec succès";

        try {
            let newRole = this.state.currentRole;
            let user = this.state.currentUser;
            if(newRole && newRole.isValid()){
                newRole.baseCollectionPath = user.ref + '/' + ROLES_COLLECTION_PATH;
                user.roles.push(newRole);
            }

            const promises = [];
            for(let role of user.roles){
                let ecole, classe, tmp;

                tmp = this.state.ecoles.filter((ecole) => ecole.ref.split('/').pop()===role.ecole);
                if(tmp && tmp.length != 0){
                    ecole = tmp[0];

                    tmp = ecole.classes.filter((classe) => classe.ref.split('/').pop()===role.classe);
                    if(tmp && tmp.length !=0){
                        classe = tmp[0];
                    }
                }

                role.role = role.role || "";
                switch(role.role.toLowerCase()){
                    case "superadmin":
                        user.superadmin=true;
                        break;
                    case "admin":
                        if(ecole){
                            const admins = new Set(ecole.admins);
                            admins.add(this.state.currentUser.uid);
                            ecole.admins = [...admins];
                            promises.push(ecole.save());
                        }
                        break;
                    case "enseignant":
                        if(ecole){
                            const enseignants = new Set(ecole.enseignants);
                            enseignants.add(this.state.currentUser.uid);
                            ecole.enseignants = [...enseignants];
                            promises.push(ecole.save());
                        }
                        if(classe){
                            const enseignants = new Set(classe.enseignants);
                            enseignants.add(this.state.currentUser.uid);
                            classe.enseignants = [...enseignants];
                            promises.push(classe.save());
                        }
                        break;
                    case "eleve":
                        if(classe){
                            const eleves = new Set(classe.eleves);
                            eleves.add(this.state.currentUser.uid);
                            classe.eleves = [...eleves];
                            promises.push(classe.save());
                        }
                        break;
                    default:
                        break;
                }

            }

            promises.push(user.save());
            await Promise.all(promises);

            const users = [...this.state.users];
            this.setState({ users: users });
            toast.success(successMessage);
            this.closeRolesModal();
        } catch (error) {
            console.error(error);
            toast.error('Une erreur est survenue');
        }
    }

    deleteUser = async () => {
        const user = this.state.currentUser;

        if(!user){
            return;
        }

        if(!user.ref){
            if(!user.id){
                return;
            }

            user.ref = UTILISATEURS_COLLECTION_PATH + '/' + user.id;
        }

        try {
            const res = await user.delete();
            if(res){
                const users = this.state.users.filter((item) => {
                            const eq = (item.ref && item.ref === user.ref)
                                || (item.id && item.id === user.id);

                            return !eq;
                        }
                    );
                
                this.setState({ users: users });
                toast.success("User supprimée avec succès");
            } else {
                toast.error('Une erreur est survenue');
            }
            this.closeDeleteModal();
        } catch (error) {
            console.error(error);
            toast.error('Une erreur est survenue');
        }
    }

    getClassesFromEcole = (ecoleId) => {
        if(!this.state.ecoles || !ecoleId){
            return [];
        }

        const tmp = this.state.ecoles.filter((ecole) => ecole.ref.split('/').pop()===ecoleId);
        if(tmp.length === 0){
            return [];
        }

        return tmp[0].classes || [];
    }


    render() {
        console.log("Rerenderinggggggggggggggggg");
        return (
            <div>
                <ToastContainer />
                <Sidebarsuper/>

                <main className="main-wrap">
                    <Header history={this.props.history}/>

                    <Container>
                        <Row className="welcome-greeting">
                            <Col sm={9}>
                                <h2 className="title">Liste des utilisateurs</h2>
                            </Col>

                            <Col sm={3}>
                                <Button style={{ marginRight:"5px" }} variant="outline-success" className="float-end" onClick={async () => this.showModal(new Utilisateur(), true)}>
                                    <i className="fa fa-plus"></i> Créer un utilisateur
                                </Button>
                            </Col>
                        </Row>

                        <div className="justify-content-md-center" style={{paddingTop: "80px"}}>

                            <DataTableBase style={{marginBottom: "40px"}}
                                columns={this.dataTableCols}
                                data={this.state.users}
                                dense
                            />

                            <Modal show={this.state.shouldShowDelete && this.state.currentUser} centered onHide={this.closeDeleteModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Confirmation</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>
                                        Etes vous sûr de supprimer l'utilisateur&nbsp; 
                                        <span className="fw-bold text-sucess">
                                            {this.state.currentUser.nom}
                                        </span>
                                        &nbsp;?
                                    </p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="success" onClick={this.closeDeleteModal}>
                                        Non
                                    </Button>
                                    <Button variant="danger" onClick={this.deleteUser}>
                                        Oui
                                    </Button>
                                </Modal.Footer>
                            </Modal>


                            <Modal show={this.state.shouldShowModal} scrollable centered size="xl" onHide={this.closeModal}>
                                <Modal.Header closeButton closeVariant="white" className="bg-success text-white">
                                    <Modal.Title className="fw-bold">
                                        {this.state.currentUser && this.state.currentUser.ref ? toTitleCase(this.state.currentUser.nom) + " " + toTitleCase(this.state.currentUser.prenom) : "Créer utilisateur"}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form id="form-user" onSubmit={(e) => {e.preventDefault(); this.saveUser();}} action="#">
                                        <Container>
                                            <Row>
                                                <Col sm={3} className="text-center">
                                                    <Image src={this.state.currentUser.imageUrl ?? "/images/user.png"} thumbnail fluid />
                                                </Col>
                                                <Col sm={9}>
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label className="fw-bold">Nom</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            autoFocus
                                                            value={this.state.currentUser.nom}
                                                            onChange={this.handleChange}
                                                            disabled={!this.state.modalEditable}
                                                            name="nom"
                                                            required
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label className="fw-bold">Prénom</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            autoFocus
                                                            value={this.state.currentUser.prenom}
                                                            onChange={this.handleChange}
                                                            disabled={!this.state.modalEditable}
                                                            name="prenom"
                                                            required
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label className="fw-bold">Sexe</Form.Label>
                                                        <Form.Select value={this.state.currentUser.sexe} onChange={this.handleChange} disabled={!this.state.modalEditable} name="sexe" required>
                                                            <option>--Choisir--</option>
                                                            <option value="Femme" >Femme</option>
                                                            <option value="Homme" >Homme</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                    
                                                </Col>                                                        
                                            </Row>
                                            <Row>
                                                <Col sm={6}>                                                    
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="fw-bold">Date de naissance</Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            autoFocus
                                                            value={this.state.currentUser.dateNaissance}
                                                            onChange={this.handleChange}
                                                            disabled={!this.state.modalEditable}
                                                            name="dateNaissance"
                                                            required
                                                        />
                                                    </Form.Group>  
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="fw-bold">Email</Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            autoFocus
                                                            value={this.state.currentUser.email}
                                                            onChange={this.handleChange}
                                                            disabled={!this.state.modalEditable}
                                                            name="email"
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label className="fw-bold">Indicatif</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            autoFocus
                                                            value={this.state.currentUser.codeCountry}
                                                            onChange={this.handleChange}
                                                            disabled={!this.state.modalEditable}
                                                            name="codeCountry"
                                                        />
                                                    </Form.Group>                                                 
                                                </Col>
                                                <Col sm={6}>
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label className="fw-bold">Pays</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            autoFocus
                                                            value={this.state.currentUser.pays}
                                                            onChange={this.handleChange}
                                                            disabled={!this.state.modalEditable}
                                                            name="pays"
                                                            required
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label className="fw-bold">Ville</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            autoFocus
                                                            value={this.state.currentUser.ville}
                                                            onChange={this.handleChange}
                                                            disabled={!this.state.modalEditable}
                                                            name="ville"
                                                            required
                                                        />
                                                    </Form.Group>                                 
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label className="fw-bold">Téléphone</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            autoFocus
                                                            value={this.state.currentUser.numero_tel}
                                                            onChange={this.handleChange}
                                                            disabled={!this.state.modalEditable}
                                                            name="numero_tel"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Form>
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="danger" onClick={this.closeModal}>
                                        Fermer
                                    </Button>
                                    <Button variant="success" form="form-user" type="submit" className={this.state.modalEditable ? "" : "d-none"}>
                                        Enregistrer
                                    </Button>
                                </Modal.Footer>
                            </Modal>


                            <Modal show={this.state.shouldShowRolesModal} scrollable centered size="lg" onHide={this.closeRolesModal}>
                                <Modal.Header closeButton closeVariant="white" className="bg-success text-white">
                                    <Modal.Title className="fw-bold">
                                        Roles de {this.state.currentUser ? toTitleCase(this.state.currentUser.nom) + " " + toTitleCase(this.state.currentUser.prenom) : ""}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form id="form-roles" onSubmit={(e) => {e.preventDefault(); this.saveUserRoles();}} action="#">
                                        <Container>
                                            {this.state.currentUser &&
                                                (!this.state.currentUser.roles || !Array.isArray(this.state.currentUser.roles) || this.state.currentUser.roles.length == 0 ?
                                                    <Row className="mb-4">
                                                        <Col sm={12}>
                                                            <h6 className="">Aucun rôle pour l'instant.</h6>
                                                        </Col>
                                                    </Row>
                                                 : 
                                                    <>
                                                        <Row className="mb-2">
                                                            <Col sm={4}>
                                                                <div className="fw-bold">Role</div>
                                                            </Col>
                                                            <Col sm={4}>
                                                                <div className="fw-bold">Ecole</div>
                                                            </Col>
                                                            <Col sm={4}>
                                                                <div className="fw-bold">Classe</div>
                                                            </Col>
                                                        </Row>

                                                        {this.state.currentUser.roles.map((role) => 
                                                            <Row key={role.ref}>
                                                                <Col sm={4}>
                                                                    <Form.Group className="mb-3" >
                                                                        <Form.Select value={role.role}
                                                                            onChange={(e)=>{this.handleRoleChange(role, e)}}
                                                                            disabled={!this.state.rolesModalEditable}
                                                                            name="role"
                                                                            required
                                                                        >
                                                                            <option value="">--Choisir--</option>
                                                                            <option value="eleve" >Eleve</option>
                                                                            <option value="enseignant" >Enseignant</option>
                                                                            <option value="admin" >Admin</option>
                                                                            <option value="superadmin" >Superadmin</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col sm={4}>
                                                                    <Form.Group className="mb-3" >
                                                                        <Form.Select
                                                                            value={role.ecole}
                                                                            onChange={(e)=>{this.handleRoleChange(role, e)}}
                                                                            disabled={!this.state.rolesModalEditable || !role.role || role.role==="superadmin"}
                                                                            name="ecole"
                                                                            required={role.role && role.role!="superadmin"}
                                                                            >
                                                                                <option value="">--Choisir--</option>
                                                                                {
                                                                                    this.state.ecoles && Array.isArray(this.state.ecoles) && 
                                                                                    this.state.ecoles.map((ecole) => 
                                                                                        <option key={role.ref+ecole.ref}
                                                                                            value={ecole.ref.split('/').pop()}>
                                                                                            {ecole.nom}
                                                                                        </option>)
                                                                                }
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col sm={4}>
                                                                    <Form.Group className="mb-3" >
                                                                        <Form.Select value={role.classe}
                                                                            onChange={(e)=>{this.handleRoleChange(role, e)}}
                                                                            disabled={!this.state.rolesModalEditable || !role.role || role.role==="superadmin" || role.role==="admin"}
                                                                            name="classe"
                                                                            required={role.role && role.role!="superadmin" && role.role!="admin"}>
                                                                            <option value="">--Choisir--</option>
                                                                            {
                                                                                this.getClassesFromEcole(role.ecole).map((classe) =>
                                                                                    <option key={role.ref+classe.ref}
                                                                                        value={classe.ref.split('/').pop()}>
                                                                                        {classe.nom}
                                                                                    </option>)
                                                                            }
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                    </>
                                                )
                                            }

                                            <hr/>
                                            <Row>
                                                <Col sm={12}>
                                                    <h4 className="mb-3" >
                                                        Ajouter un rôle
                                                    </h4>
                                                </Col>
                                                <Col sm={4}>
                                                    <Form.Group className="mb-3" >
                                                        <Form.Select
                                                            onChange={(e)=>{this.handleRoleChange(null, e)}}
                                                            name="role"
                                                            disabled={!this.state.rolesModalEditable}
                                                        >
                                                            <option value="">--Choisir--</option>
                                                            <option value="eleve" >Eleve</option>
                                                            <option value="enseignant" >Enseignant</option>
                                                            <option value="admin" >Admin</option>
                                                            <option value="superadmin" >Superadmin</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={4}>
                                                    <Form.Group className="mb-3" >
                                                        <Form.Select
                                                            onChange={(e)=>{this.handleRoleChange(null, e)}}
                                                            name="ecole"
                                                            disabled={!this.state.rolesModalEditable || !this.state.currentRole.role || this.state.currentRole.role==="superadmin"}
                                                            required={this.state.currentRole.role && this.state.currentRole.role!="superadmin"}
                                                            >
                                                                <option value="">--Choisir--</option>
                                                                {
                                                                    this.state.ecoles && Array.isArray(this.state.ecoles) && 
                                                                    this.state.ecoles.map((ecole) =>
                                                                        <option key={this.state.currentRole.ref+ecole.ref}
                                                                            value={ecole.ref.split('/').pop()}
                                                                            >
                                                                            {ecole.nom}
                                                                        </option>)
                                                                }
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={4}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Select
                                                            onChange={(e)=>{this.handleRoleChange(null, e)}}
                                                            name="classe"
                                                            disabled={!this.state.rolesModalEditable || !this.state.currentRole.role || this.state.currentRole.role==="superadmin" || this.state.currentRole.role==="admin"}
                                                            required={this.state.currentRole.role && this.state.currentRole.role!="superadmin" && this.state.currentRole.role!="admin"}
                                                        >
                                                            <option value="">--Choisir--</option>
                                                            {
                                                                this.getClassesFromEcole(this.state.currentRole.ecole).map((classe) => 
                                                                    <option
                                                                        key={this.state.currentRole.ref+classe.ref}
                                                                        value={classe.ref.split('/').pop()}
                                                                        >
                                                                        {classe.nom}
                                                                    </option>)
                                                            }
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Form>
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="danger" onClick={this.closeRolesModal}>
                                        Fermer
                                    </Button>
                                    <Button variant="success" form="form-roles" type="submit" className={this.state.rolesModalEditable ? "" : "d-none"}>
                                        Enregistrer
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                        </div>


                    </Container>


                </main>
            </div>
        );
    }

}

export default SuperUser;
