import {Model} from './Model';
import {Role} from './Role';
import {FirebaseProvider, UTILISATEURS_COLLECTION_PATH, ROLES_COLLECTION_PATH} from '../providers/FirebaseProvider';

export class Utilisateur extends Model {
	constructor(params){
		super(params);
		this.roles = this.roles || [];
		this.baseCollectionPath = this.baseCollectionPath || UTILISATEURS_COLLECTION_PATH;
	}

	async download(){
		if(!this.ref){
			return;
		}
		await super.download();
		await this.downloadRoles();
	}

	async downloadRoles() {
		let rolesPath = this.ref + '/' + ROLES_COLLECTION_PATH;
		let roles = await FirebaseProvider.getAllDocsInCollection(rolesPath);

		// Parse them to 'Role' type
		this.roles = [];
		for(let roleId in roles){
			let currentRole = roles[roleId];

			currentRole.ref = rolesPath + '/' + roleId;
			currentRole.baseCollectionPath = rolesPath;
			this.roles.push(new Role(currentRole));
		}

		// Add superadmin role
		if(this.superadmin){
			let params = {role: 'superadmin'};
			this.roles.push(new Role(params));
		}

		// Sort roles
		this.roles.sort(function (role1, role2){
			const rolesOrder = {
				'superadmin': 0,
				'admin': 1,
				'enseignant': 2,
				'eleve': 3
			}

			let r1 = rolesOrder[role1.role.toLowerCase()] || 10;
			let r2 = rolesOrder[role2.role.toLowerCase()] || 10;
			return r2 - r1;
		});
	}

	async save(){
		const copy = Object.assign({}, this);
		delete this.roles;

		const promises = [super.save(), Utilisateur.saveRoles(copy)];
		const res = await Promise.all(promises);
		this.roles = copy.roles;
		return res[0];
	}

	static async saveRoles(instance){
		const promises = [];

		if(instance.roles){
			for(let role of instance.roles){
				if(!role.ref){
					role.baseCollectionPath = instance.ref + '/' + ROLES_COLLECTION_PATH;
				}
				if(role.role != "superadmin"){
					promises.push(role.save());
				}
			}
		}

		return await Promise.all(promises);
	}
}