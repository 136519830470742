import React from "react";
import Header from "../components/Header";
import Sidebar from "./../components/sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import {Link} from "react-router-dom";

const ProfilScreen = (props) => {

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header history={props.history}/>

          <div
              className="profiledit"
          >
              <div className="card-body">
                  <h4 className="card-title">Mon compte</h4>

                  <form>
                      <div className="form-floating" style={{ marginTop:"40px" }}>
                          <input
                              className="form-control"
                              type="text"
                              id="name"
                          />
                          <label style={{ color:"green", fontSize:"16px"}} htmlFor="name">Nom d'utilisateur</label>
                      </div>

                      <div className="form-floating" style={{ marginTop:"10px" }}>
                          <input
                              className="form-control"
                              type="text"
                              id="surname"
                          />
                          <label style={{ color:"green", fontSize:"16px"}} htmlFor="surname">Prénom de l'utilisateur</label>
                      </div>

                      <div className="form-floating" style={{ marginTop:"10px" }}>
                          <input
                              className="form-control"
                              type="date"
                              id="age"
                          />
                          <label style={{ color:"green", fontSize:"16px"}} htmlFor="age">Entrez votre date de naissance</label>
                      </div>

                      <div className="form-floating" style={{ marginTop:"10px" }}>
                          <input
                              className="form-control"
                              type="text"
                              id="sexe"
                          />
                          <label style={{ color:"green", fontSize:"16px"}} htmlFor="sexe">Sexe ( M / F )</label>
                      </div>

                      <div className="form-floating" style={{ marginTop:"10px" }}>
                          <input
                              className="form-control"
                              type="email"
                              id="email"
                          />
                          <label style={{ color:"green", fontSize:"16px"}} htmlFor="email">Adresse mail</label>
                      </div>

                      <div style={{ marginTop:"30px" , marginBottom:"20px" }}>
                          <button type="submit" className="btn btn-primary w-100">
                              Enregistrer
                          </button>
                      </div>
                  </form>

              </div>
          </div>


      </main>
    </>
  );
};

export default ProfilScreen;
