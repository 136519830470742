import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    Colors
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    Colors,
);



const NotesRadarChart = ({notesHistory, matiere, backgroundColor, borderColor}) => {
    if(!notesHistory || Object.keys(notesHistory).length==0){
        return <div className="my-5 text-center">Aucune donnée à afficher</div>;
    }

    const options = {
        responsive: true,
        aspectRatio: 16/11,
        plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Temps passé',
            },
            tooltip: {
                mode: 'index'
            },
        },
        interaction: {
          mode: 'nearest',
          axis: 'x',
          intersect: false
        },
        scales: {
            x: {
                title: {
                  display: true,
                  text: 'Matière'
                }
              },
            y: {
                stacked: true,
                title: {
                  display: true,
                  text: "Note (/20)"
                }
              }
          },
        layout: {
            padding: {
                top: 20,
                bottom: 20
            }
        }
    };

    
    const labels = [];
    const values = [];
    for(let mat of Object.keys(notesHistory)){
        if(mat === 'global'){
            continue;
        }

        labels.push(mat.split("/").pop());

        // Get the last value for this matiere
        const lastDate = Object.keys(notesHistory[mat]).sort().pop();
        values.push(notesHistory[mat][lastDate] / 5); // Conversion to grades over 20
    }

    const data = {
        labels: labels,
        datasets: [
            {
                label: matiere || 'Global',
                data: values,
                backgroundColor: backgroundColor || 'rgba(65,105,225, 0.4)',
                borderColor: borderColor || 'rgba(65,105,225, 1)',
                borderWidth: 4,
            },
        ],
    };

    return (
        <Radar options={options} data={data}></Radar>
    );
};

export default NotesRadarChart;
