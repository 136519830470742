import {Model} from './Model';
import {
	FirebaseProvider,
	ECOLES_COLLECTION_PATH,
	CLASSES_COLLECTION_PATH,
} from "../providers/FirebaseProvider"


export class Ecole extends Model {
	constructor(params){
		super(params);
		this.baseCollectionPath = this.baseCollectionPath || ECOLES_COLLECTION_PATH;
	}

	async save() {
		if(this.ref){
			return super.save();
		}

		// Create new ecole
		let copy = Object.assign({}, this);
		delete copy.jsonObject;
		const res = await FirebaseProvider.addDocToCollection(ECOLES_COLLECTION_PATH, copy);

		// this.ref = ECOLES_COLLECTION_PATH + '/' + res.id;
		// await FirebaseProvider.createCollection(this.ref + '/' + CLASSES_COLLECTION_PATH);

		return res;
	}

	async delete() {
		if(!this.ref){
			return;
		}

		// Delete 'classes' subcollection first
		const classesPath = this.ref + '/' + CLASSES_COLLECTION_PATH;
		await FirebaseProvider.deleteAllDocsAtPath(classesPath);

		// Then delete this ecole document
		return await super.delete();
	}
}