import React from "react";
import { Link, NavLink } from "react-router-dom";
import Image from 'react-bootstrap/Image'
import {getAuth} from "firebase/auth";
import app from "../screens/base";
import {Button} from "react-bootstrap";

const Sidebartemp = () => {
  const out = () => {
    const auth = getAuth(app);
    auth.signOut();
  }
  return (
    <div>
      <aside className="navbar-aside" id="offcanvas_aside">
        <div className="aside-top">
          <Link to="/" className="brand-wrap">
            <Image src="/images/logo.png" fluid
              style={{ width: "80%" }}
              alt="Skoole dash"
            />
          </Link>
          <div>
            <button className="btn btn-icon btn-aside-minimize">
              <i className="text-muted fas fa-stream"></i>
            </button>
          </div>
        </div>

        <nav style={{ paddingTop:"30px" }}>
          <ul className="menu-aside">
            <li className="menu-item">
              <NavLink
                activeClassName="active"
                className="menu-link"
                to="/"
                exact={true}
              >
                <i className="icon fas fa-home"></i>
                <span className="text">Acceuil de choix</span>
              </NavLink>
            </li>

            <li className="menu-item" onClick={out}>
              <a href="#" className="menu-link" >
                <i className="icon fas fa-sign-out-alt" style={{ color:"indianred" }}></i>
                <span className="text" style={{ color:"indianred" }}>Déconnexion</span>
              </a>
            </li>


          </ul>
          <br />
          <br />
        </nav>
      </aside>
    </div>
  );
};

export default Sidebartemp;
