import * as React from "react";
import { useState, useEffect, Fragment, useContext, Component } from 'react';
import { withRouter } from "react-router-dom";
import Header from "../components/Header";
import Sidebar from "./../components/sidebar";
import Container from "react-bootstrap/Container";
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import {Link, NavLink, Redirect} from "react-router-dom";
import {Card, Tab, Tabs, Button} from "react-bootstrap";
import Graphiquearea from "../components/chart/Graphiquearea";
import Barrearea from "../components/chart/Barrearea";
import Radararea from "../components/chart/Radararea";
import Evolutionarea from "../components/chart/Evolutionarea";
import Evolutionareamat from "../components/chart/Evolutionareamat";
import app from "./base.js";
import {auth} from "./base.js";
import {db} from "./base.js";
import PhoneNumber from 'libphonenumber-js';
import {
    doc,
    onSnapshot,
    updateDoc,
    setDoc,
    deleteDoc,
    collection,
    serverTimestamp,
    getDocs,
    query,
    where,
    orderBy,
    limit,
} from 'firebase/firestore';
import {AuthContext, useUserAuth} from "./Auth";
import Spinner from "react-bootstrap/Spinner";

import Barreareamat from "../components/chart/Barreareamat";
import redirect from "react-router-dom/es/Redirect";


import DataTableBase from '../components/DataTableBase';
import {
    FirebaseProvider,
    ECOLES_COLLECTION_PATH,
    CLASSES_COLLECTION_PATH,
    UTILISATEURS_COLLECTION_PATH,
    STATS_COLLECTION_PATH
} from "../providers/FirebaseProvider";
import {Ecole, Classe, Utilisateur} from '../models';
import {CompetencesChart, DurationHistoryChart, NotesHistoryChart, NotesRadarChart} from '../components/chart';
import {computeProgramCoverage, computeDurationHistory, computeNotesHistory} from '../utils/stats_functions';
import {toTitleCase} from '../utils/helpers';
import {colormap} from '../utils/chart_colormap';


class HomeScreenAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            totaleleveec: 0,
            totalelevecl: 0,
            roles: [],
            sonRole: "",
            ecole: [],
            classes: [],
            eleves: [],
            elevesliste: [],
            elevesclasse: [],
            elevesuid: [],
            teachers: [],
            loadingecole : false,
            loadingusers : false,
            loadingeleves : false,
            loadingteachers : false,
            loadingclasses : false,
            nomec: [],
            statsec: [],
            displayec: true,

            nomc: [],
            statsc: [],
            moyenneG: 0,
            moyenneMax: 0,
            moyenneMin: 0,
            moyenneGgros: 0,
            displayc: false,

            nomel: [],
            nomvrai: [],
            statsel: [],
            notesel: [],
            matieres: [],
            statscompris: [],
            statssuperfi: [],
            statsnonfaits: [],
            isLoadingcompris: true,
            isLoadingnoncompris: true,
            isLoadingsuperfi: true,
            isLoadingnonfaits: true,
            statsnoncompris: [],
            statscompetences: [],
            statsnotes: [],
            statstemps: [],
            matsolo: "",
            matsolocompe: "",
            matsoloevolu: "",
            displayel: false,
            stateSelect: false,



            currentEcole: new Ecole(),
            allStudents: [],
            studentsToShow: [],

            allStats: [],
            statsOfSelectedUsers: [],
            currentStats: [],

            selectedClasses: new Set(),
            selectedMatieres: new Set(),

            competencesHistory: {},
            durationHistory: {},
            notesHistory: {},
            globalStats : {
                min: 0,
                max: 0,
                avg: 0
            }
        };

        this.dataTableCols = [
            {
                name: 'Nom',
                selector: row => toTitleCase(row.nom),
            },
            {
                name: 'Prénom',
                selector: row => toTitleCase(row.prenom),
            },
            {
                name: 'Classe',
                selector: row => row.classe ? row.classe.nom : "",
            },
        ]
    }

    async componentDidMount() {
        const { idecole } = this.props.match.params;

        const ecolePath = ECOLES_COLLECTION_PATH + '/' + idecole;
        const classesPath = ecolePath + '/' + CLASSES_COLLECTION_PATH;
        const ecole = new Ecole({ref: ecolePath});

        // Download the school data and its classes in parallel
        const ecolePromise = ecole.download();
        const classesPromise = FirebaseProvider.getAllDocsInCollection(classesPath);
        const [res, classesObj] = await Promise.all([ecolePromise, classesPromise]);

        // Parse the obtained classes to real 'Classe' type
        const classes = [];
        const elevesDownloadCompletedPromises = [];
        for(let classeId in classesObj){
            let classe = classesObj[classeId];
            classe.ref = classesPath + '/' + classeId;
            classe = new Classe(classe);
            classes.push(classe);

            // Get students of this classe and their corresponding stats
            if(classe.eleves.length == 0){
                continue;
            }

            // Create a promise which will resolve only when all students and their stats will be downloaded
            const statsOfElevePromises = [];
            const elevesOfClassePromise = new Promise((resolve, reject) => {
                FirebaseProvider.queryCollectionWithCondition(UTILISATEURS_COLLECTION_PATH,
                    'uid', 'in', classe.eleves
                ).then((elevesObj) => {
                    let eleves = [];
                    for(let eleveId in elevesObj){
                        let eleve = elevesObj[eleveId];
                        eleve.ref = UTILISATEURS_COLLECTION_PATH + '/' + eleveId;
                        eleve = new Utilisateur(eleve);
                        eleve.classe = classe;
                        eleves.push(eleve);

                        // Get stats of this student
                        const statsPath = eleve.ref + '/' + STATS_COLLECTION_PATH;
                        const statsOfElevePromise = FirebaseProvider.getAllDocsInCollection(statsPath)
                        .then((statsObj) => {
                            let stats = [];
                            for(let statId in statsObj){
                                const stat = statsObj[statId];
                                stat.eleve = eleve;
                                stat.classe = classe;
                                stat.matiere = stat.exo ? stat.exo.split('/')[5] : "";

                                stats.push(stat);
                            }

                            eleve.stats = stats;
                        });
                        statsOfElevePromises.push(statsOfElevePromise);
                    }

                    Promise.all(statsOfElevePromises).then(() => {
                            classe.eleves = eleves;
                            resolve();
                        });
                });
            });

            elevesDownloadCompletedPromises.push(elevesOfClassePromise);
        }

        // Wait for all the data to be downloaded
        await Promise.all(elevesDownloadCompletedPromises);
        ecole.classes = classes;

        // Arrays containing all students and all their stats
        const allStudents = classes.reduce(
            (tmpAccumulator, currentClasse) => [...new Set([...tmpAccumulator, ...currentClasse.eleves])],
            []
        );
        const allStats = allStudents.reduce(
            (tmpAccumulator, currentEleve) => [...new Set([...tmpAccumulator, ...currentEleve.stats])],
            []
        );
        this.customUpdateState({
            currentEcole: ecole,
            allStudents: allStudents,
            studentsToShow: allStudents,
            allStats: allStats,
            statsOfSelectedUsers: allStats,
            currentStats: allStats
        });
    }

    onSelectedRowsChange = (params) => {
        const studentsIds = params.selectedRows.map((student) => student.uid);
        const statsOfSelectedUsers = this.state.allStats.filter((stat) => studentsIds.includes(stat.eleve.uid));
        this.customUpdateState({
            statsOfSelectedUsers: statsOfSelectedUsers,
            currentStats: statsOfSelectedUsers,
        });
    }

    toggleClasseSelection = (classe) => {
        const newClassesSet = new Set(this.state.selectedClasses);
        if(!classe){
            newClassesSet.clear();
            this.customUpdateState({
                selectedClasses: newClassesSet,
                studentsToShow: this.state.allStudents,
                statsOfSelectedUsers: this.state.allStats,
                currentStats: this.state.allStats,
            });
            return;
        }

        if(newClassesSet.has(classe.ref)){
            newClassesSet.delete(classe.ref);
        } else {
            newClassesSet.add(classe.ref);
        }

        if(newClassesSet.size === 0){
            this.customUpdateState({
                selectedClasses: newClassesSet,
                studentsToShow: this.state.allStudents,
                statsOfSelectedUsers: this.state.allStats,
                currentStats: this.state.allStats,
            });
            return;
        }


        const studentsToShow = this.state.allStudents.filter((student) => newClassesSet.has(student.classe.ref));
        const studentsIds = studentsToShow.map((student) => student.uid);
        const statsOfSelectedUsers = this.state.allStats.filter((stat) => studentsIds.includes(stat.eleve.uid));

        this.customUpdateState({
            selectedClasses: newClassesSet,
            studentsToShow: studentsToShow,
            statsOfSelectedUsers: statsOfSelectedUsers,
            currentStats: statsOfSelectedUsers,
        });
    }

    toggleMatiereSelection = (matiere) => {
        const newMatieresSet = new Set(this.state.selectedMatieres);
        if(!matiere){
            newMatieresSet.clear();
            this.customUpdateState({
                selectedMatieres: newMatieresSet,
                currentStats: this.state.statsOfSelectedUsers,
            });
            return;
        }

        if(newMatieresSet.has(matiere)){
            newMatieresSet.delete(matiere);
        } else {
            newMatieresSet.add(matiere);
        }


        if(newMatieresSet.size === 0){
            this.customUpdateState({
                selectedMatieres: newMatieresSet,
                currentStats: this.state.statsOfSelectedUsers,
            });
            return;
        }

        const currentStats = this.state.statsOfSelectedUsers.filter((stat) => newMatieresSet.has(stat.matiere));
        this.customUpdateState({
            selectedMatieres: newMatieresSet,
            currentStats: currentStats,
        });
    }

    customUpdateState = (stateUpdate) => {
        Object.assign(this.state, stateUpdate);
        this.computeStats();     
    }

    computeStats = () => {
        console.log(this.state.currentStats);

        const programCoverage = computeProgramCoverage(this.state.currentStats);
        const durationHistory = computeDurationHistory(this.state.currentStats);
        const notesHistory = computeNotesHistory(this.state.currentStats);

        console.log("zzzzzzzzzzzzzzzzzzzzzzzzzzzzz", programCoverage);
        console.log("yyyyyyyyyyyyyyyyyyyyyyyyyyyyy", durationHistory);
        console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxx", notesHistory);


        this.state.competencesHistory = programCoverage;
        this.state.notesHistory = notesHistory;
        this.state.durationHistory = durationHistory;


        if(this.state.currentStats){
            const groupedStats = this.state.currentStats.reduce((acc, stat) => {
                const tmp = acc[stat.eleve.ref] || [];
                tmp.push(stat);
                acc[stat.eleve.ref] = tmp;
                return acc;
              }, {});

            console.log("ooooooooooooooooooo", groupedStats);
            const avgs = Object.keys(groupedStats).map((studentId) => {
                const sum = groupedStats[studentId].reduce((acc, val) => acc+val.note, 0);
                return (sum / groupedStats[studentId].length) / 5; // Conversion back to grade over 20
            });

            let min=1000, max=-1, sum=0, count=0;
            for(let val of avgs){
                if(!val){
                    continue;
                }

                min = Math.min(min, val);
                max = Math.max(max, val);
                sum += val;
                count++;
            }

            if(count != 0){
                const avg = sum/count;
                this.state.globalStats = {
                    min: min,
                    max: max,
                    avg: avg
                };                
            }
        }

        this.forceUpdate();
    }



    changeEcole = (nomec, elevesliste, displayec, displayel, displayc) => {
        this.setState({nomec});
        //this.setState({statsec});
        this.setState({displayec});
        this.setState({displayel});
        this.setState({displayc});

        this.setState(elevesliste);
    }

    changeTempsmat = (matsolo) => {
        this.setState({matsolo});
    }

    changeTempsmatevolu = (matsoloevolu) => {
        this.setState({matsoloevolu});
    }

    changeTempsmatcompe = (matsolocompe) => {
        this.setState({matsolocompe});
    }

    changeTempsTout = () => {
        this.setState({matsolocompe : ""});
    }

    changeTempsToutEvolu = () => {
        this.setState({matsoloevolu : ""});
    }

    changeEleve = async  (nomel, nomvrai, displayec, displayel, displayc) => {

        this.setState({nomel});
        this.setState({nomvrai});
        this.setState({displayec});
        this.setState({displayel});
        this.setState({displayc});
        this.setState({matsolocompe : ""})
        this.setState({matsoloevolu : ""})

        this.state.elevesliste.forEach((elev) => {

            if (elev[0] === nomel){

                elev.splice(4, 1, true);

            }else {
                elev.splice(4, 1, false);
            }

        })

        this.state.elevesclasse.forEach((elev) => {

            if (elev[0].uid === nomel){

                elev.splice(1, 1, true);

            }else {
                elev.splice(1, 1, false);
            }

        })

        await onSnapshot(query( collection(db, 'utilisateurs/'+nomel+'/stats'), orderBy("date","asc") ), (querySnapshot) => {
            const statsel = [];
            const notesel = [];
            const matieres = [];
            let previousValue = null;
            querySnapshot.forEach((doc) => {
                const currentValue = (doc.data().title);
                const isValuePresent = statsel.some(obj => obj['title'] === currentValue);
                const currentmatiere = (doc.data().exo.split("/")[5]);
                const isValuePresentmat = matieres.includes(currentmatiere);

                if (isValuePresent) {
                    return false;
                } else {
                    statsel.push(doc.data());
                    notesel.push(doc.data().note);
                }

                if (!isValuePresentmat) {
                    matieres.push(doc.data().exo.split("/")[5])
                }else {
                    return false
                }

            });
            this.setState({statsel});
            this.setState({notesel});
            this.setState({matieres});

            const statscompris = [];
            const statssuperfi = [];
            const statsnonfaits = [];
            const statscompetences = [];
            const statsnoncompris = [];

            this.state.statsel.forEach((stat) => {
                if (stat.note >= 80){
                    statscompris.push(stat.title);
                    //statscompetences.push([stat.date.substring(0,4) , parseInt(stat.note.toFixed(2)), 0 ]);

                }else if (stat.note < 80 && stat.note >= 50){
                    statssuperfi.push(stat.title);
                }else{
                    statsnoncompris.push(stat.title);
                }
            });

            this.setState({statscompris});
            this.setState({statsnoncompris});
            this.setState({statssuperfi});
            this.setState({statsnonfaits});
            this.setState({statscompetences});
            this.setState({isLoadingnoncompris : false})
            this.setState({isLoadingsuperfi : false})

        });

    }

    changeClasse = async (nomc, statsc, nbreeleve, displayec, displayel, displayc) => {
        this.setState({nomc});
        this.setState({statsc});
        this.setState({displayec});
        this.setState({displayel});
        this.setState({displayc});

        const totalelevecl = nbreeleve;
        this.setState({totalelevecl});
        const elevesclasse = []

        statsc.forEach((stat) => {

            onSnapshot(query( collection(db, 'utilisateurs'), where('uid' , '==', stat ) ), (querySnapshot) => {

                querySnapshot.forEach((doc) => {
                    elevesclasse.push([doc.data(),false]);
                });
                this.setState({elevesclasse});
            });

            onSnapshot(query( collection(db, 'utilisateurs/'+stat+'/stats'), orderBy("date", "asc") ), (querySnapshot) => {
                let moyenneG = 0;
                let sumStat = 0;
                let countexo = 1;
                const matierenow = []
                const moyenneTot = []
                querySnapshot.forEach((doc) => {
                    const currentmatiere = (doc.data().exo.split("/")[5]);
                    const isValuePresentmat = matierenow.includes(currentmatiere);

                    if (!isValuePresentmat) {
                        countexo = 1;
                        matierenow.push(doc.data().exo.split("/")[5])
                        sumStat = sumStat + ((doc.data().note)/5)
                        moyenneTot.push(sumStat);
                    }else {
                        countexo = countexo + 1;
                        const index = matierenow.indexOf(currentmatiere);
                        const ancienmoy = moyenneTot[index]
                        const newmoy = (ancienmoy + ((doc.data().note)/5) ) / countexo;
                        moyenneTot.splice(index, 1, newmoy)
                    }
                });
                const taillemoy = moyenneTot.length;
                moyenneTot.forEach((moy) => {
                    moyenneG = (moyenneG+moy);
                })
                moyenneG = moyenneG/taillemoy
                const minMoy = Math.min.apply(null, moyenneTot);
                const maxMoy = Math.max.apply(null, moyenneTot);

                this.setState({moyenneG});
                this.setState({moyenneMax : maxMoy});
                this.setState({moyenneMin : minMoy});
            });

        });


        /*
                moyenneGgros.forEach((moyenne) => {
                    let sommetemp = 0;
                    sommetemp += moyenne;
                    setMoyenneG(sommetemp);
                });
        */

    }


    render() {
        /*if (this.state.sonRole !== "admin" ) {
            return <Redirect to="/" />
        }*/

        console.log("pppppppppppppppppppppppppp", this.state.globalStats);

        const matieresButtons = [];
        matieresButtons.push(
            <Button variant="outline-success"
                active={!this.state.selectedMatieres || this.state.selectedMatieres.size===0}
                style={{ marginRight:".25rem" }}
                onClick={() => this.toggleMatiereSelection(null)}>
                <span className="fw-bold">Toutes les matières</span>
            </Button>
        );

        const currentMatieres = this.state.statsOfSelectedUsers.reduce((accSet, stat) => {
            return accSet.add(stat.matiere);
        }, new Set());
        currentMatieres.forEach((matiere) => {
            matieresButtons.push(
                <Button variant="outline-success"
                    active={this.state.selectedMatieres && this.state.selectedMatieres.has(matiere)}
                    style={{ marginRight:".25rem" }}
                    onClick={() => this.toggleMatiereSelection(matiere)}
                    key={"btn-"+matiere}>
                    {toTitleCase(matiere)}
                </Button>
            );
        });


        return (
            <div>
                <Sidebar/>
                <main className="main-wrap">
                    <Header history={this.props.history}/>

                    <Container>
                        <Row className="welcome-greeting">
                            <Col sm={9}>
                                <h2 className="title">{this.state.currentEcole && this.state.currentEcole.nom ? this.state.currentEcole.nom : "Statistiques d'école"}</h2>
                            </Col>
                        </Row>

                        <Row className="mt-5 custom-btns">
                            <Col sm={12}>
                                <span className="h5">Statistiques de </span>
                                <Button variant="outline-success"
                                        active={!this.state.selectedClasses || this.state.selectedClasses.size === 0}
                                        style={{ marginRight:".25rem" }} onClick={() => this.toggleClasseSelection(null)}>
                                    <span className="fw-bold">Toutes les classes</span>
                                </Button>

                                {this.state.currentEcole && this.state.currentEcole.classes && this.state.currentEcole.classes.map((classe) => (
                                    <Button variant="outline-success"
                                        active={this.state.selectedClasses && this.state.selectedClasses.has(classe.ref)}
                                        style={{ marginRight:".25rem" }}
                                        onClick={() => this.toggleClasseSelection(classe)}
                                        key={"btn-"+classe.nom}>
                                        {classe.nom}
                                    </Button>
                                ))}
                            </Col>
                        </Row>


                        <Row className="justify-content-md-center" style={{paddingTop: "20px"}}>

                            <Col sm={4} >
                                <div className="card mx-auto" style={{ display: this.state.displayec ? "block" : "none" }}>
                                    <div className="card-body">
                                        <h4 className="card-title" style={{paddingBottom: "10px"}}>Elèves
                                            {/*<button type="button" className="btn btn-seconday" style={{float: "right"}}>
                                                <span className="badge bg-primary">
                                                    {this.state.studentsToShow.length}
                                                </span> élèves
                                            </button>*/}
                                        </h4>

                                        <DataTableBase
                                            columns={this.dataTableCols}
                                            data={this.state.studentsToShow}
                                            searchSpan={12}
                                            selectableRows
                                            selectableRowsHighlight
                                            onSelectedRowsChange={this.onSelectedRowsChange}
                                        />
                                    </div>
                                </div>

                                <Card
                                    bg="primary"
                                    text="white"
                                    style={{width: '100%'}}
                                    className={"mb-2 mt-4" }
                                >
                                    <Card.Header className="text-center">
                                        <Card.Title>
                                            <h4 className="fw-bold">Statistiques des élèves</h4>
                                        </Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        {!this.state.currentStats || this.state.currentStats.length === 0 ?
                                            <div className="my-3 text-center">Aucune donnée</div> :
                                            <div>
                                                <Card.Text>
                                                    <Row>
                                                        <Col className="text-center" sm={12}>
                                                            <span style={{
                                                                fontSize: "14px",
                                                                color: "white"
                                                            }}>
                                                                Moyenne générale
                                                            </span>
                                                            <br></br>
                                                            <span style={{fontSize: "40px", fontWeight: "bold"}}>
                                                                {this.state.globalStats.avg.toFixed(1)}/20
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                    <Row className="text-center" style={{marginTop: "20px"}}>
                                                        <Col className="text-center" sm={6}>
                                                        <span style={{
                                                            fontSize: "14px",
                                                            color: "#eee"
                                                        }}> Moyenne minimum </span> <br></br>
                                                            <span className="fw-bold" style={{fontSize: "20px", color: "#eee"}}>
                                                                {this.state.globalStats.min.toFixed(1)}/20
                                                            </span>
                                                        </Col>
                                                        <Col className="text-center" sm={6}>
                                                        <span style={{
                                                            fontSize: "12px",
                                                            color: "#eee"
                                                        }}> Moyenne maximale </span> <br></br>
                                                            <span className="fw-bold" style={{fontSize: "20px", color: "#eee"}}>
                                                                {this.state.globalStats.max.toFixed(1)} /20
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                </Card.Text>
                                            </div>}
                                    </Card.Body>
                                </Card>
                            </Col>


                            <Col sm={8}>
                                <Tabs
                                    defaultActiveKey="home"
                                    id="fill-tab-example"
                                    className="mb-3"
                                    fill
                                >
                                    <Tab eventKey="home" title="Compétences">
                                        <Row className="custom-btns mb-2">
                                            <div>{matieresButtons}</div>
                                        </Row>

                                        <Row>
                                            <Col style={{textAlign: "center"}} sm={12}>
                                                <div className="table-responsive card">
                                                    <CompetencesChart competencesHistory={this.state.competencesHistory} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab>
                                    <Tab eventKey="profile" title="Notes">
                                        <Row className="custom-btns mb-2">
                                            <div>{matieresButtons}</div>
                                        </Row>
                                        <Row>
                                            <Col sm={{span: 10, offset: 1}}>
                                                <div className="table-responsive card">
                                                    <NotesRadarChart
                                                        notesHistory={this.state.notesHistory}
                                                    />
                                                </div>
                                            </Col>

                                            {this.state.notesHistory && Object.keys(this.state.notesHistory).map((matiere, idx) => {
                                                    if(matiere == 'global'){
                                                        return <></>;
                                                    }

                                                    return <Col sm={6} className="mt-3" key={"graphe-notes-" + matiere}>
                                                                <div className="table-responsive card">
                                                                    <NotesHistoryChart
                                                                        notesHistory={this.state.notesHistory[matiere]}
                                                                        matiere={toTitleCase(matiere.split('/').pop())}
                                                                        backgroundColor={colormap[idx%colormap.length].background}
                                                                        borderColor={colormap[idx%colormap.length].foreground}
                                                                    />                                                    
                                                                </div>
                                                            </Col>
                                                })
                                            }

                                        </Row>
                                    </Tab>
                                    <Tab eventKey="longer-tab" title="Temps passé">
                                        <Row className="custom-btns mb-2">
                                            <div>{matieresButtons}</div>
                                        </Row>
                                        <Row >
                                            <Col sm={12}>
                                                <div className="table-responsive card">
                                                    <DurationHistoryChart durationHistory={this.state.durationHistory['global']}/>                                                    
                                                </div>
                                            </Col>

                                            {this.state.durationHistory && Object.keys(this.state.durationHistory).map((matiere, idx) => {
                                                    if(matiere == 'global'){
                                                        return <></>;
                                                    }

                                                    return <Col sm={6} className="mt-3" key={"graphe-duree-" + matiere}>
                                                                <div className="table-responsive card">
                                                                    <DurationHistoryChart
                                                                        durationHistory={this.state.durationHistory[matiere]}
                                                                        matiere={toTitleCase(matiere.split('/').pop())}
                                                                        backgroundColor={colormap[idx%colormap.length].background}
                                                                        borderColor={colormap[idx%colormap.length].foreground}
                                                                    />                                                    
                                                                </div>
                                                            </Col>
                                                })
                                            }
                                        </Row>
                                    </Tab>

                                </Tabs>

                                <Row style={{marginTop: "20px"}}>
                                    <Col sm={6} style={{ display: this.state.displayel ? "block" : "none" }}>
                                        <div
                                            className="card mx-auto"
                                        >
                                            <div className="card-body" style={{ textAlign:"left" }}>

                                                <h4 className="card-title" style={{paddingBottom: "10px"}}>
                                                    <span className="badge bg-dark">
                                                {this.state.statssuperfi.length}
                                            </span>Exercices
                                                    superficiels </h4>
                                                {/*{JSON.stringify(this.state.statsel)}*/}
                                                {this.state.isLoadingsuperfi ? <Spinner style={{ textAlign:"center" }} animation="border" variant="success" /> : null}
                                                <nav style={{paddingTop: "0px", maxHeight: "400px", overflowY: "auto"}}>
                                                    <ul className="menu-aside">
                                                        {this.state.statssuperfi.map((stats) => (
                                                            <li className="menu-item border menu-link">
                                                                <Image src="/images/logo.png" style={{width: "20%"}} fluid/>
                                                                <span className="text" style={{ whiteSpace:"pre-wrap" }}>{stats}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </nav>

                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6} style={{ display: this.state.displayel ? "block" : "none" }}>

                                        <div
                                            className="card mx-auto"
                                        >
                                            <div className="card-body" style={{ textAlign:"left" }}>

                                                <h4 className="card-title" style={{paddingBottom: "10px"}}>
                                                    <span className="badge bg-dark">
                                                {this.state.statsnoncompris.length}
                                            </span>Exercices pas
                                                    compris</h4>
                                                {this.state.isLoadingnoncompris ? <Spinner style={{ textAlign:"center" }} animation="border" variant="success" /> : null}
                                                <nav style={{ textAlign:"left", paddingTop: "0px", maxHeight: "400px", overflowY: "auto"}}>
                                                    <ul className="menu-aside">

                                                        {this.state.statsnoncompris.map((stats) => (
                                                            <li className="menu-item border menu-link">
                                                                <Image src="/images/logo.png" style={{width: "20%"}} fluid/>
                                                                <span className="text" style={{ whiteSpace:"pre-wrap" }}>{stats}</span>
                                                            </li>
                                                        ))}

                                                    </ul>
                                                </nav>

                                            </div>
                                        </div>

                                    </Col>
                                </Row>

                            </Col>

                        </Row>
                    </Container>


                </main>
            </div>
        );
    }


}

export default HomeScreenAdmin;
