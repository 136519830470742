import {FirebaseProvider} from "../providers/FirebaseProvider"

export class Model {
	constructor(params){
		params = params || {};
		this.baseCollectionPath = "";
		Object.assign(this, params);
		Object.assign(this, this.jsonObject);
	}

	async download(){
		if(!this.ref){
			return;
		}

		this.jsonObject = await FirebaseProvider.getDocAtPath(this.ref);
		Object.assign(this, this.jsonObject);
	}

	async save(){
		let copy = Object.assign({}, this);
		delete copy.jsonObject;
		delete copy.baseCollectionPath;
		delete copy.ref;

		if(this.ref){
			return FirebaseProvider.setDocAtPath(this.ref, copy);
		} 

		if(!this.baseCollectionPath){
			return;
		}
		return FirebaseProvider.addDocToCollection(this.baseCollectionPath, copy);
	}

	async delete(){
		if(!this.ref){
			return;
		}

		return FirebaseProvider.deleteDocAtPath(this.ref);
	}

	toJsonString(){
		let copy = Object.assign({}, this);
		delete copy.jsonObject;

		return JSON.stringify(copy);
	}
}