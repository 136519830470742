import * as firebase from "firebase/app";
import "firebase/auth";
import {getAuth} from "firebase/auth";
import {getFirestore, initializeFirestore, persistentLocalCache} from "firebase/firestore";

const app = firebase.initializeApp({
        apiKey: "AIzaSyAIg2I_V1C8XwyURDd5nECf2N2QbKN22Fk",
        authDomain: "skoole-a9462.firebaseapp.com",
        databaseURL: "https://skoole-a9462-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "skoole-a9462",
        storageBucket: "skoole-a9462.appspot.com",
        messagingSenderId: "668749867047",
        appId: "1:668749867047:web:77b454c01a37210a800602",
        measurementId: "G-ER2R872VJR"
    }
  );
export const auth = getAuth(app);
//export const db = getFirestore(app);
export const db = initializeFirestore(app, {
        localCache: persistentLocalCache()
    });
export default app;

