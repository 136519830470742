import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


const Barreareamat = ({matsolo, statsel
                   }) => {

    const labels = [];
    const tempsGene = [];
    let untemps = 0

    statsel.forEach((doc) => {

        const matnow = (doc.exo.split("/")[5]);

        if (matnow === matsolo){
            const currentValue = (doc.date.split(" ")[0]);
            const isValuePresent = labels.includes(currentValue);

            if (!isValuePresent) {
                labels.push(doc.date.split(" ")[0])

                untemps = doc.duration/60

                tempsGene.push(untemps);

            } else {
                const index = labels.indexOf(currentValue);

                const ancientemps = tempsGene[index];
                const newtemps = ancientemps + ((doc.duration)/60)

                tempsGene.splice(index, 1, newtemps);

            }

        }else {
            return false
        }


    });



    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: matsolo,
            },
        },
    };

    const data = {
        labels,
        datasets: [
            {
                label: 'Temps passé',
                data: tempsGene,
                backgroundColor: 'rgba(65,105,225, 0.5)',
            },
        ],
    };

    return (

        <Bar options={options} data={data} />
    );
};

export default Barreareamat;
