import * as React from "react";
import { Component } from 'react';
import Header from "../components/Header";
import Sidebarsuper from "./../components/sidebarsuper";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Link} from "react-router-dom";
import {Card,} from "react-bootstrap";
import {AuthContext} from "./Auth";
import Spinner from "react-bootstrap/Spinner";
import {
    FirebaseProvider,
    UTILISATEURS_COLLECTION_PATH,
    ECOLES_COLLECTION_PATH,
    CLASSES_COLLECTION_PATH
} from "../providers/FirebaseProvider";

class HomeScreenSuperAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nbEcoles : 0,
            nbClasses : 0,
            nbUsers : 0,
            nbEnseignants: 0,
            nbEleves : 0,

            loadingEcoles: true,
            loadingClasses: true,
            loaddingUsers: true,
            loadingEnseignants : true,
            loadingEleves: true,

            currentUser: {}
        };
    }

    componentDidMount() {
        this.setState({currentUser: this.context.currentUser});

        let nbUsers = 0;
        let nbEcoles = 0;
        let nbClasses = 0;
        let nbEleves = 0;
        let nbEnseignants = 0;
        let classesPromisesArray = [];

        FirebaseProvider.getAllDocsInCollection(UTILISATEURS_COLLECTION_PATH).then((usersObj) => {
            // Compute nbUsers
            nbUsers = Object.keys(usersObj).length;
            this.setState({nbUsers: nbUsers, loaddingUsers:false});
        });

        FirebaseProvider.getAllDocsInCollection(ECOLES_COLLECTION_PATH).then((ecolesObj) => {
            // Compute nbEcoles
            nbEcoles = Object.keys(ecolesObj).length;
            this.setState({nbEcoles: nbEcoles, loadingEcoles:false});

            // Load classes of each ecole
            for(let ecoleId in ecolesObj){
                const enseignants = ecolesObj[ecoleId]['enseignants'];
                nbEnseignants += enseignants && Array.isArray(enseignants) ? enseignants.length : 0;
                
                const classesPath = ECOLES_COLLECTION_PATH + '/' + ecoleId + '/' + CLASSES_COLLECTION_PATH;
                const classesPromise = FirebaseProvider.getAllDocsInCollection(classesPath)
                .then((classesObj) => {
                    nbClasses += Object.keys(classesObj).length;
                    
                    // Loop over these classes and increment nbEleves and nbEnseignants
                    for(let classeId in classesObj){
                        const eleves = classesObj[classeId]['eleves'];
                        nbEleves += eleves && Array.isArray(eleves) ? eleves.length : 0;
                    }
                });
                classesPromisesArray.push(classesPromise);
            }
            
            Promise.all(classesPromisesArray).then((values) => {
                this.setState({
                    nbClasses: nbClasses, loadingClasses: false,
                    nbEleves: nbEleves, loadingEleves: false,
                    nbEnseignants: nbEnseignants, loadingEnseignants: false,
                });
            });
        });
    }


    render() {
        /*if (this.state.sonRole === false ) {
            return <Redirect to="/" />
        }
*/
        return (
            <div>
                <Sidebarsuper/>

                <main className="main-wrap">
                    <Header history={this.props.history}/>
                    <Container>
                        {this.state.currentUser &&
                            <Row className="welcome-greeting">
                                <Col sm={12}>
                                    <h5 className="">
                                        Bonjour, <span style={{color: "#1cb323", fontSize: "125%"}}>
                                        {this.state.currentUser.prenom + " " + this.state.currentUser.nom}
                                    </span>
                                    </h5>
                                </Col>
                            </Row>                            
                        }


                        <Row className="" style={{paddingTop: "20px"}}>
                            <Col sm={3}>
                                <Link to="/superadmin/usersTotal" className="text-decoration-none">
                                    <Card
                                        bg="success"
                                        text="white"
                                        className="mb-2 mt-4"
                                    >
                                        <Card.Header className="text-center"><Card.Title> Nombre d'utilisateurs </Card.Title></Card.Header>
                                        <Card.Body>
                                            <Card.Text>
                                                <Row>
                                                    <Col className="text-center" sm={12} style={{ color:"white" }}>
                                                        <Spinner animation="border" variant="white" className={!this.state.loadingUsers && "d-none"} />
                                                        <span className="card-content-main-number">
                                                            {!this.state.loadingUsers && this.state.nbUsers}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>

                            <Col sm={3}>
                                <Link to="/superadmin/ecole" className="text-decoration-none">
                                    <Card
                                        bg="primary"
                                        text="white"
                                        className="mb-2 mt-4"
                                    >
                                        <Card.Header className="text-center">
                                            <Card.Title> Nombre d'écoles </Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <Card.Text>
                                                <Row>
                                                    <Col className="text-center" sm={12} style={{ color:"white" }}>
                                                        <Spinner animation="border" variant="white" className={!this.state.loadingEcoles && "d-none"} />
                                                        <span className="card-content-main-number">
                                                            {!this.state.loadingEcoles && this.state.nbEcoles}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>

                            <Col sm={3}>
                                <Link to="/superadmin/teacher" className="text-decoration-none">
                                    <Card
                                        bg="danger"
                                        text="white"
                                        className="mb-2 mt-4"
                                    >
                                        <Card.Header className="text-center"><Card.Title> Nombre de classes </Card.Title></Card.Header>
                                        <Card.Body>
                                            <Card.Text>
                                                <Row>
                                                    <Col className="text-center" sm={12} style={{ color:"white" }}>
                                                        <Spinner animation="border" variant="white" className={!this.state.loadingClasses && "d-none"} />
                                                        <span className="card-content-main-number">
                                                            {!this.state.loadingClasses && this.state.nbClasses}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>

                            <Col sm={3}>
                                <Link to="/superadmin/teacher" className="text-decoration-none">
                                    <Card
                                        bg="warning"
                                        text="white"
                                        className="mb-2 mt-4"
                                    >
                                        <Card.Header className="text-center"><Card.Title> Nombre d'enseignants </Card.Title></Card.Header>
                                        <Card.Body>
                                            <Card.Text>
                                                <Row>
                                                    <Col className="text-center" sm={12} style={{ color:"white" }}>
                                                        <Spinner animation="border" variant="white" className={!this.state.loadingEnseignants && "d-none"} />
                                                        <span className="card-content-main-number">
                                                            {!this.state.loadingEnseignants && this.state.nbEnseignants}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>

                            <Col sm={3}>
                                <Link to="/superadmin/eleve" className="text-decoration-none">
                                    <Card
                                        bg="secondary"
                                        text="white"
                                        className="mb-2 mt-4"
                                    >
                                        <Card.Header className="text-center"><Card.Title> Nombre d'éleves </Card.Title></Card.Header>
                                        <Card.Body>
                                            <Card.Text>
                                                <Row>
                                                    <Col className="text-center" sm={12} style={{ color:"white" }}>
                                                        <Spinner animation="border" variant="white" className={!this.state.loadingEleves && "d-none"} />
                                                        <span className="card-content-main-number">
                                                            {!this.state.loadingEleves && this.state.nbEleves}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                </main>
            </div>
        );
    }


}

HomeScreenSuperAdmin.contextType = AuthContext;
export default HomeScreenSuperAdmin;
