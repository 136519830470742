import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


const Barrearea = ({nomel, statsel, classeselect
                   }) => {

    const labels = [];
    const tempsGene = [];
    let untemps = 0

    statsel.forEach((doc) => {

        const currentValue = (doc.date.split(" ")[0]);
        const isValuePresent = labels.includes(currentValue);

        if (!isValuePresent) {
            labels.push(doc.date.split(" ")[0])

            untemps = doc.duration/60

            tempsGene.push(untemps);


        } else {
            const index = labels.indexOf(currentValue);

            const ancientemps = tempsGene[index];
            const newtemps = (ancientemps + doc.duration)/60

            tempsGene.splice(index, 1, newtemps);

        }

    });

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Statistiques générales de '+nomel,
            },
        },
    };

    const data = {
        labels,
        datasets: [
            {
                label: 'Durée total dans le temps',
                data: tempsGene,
                backgroundColor: 'rgba(65,105,225, 0.5)',
            },
        ],
    };

    return (

        <Bar options={options} data={data} />
    );
};

export default Barrearea;
