import React, {Component} from 'react';
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import DataTable from 'react-data-table-component';

const defaultColumnOptions = {
    wrap: true,
    sortable: true,
    reorder: true
}

const css = `
        .rdt_TableHead {
          font-weight: bold !important;
          font-size: 95% !important;
          border-bottom: solid 2px black;
        }

        .rdt_Table {
          border: solid 1px #ccc;
        }

        .datatablebase {
            background-color: inherit !important;
        }

        .datatablebase header, .datatablebase header .container {
            background-color: inherit !important;
            padding: 0 0 6px 0 !important;
        }

        .datatablebase nav {
            background-color: inherit !important;
            color: #000 !important;
        }

        /*.datatablebase nav svg {
            fill: #000;
        }*/
    `

export function SearchBar(props) {
  return (
    <Form className="d-flex">
        <Form.Control
          type="search"
          placeholder={props.placeHolder || "Rechercher"}
          className="me-2"
          aria-label="Search"
          onChange={props.onChange}
        />
        <Button variant="outline-success">
          <i className="icon fas fa-search"></i>
        </Button>
    </Form>
  );
}

class DataTableBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            filteredData: props.data,
            resetPaginationToggle: false,
            filterQuery: ''
        };

        this.searchSpan = this.props.searchSpan || 4;
        this.setStateFromProps(props);
    }

    componentWillReceiveProps(props) {
      this.setStateFromProps(props);
    }

    setStateFromProps = (props) => {
        if(this.props.columns && Array.isArray(this.props.columns)){
            for(let i=0; i<this.props.columns.length; i++){
                this.props.columns[i] = {...defaultColumnOptions, ...this.props.columns[i]};
            }
        }

        this.setState({
            data: [...props.data],
            filteredData: [...props.data],
            filterQuery: ''
        });  
    }

    search = () => {
        if(!this.state.filterQuery){
            this.setState({ filteredData: [...this.state.data] });
            return;
        }

        const query = this.state.filterQuery.toLowerCase();
        const filteredData = this.state.data.filter(
            (item) => Object.values(item).some(a => a && a.toString().toLowerCase().includes(query))
        );
        this.setState({ filteredData: filteredData });
    };

    getSubHeaderComponent = () => {
        const opt = { span: this.searchSpan, offset: 12-this.searchSpan };
        return (
            <Container>
              <Row>
                <Col sm={opt}>
                    <SearchBar onChange={(e) => {
                                this.setState({filterQuery: e.target.value});
                                this.search();
                            }
                        }
                    />
                </Col>
              </Row>
            </Container>
        );
    };

    render(){
        return (
            <div className="datatablebase">
                <style>{css}</style>
                <DataTable
                    striped
                    highlightOnHover
                    pointerOnHover
                    pagination
                    paginationPerPage={20}
                    paginationResetDefaultPage={this.state.resetPaginationToggle}
                    fixedHeader
                    noHeader
                    subHeader
                    subHeaderComponent={this.getSubHeaderComponent()}
                    {...this.props}
                    data={this.state.filteredData}
                />
            </div>
        );
    }
}

export default DataTableBase;
