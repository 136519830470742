import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    Colors
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import { UNDERSTANDING_STATE_VALUES } from '../../utils/stats_functions';
import { colormap } from '../../utils/chart_colormap';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    Colors,
);


export const MAX_NB_EXOS = 25;

const CompetencesChart = ({competencesHistory}) => {
    if(!competencesHistory || Object.keys(competencesHistory).length==0){
        return <div className="my-5 text-center">Aucune donnée à afficher</div>;
    }

    const options = {
        responsive: true,
        plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Evolution de la couverture du programme',
            },
            tooltip: {
                mode: 'index'
            },
        },
        interaction: {
          mode: 'nearest',
          axis: 'x',
          intersect: false
        },
        scales: {
            x: {
                title: {
                  display: true,
                  text: 'Date'
                }
              },
            y: {
                stacked: true,
                title: {
                  display: true,
                  text: "Nombre d'exercices"
                }
              }
          }
    };



    const valsOfStates = {};
    for(let state of UNDERSTANDING_STATE_VALUES){
        valsOfStates[state] = [];
    }
    valsOfStates["NON_FAIT"] = [];

    const dates = Object.keys(competencesHistory).sort();
    
    let nbNonFaits, tmp;
    for(let date of dates){
        const competency = competencesHistory[date];
        nbNonFaits = MAX_NB_EXOS;
        
        for(let state of UNDERSTANDING_STATE_VALUES){
            tmp = competency[state];
            valsOfStates[state].push(tmp);
            nbNonFaits -= tmp;
        }

        valsOfStates["NON_FAIT"].push(nbNonFaits);
    }

    const data = {
        labels: dates,
        datasets: Object.keys(valsOfStates).map((state, idx) => {
            return {
                label: state,
                data: valsOfStates[state],
                fill: true,
                borderWidth: 4,
                borderColor: colormap[idx%colormap.length].foreground,
                backgroundColor: colormap[idx%colormap.length].background,
            };
        })

        /*[
            {
                label: 'Moyenne cumulée /20 des matières de ',
                data: [],
                backgroundColor: 'rgba(65,105,225, 0.2)',
                borderColor: 'rgba(65,105,225, 1)',
                borderWidth: 1,
            },
        ],*/
    };

    return (
        <Line options={options} data={data} />
    );
};

export default CompetencesChart;
