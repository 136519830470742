import React from "react";
import Header from "../components/Header";
import Sidebar from "./../components/sidebar";
import Accordion from 'react-bootstrap/Accordion';
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import {Link} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Sidebarsuper from "../components/sidebarsuper";

const HelpScreenSuper = (props) => {

  return (
    <>
      <Sidebarsuper />
      <main className="main-wrap">
        <Header history={props.history} />

          <Row style={{ paddingTop:"50px" , justifyContent:"center" }}>

              <Col sm={6}>
                  <div
                      className="mx-auto"
                  >

                      <div className="accordion accordion-flush" id="accordionFlushExample">
                          <div className="accordion-item">
                              <h2 className="accordion-header" id="flush-headingOne">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                          data-bs-target="#flush-collapseOne" aria-expanded="false"
                                          aria-controls="flush-collapseOne">
                                      Astuce 1
                                  </button>
                              </h2>
                              <div id="flush-collapseOne" className="accordion-collapse collapse"
                                   aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                  <div className="accordion-body">Il faut revoir tes façons</div>
                              </div>
                          </div>
                          <div className="accordion-item">
                              <h2 className="accordion-header" id="flush-headingTwo">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                          data-bs-target="#flush-collapseTwo" aria-expanded="false"
                                          aria-controls="flush-collapseTwo">
                                      Astuce 2
                                  </button>
                              </h2>
                              <div id="flush-collapseTwo" className="accordion-collapse collapse"
                                   aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                  <div className="accordion-body">Bonnnn c'est pas trop ça mais après
                                  </div>
                              </div>
                          </div>
                          <div className="accordion-item">
                              <h2 className="accordion-header" id="flush-headingThree">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                          data-bs-target="#flush-collapseThree" aria-expanded="false"
                                          aria-controls="flush-collapseThree">
                                      Astuce 3
                                  </button>
                              </h2>
                              <div id="flush-collapseThree" className="accordion-collapse collapse"
                                   aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                  <div className="accordion-body">Ummmm on verra bien ce que ça va donner
                                  </div>
                              </div>
                          </div>
                      </div>

                  </div>
              </Col>

          </Row>

      </main>
    </>
  );
};

export default HelpScreenSuper;
