import {Model} from './Model';

export class Role extends Model {

	isValid(){
		if(!this.role){
			return false;
		}

		switch(this.role.toLowerCase()){
			case "superadmin":
				return true;
			case "admin":
				return !!this.ecole;
			case "enseignant":
				return !!this.ecole && !!this.classe;
			case "eleve":
				return !!this.ecole && !!this.classe;
		}
		
		return false;
	}

	toString(){
		let res = this.role.charAt(0).toUpperCase() + this.role.slice(1);
		if(this.role === 'superadmin'){
			return res;
		}

		res += ' - ' + this.ecole;
		if(this.role === 'admin'){
			return res;
		}

		res += ' - ' + this.classe;
		return res;
	}
}