import * as React from "react";
import { useState, useEffect, Fragment, useContext } from 'react';
import Header from "../components/Header";
import Sidebar from "./../components/sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import {Link, NavLink} from "react-router-dom";
import {Card, Tab, Tabs} from "react-bootstrap";
import Graphiquearea from "../components/chart/Graphiquearea";
import Barrearea from "../components/chart/Barrearea";
import Radararea from "../components/chart/Radararea";
import Evolutionarea from "../components/chart/Evolutionarea";
import app from "./base.js";
import {auth} from "./base.js";
import {db} from "./base.js";
import {
    doc,
    onSnapshot,
    updateDoc,
    setDoc,
    deleteDoc,
    collection,
    serverTimestamp,
    getDocs,
    query,
    where,
    orderBy,
    limit,
} from 'firebase/firestore';
import {AuthContext, useUserAuth} from "./Auth";
import {useFirestoreDocument, useFirestoreQuery} from "@react-query-firebase/firestore";


const HomeScreen = (props) => {

    const colletionRef = collection(db, 'ecoles');
    const colletionRefusers = collection(db, 'utilisateurs');
    const colletionRefclasses = collection(db, 'ecoles/testecole1/classes/');
    const colletionRefusersroles = collection(db, 'utilisateurs/');

    const { currentUser } = useContext(AuthContext);
    const currentUserId = currentUser ? currentUser.uid : null;
    const [users, setUsers] = useState([]);
    const [ecoles, setEcoles] = useState([]);
    const [classes, setClasses] = useState([]);
    const [eleves, setEleves] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingusers, setLoadingusers] = useState(false);
    const [loadingclasses, setLoadingclasses] = useState(false);

    const [roles, setRoles] = useState([]);

    const [uiduser, setUiduser] = useState([]);

    //REALTIME GET FUNCTION
    useEffect(() => {
        const q = query(
            colletionRef,
            where('niveau', '==', 'primaire')
        );

        const qusers = query(
            colletionRefusers,
            where('numero_tel', '==', userc.phoneNumber.substring(4) )
        );

        setLoading(true);
        // const unsub = onSnapshot(q, (querySnapshot) => {
        const unsub = onSnapshot(q, (querySnapshot) => {
            const items = [];
            querySnapshot.forEach((doc) => {
                items.push(doc.data());
            });
            setEcoles(items);
            setLoading(false);
        });

        const unsubusers = onSnapshot(qusers, (querySnapshot) => {
            const items = [];
            querySnapshot.forEach((doc) => {
                items.push(doc.data());
            });
            setUsers(items);
            setLoadingusers(false);

        });

        const unsubclasses = onSnapshot(colletionRefclasses, (querySnapshot) => {
            const items = [];
            querySnapshot.forEach((doc) => {
                items.push(doc.data());
            });
            setClasses(items);
            setLoadingclasses(false);
        });

        return () => {
            unsub();
            unsubusers();
            unsubclasses();
        };

        // eslint-disable-next-line
    }, []);

    const [bool, setBool] = useState("false");
    const userc = auth.currentUser;

    useEffect(() => {

        users.forEach((user, index) => {
            setUiduser(user.uid)
        });

        if (uiduser === "testuser1"){
            setBool("true")
        }else {
            setBool("false")
        }

        const unsubroles = onSnapshot(collection(db, `utilisateurs/testuser1/roles`), (querySnapshot) => {
            const items = [];
            querySnapshot.forEach((doc) => {
                items.push(doc.data());
            });
            setRoles(items);
        });
        return () => {
            unsubroles();
        };



    }, [])

    return (
        <>
            <Sidebar/>
            <main className="main-wrap">
                <Header history={props.history}/>

                <Container>

                    <Row>



                        {/*<Col sm={12}>
                            {loading ? <h1>Loading...</h1> : null}
                            {ecoles.map((ecole) => (
                                <div className="school" key={ecole.id}>
                                    <h2>{JSON.stringify(ecole) }</h2>
                                </div>
                            ))}
                        </Col>*/}

                       <Col sm={12}>
                            {loading ? <h1>Loading...</h1> : null}
                           {bool}<br></br>
                           {uiduser}<br></br>
                           {JSON.stringify(users[0])}
                        </Col>

                        <Col sm={12}>
                            {loading ? <h1>Loading...</h1> : null}
                            {roles.map((role) => (
                                <div className="school" key={role.id}>
                                    <h2>{JSON.stringify(role) }</h2>
                                </div>
                            ))}
                        </Col>

                    </Row>

                    <Row>
                        <Col sm={12}>
                            <h5 className="">Bonjour , <span style={{color: "#1cb323"}}>Daril Super Admin</span></h5>
                        </Col>
                        <Col sm={12}>
                            <h2 className="title">FONDATION MALONGO</h2>
                        </Col>
                        <Col sm={12}>
                            <p className="title">{}</p>
                        </Col>
                    </Row>

                    <Row className="justify-content-md-center" style={{paddingTop: "20px"}}>
                        <Col sm={4}>
                            <div
                                className="card mx-auto"
                            >
                                <div className="card-body">

                                    <h2 className="card-title" style={{paddingBottom: "10px"}}>Elèves
                                        <button type="button" className="btn btn-seconday" style={{float: "right"}}>
                                            <span className="badge bg-dark">4</span>Elèves
                                        </button>
                                    </h2>


                                    <nav style={{
                                        paddingTop: "0px",
                                        width: "100%",
                                        maxHeight: "400px",
                                        overflowY: "auto"
                                    }}>
                                        <ul className="menu-aside">
                                            <li className="menu-item border">
                                                <a href="#" className="menu-link">
                                                    <i className="icon fas fa-square"></i>
                                                    <span className="text">Angèle</span>
                                                </a>
                                            </li>
                                            <li className="menu-item border">
                                                <a href="#" className="menu-link">
                                                    <i className="icon fas fa-square"></i>
                                                    <span className="text">Loic</span>
                                                </a>
                                            </li>
                                            <li className="menu-item border">
                                                <a href="#" className="menu-link">
                                                    <i className="icon fas fa-square"></i>
                                                    <span className="text">Ludovic</span>
                                                </a>
                                            </li>

                                            <li className="menu-item border">
                                                <a href="#" className="menu-link">
                                                    <i className="icon fas fa-square"></i>
                                                    <span className="text">Ludovic</span>
                                                </a>
                                            </li>

                                            <li className="menu-item border">
                                                <a href="#" className="menu-link">
                                                    <i className="icon fas fa-square"></i>
                                                    <span className="text">Ludovic</span>
                                                </a>
                                            </li>

                                            <li className="menu-item border">
                                                <a href="#" className="menu-link">
                                                    <i className="icon fas fa-square"></i>
                                                    <span className="text">Ludovic</span>
                                                </a>
                                            </li>

                                            <li className="menu-item border">
                                                <a href="#" className="menu-link">
                                                    <i className="icon fas fa-square"></i>
                                                    <span className="text">Ludovic</span>
                                                </a>
                                            </li>

                                        </ul>
                                    </nav>

                                </div>
                            </div>

                            <Card
                                bg="primary"
                                text="white"
                                style={{width: '100%'}}
                                className="mb-2 mt-4"
                            >
                                <Card.Header className="text-center"><Card.Title> Statistiques
                                    Globales </Card.Title></Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <Row>
                                            <Col className="text-center" sm={12}>
                                                <span style={{
                                                    fontSize: "12px",
                                                    color: "yellowgreen"
                                                }}> Moyenne Generale </span> <br></br>
                                                <span style={{fontSize: "40px", fontWeight: "bold"}}> 13.9/20 </span>
                                            </Col>
                                        </Row>
                                        <Row className="text-center" style={{marginTop: "20px"}}>
                                            <Col className="text-center" sm={6}>
                                                <span style={{
                                                    fontSize: "12px",
                                                    color: "#f00020"
                                                }}> Moyenne minimum </span> <br></br>
                                                <span style={{fontSize: "20px", color: "#f00020"}}> 9.9/20 </span>
                                            </Col>
                                            <Col className="text-center" sm={6}>
                                                <span style={{
                                                    fontSize: "12px",
                                                    color: "#b0f2b6"
                                                }}> Moyenne maximale </span> <br></br>
                                                <span style={{fontSize: "20px", color: "#b0f2b6"}}> 13.9/20 </span>
                                            </Col>
                                        </Row>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col sm={8}>

                            <Tabs
                                defaultActiveKey="home"
                                id="fill-tab-example"
                                className="mb-3"
                                fill
                            >
                                <Tab eventKey="home" title="Compétences">
                                    <Row style={{backgroundColor: "#b0f2b6"}}>
                                        <Col style={{textAlign: "center"}} sm={12}>
                                            <div className="table-responsive card">
                                                <Graphiquearea/>
                                            </div>
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="profile" title="Notes">
                                    <Row style={{backgroundColor: "#b0f2b6"}}>
                                        <Col sm={6}>
                                            <div className="table-responsive card">
                                                <Radararea/>
                                            </div>
                                        </Col>
                                        <Col sm={6}>
                                            <div className="table-responsive card">
                                                <Evolutionarea/>
                                            </div>
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="longer-tab" title="Temps passé">
                                    <Row style={{backgroundColor: "#b0f2b6"}}>
                                        <Col sm={6}>
                                            <div className="table-responsive card">
                                                <Barrearea/>
                                            </div>
                                        </Col>
                                        <Col sm={6}>
                                            <div className="table-responsive card">
                                                <Barrearea/>
                                            </div>
                                        </Col>
                                    </Row>
                                </Tab>

                            </Tabs>

                            <Row style={{marginTop: "20px"}}>
                                <Col sm={6}>
                                    <div
                                        className="card mx-auto"
                                    >
                                        <div className="card-body">

                                            <h2 className="card-title" style={{paddingBottom: "10px"}}>Exercices
                                                compris</h2>

                                            <nav style={{paddingTop: "0px", maxHeight: "400px", overflowY: "auto"}}>
                                                <ul className="menu-aside">
                                                    <li className="menu-item border">
                                                        <a href="#" className="menu-link">
                                                            <Image src="/images/logo.png" style={{width: "20%"}} fluid/>
                                                            <span className="text">La soustraction</span>
                                                        </a>
                                                    </li>
                                                    <li className="menu-item border">
                                                        <a href="#" className="menu-link">
                                                            <Image src="/images/logo.png" style={{width: "20%"}} fluid/>
                                                            <span className="text">L'addition</span>
                                                        </a>
                                                    </li>
                                                    <li className="menu-item border">
                                                        <a href="#" className="menu-link">
                                                            <Image src="/images/logo.png" style={{width: "20%"}} fluid/>
                                                            <span className="text">La grammaire</span>
                                                        </a>
                                                    </li>

                                                    <li className="menu-item border">
                                                        <a href="#" className="menu-link">
                                                            <Image src="/images/logo.png" style={{width: "20%"}} fluid/>
                                                            <span className="text">La grammaire</span>
                                                        </a>
                                                    </li>
                                                    <li className="menu-item border">
                                                        <a href="#" className="menu-link">
                                                            <Image src="/images/logo.png" style={{width: "20%"}} fluid/>
                                                            <span className="text">La grammaire</span>
                                                        </a>
                                                    </li>

                                                    <li className="menu-item border">
                                                        <a href="#" className="menu-link">
                                                            <Image src="/images/logo.png" style={{width: "20%"}} fluid/>
                                                            <span className="text">La grammaire</span>
                                                        </a>
                                                    </li>

                                                    <li className="menu-item border">
                                                        <a href="#" className="menu-link">
                                                            <Image src="/images/logo.png" style={{width: "20%"}} fluid/>
                                                            <span className="text">La grammaire</span>
                                                        </a>
                                                    </li>

                                                </ul>
                                            </nav>

                                        </div>
                                    </div>
                                </Col>
                                <Col sm={6}>

                                    <div
                                        className="card mx-auto"
                                    >
                                        <div className="card-body">

                                            <h2 className="card-title" style={{paddingBottom: "10px"}}>Exervices pas
                                                compris</h2>

                                            <nav style={{paddingTop: "0px", maxHeight: "400px", overflowY: "auto"}}>
                                                <ul className="menu-aside">
                                                    <li className="menu-item border">
                                                        <a href="#" className="menu-link">
                                                            <Image src="/images/logo.png" style={{width: "20%"}} fluid/>
                                                            <span className="text">Le verbe avoir</span>
                                                        </a>
                                                    </li>
                                                    <li className="menu-item border">
                                                        <a href="#" className="menu-link">
                                                            <Image src="/images/logo.png" style={{width: "20%"}} fluid/>
                                                            <span className="text">Les conjugaisons</span>
                                                        </a>
                                                    </li>
                                                    <li className="menu-item border">
                                                        <a href="#" className="menu-link">
                                                            <Image src="/images/logo.png" style={{width: "20%"}} fluid/>
                                                            <span className="text">Le pronom</span>
                                                        </a>
                                                    </li>

                                                    <li className="menu-item border">
                                                        <a href="#" className="menu-link">
                                                            <Image src="/images/logo.png" style={{width: "20%"}} fluid/>
                                                            <span className="text">Le pronom</span>
                                                        </a>
                                                    </li>


                                                    <li className="menu-item border">
                                                        <a href="#" className="menu-link">
                                                            <Image src="/images/logo.png" style={{width: "20%"}} fluid/>
                                                            <span className="text">Le pronom</span>
                                                        </a>
                                                    </li>

                                                    <li className="menu-item border">
                                                        <a href="#" className="menu-link">
                                                            <Image src="/images/logo.png" style={{width: "20%"}} fluid/>
                                                            <span className="text">Le pronom</span>
                                                        </a>
                                                    </li>

                                                    <li className="menu-item border">
                                                        <a href="#" className="menu-link">
                                                            <Image src="/images/logo.png" style={{width: "20%"}} fluid/>
                                                            <span className="text">Le pronom</span>
                                                        </a>
                                                    </li>


                                                </ul>
                                            </nav>

                                        </div>
                                    </div>

                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </Container>


            </main>
        </>
    );
};

export default HomeScreen;
