import * as React from "react";
import {useState, useEffect, Fragment, useContext, Component} from 'react';
import Header from "../../components/Header";
import Sidebar from "../../components/sidebarsuper";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import {Link, NavLink, Redirect} from "react-router-dom";
import {Card, Form, Tab, Tabs} from "react-bootstrap";

import Table from 'react-bootstrap/Table';

import Spinner from 'react-bootstrap/Spinner';

import {auth} from "../../screens/base";
import {db} from "../../screens/base";
import {
    doc,
    onSnapshot,
    updateDoc,
    setDoc,
    deleteDoc,
    collection,
    serverTimestamp,
    getDocs,
    query,
    where,
    orderBy,
    limit,
} from 'firebase/firestore';
import {AuthContext, useUserAuth} from "../../screens/Auth";
import {useFirestoreDocument, useFirestoreQuery} from "@react-query-firebase/firestore";
import PhoneNumber from "libphonenumber-js";
import Sidebarsuper from "../../components/sidebarsuper";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'
import {v4 as uuidv4} from 'uuid';
import PhoneInput from "react-phone-number-input";
import { getCountryCallingCode } from 'react-phone-number-input'
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class SuperEleve extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            ecoles: [],
            ecolesid: [],
            showcreate: false,
            showedit: false,
            showview: false,
            showdelete: false,
            teachers: [],
            eleves: [],
            classes: [],
            userid: "",
            roles: [],
            loading: true,
            trouve: false,

            codeCountry : "",
            dateNaissance : "",
            email : "",
            imageUrl : "",
            isCompleteRegisted : false,
            nom : "",
            numero_tel : "",
            pays : "",
            photo_profil : "",
            prenom : "",
            sexe : "",
            superadmin : false,
            token : "",
            uid : "",
            ville : "",
            ecole : "",
            classe : "",

            codeCountryv : "",
            dateNaissancev : "",
            emailv : "",
            imageUrlv : "",
            isCompleteRegistedv : false,
            nomv : "",
            numero_telv : "",
            paysv : "",
            photo_profilv : "",
            prenomv : "",
            sexev : "",
            superadminv : false,
            tokenv : "",
            uidv : "",
            villev : "",
            ecolev : "",
            classev : "",

            iddelete: "",
            idroledelete: [],
            idecoledelete: "",
            idclassedelete: "",
            idupdate: "",
            idecoleupdate: "",
            idclasseupdate: "",
            idecoleupdatenew: "",
            idclasseupdatenew: "",
            classeAUpdate: [],
            classeAUpdateAvant: [],

            nomCombine: ""

        };

        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    componentDidMount() {
        const colletionRefusers = collection(db, 'utilisateurs');
        const colletionRefecole = collection(db, 'ecoles');

        const user = auth.currentUser;
        const parsedNumber = PhoneNumber(user.phoneNumber);
        const countryCode = parsedNumber.country;
        const allNumber = parsedNumber.number;
        const nationalNumber = parsedNumber.nationalNumber;
        const userid = ""

        const qecole = query(
            colletionRefecole);

        onSnapshot(qecole, (querySnapshot) => {
            const ecoles = [];
            const ecolesid = [];
            querySnapshot.forEach((doc) => {
                ecoles.push(doc.data());
                ecolesid.push({id: doc.id, nom: doc.data().nom});
            });
            this.setState({ecoles});
            this.setState({ecolesid});

            const classes = [];
            ecolesid.forEach((ecol) => {

                onSnapshot(query( collection(db, 'ecoles/'+ecol.id+'/classes/') ), (querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        classes.push([ecol.nom, doc.data().nom, doc.id, doc.data().enseignants, doc.data().eleves, ecol.id]);
                    });
                    this.setState({classes});

                    const eleves = []
                    this.state.classes.forEach((classsolo) => {

                        classsolo[4].forEach((classsolosup) => {

                            onSnapshot(query( collection(db, 'utilisateurs'), where('uid', '==', classsolosup) ), (querySnapshot) => {
                                querySnapshot.forEach((doc) => {
                                    eleves.push([classsolo[0],classsolo[1],doc.data(), classsolo[5], classsolo[2]])
                                });
                                this.setState({eleves})
                                this.setState({loading : false})
                            });

                        });

                    });
                });

            });


            if (this.state.classes.length){
                this.setState({trouve : true})
            }else {
                this.setState({trouve : false})
            }

        });


    }


    handleClosecreate = () => {
        this.setState({showcreate: false})
    }

    handleCloseview = () => {
        this.setState({showview: false})
    }

    handleCloseedit = () => {
        this.setState({showedit: false})
    }

    handleClosedelete = () => {
        this.setState({showdelete: false})
    }

    handleShowcreate = () => {
        this.setState({ showcreate: true });
    }

    handleShowview = (eleve, classe, ecole) => {

        this.setState({codeCountryv : eleve.codeCountry})
        this.setState({dateNaissancev : eleve.dateNaissance})
        this.setState({emailv : eleve.email})
        this.setState({imageUrlv : eleve.imageUrl})
        this.setState({isCompleteRegistedv : eleve.isCompleteRegisted})
        this.setState({nomv : eleve.nom})
        this.setState({numero_telv : eleve.numero_tel})
        this.setState({paysv : eleve.pays})
        this.setState({photo_profilv : eleve.photo_profil})
        this.setState({prenomv : eleve.prenom})
        this.setState({sexev : eleve.sexe})
        this.setState({superadminv : eleve.superadmin})
        this.setState({tokenv : eleve.token})
        this.setState({uidv : eleve.uid})
        this.setState({villev : eleve.ville})
        this.setState({ecolev : ecole})
        this.setState({classev : classe})

        this.setState({ showview: true });
    }

    handleShowdelete = (iddelete, idecoledelete, idclassedelete) => {
        this.setState({iddelete})
        this.setState({idecoledelete})
        this.setState({idclassedelete})

        this.updateClasse(idecoledelete, idclassedelete)

        this.setState({ showdelete: true });
    }

    handleShowedit = (eleve, idecoleupdate , idclasseupdate) => {
        this.setState({codeCountry : eleve.codeCountry})
        this.setState({dateNaissance : eleve.dateNaissance})
        this.setState({email : eleve.email})
        this.setState({imageUrl : eleve.imageUrl})
        this.setState({isCompleteRegisted : eleve.isCompleteRegisted})
        this.setState({nom : eleve.nom})
        this.setState({numero_tel : eleve.codeCountry+eleve.numero_tel})
        this.setState({pays : eleve.pays})
        this.setState({photo_profil : eleve.photo_profil})
        this.setState({prenom : eleve.prenom})
        this.setState({sexe : eleve.sexe})
        this.setState({superadmin : eleve.superadmin})
        this.setState({token : eleve.token})
        this.setState({uid : eleve.uid})
        this.setState({ville : eleve.ville})
        //this.setState({ecole : ecole})

        this.setState({ idupdate: eleve.uid });
        this.setState({ idecoleupdate });
        this.setState({ idclasseupdate });
        this.ancienneClasse(  idecoleupdate , idclasseupdate )

        this.setState({ showedit: true });

    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            [name]: value
        });
    }

    handlePhoneChange = (value) => {
        this.setState({ numero_tel: value })
    }

    handleChangeCheck = (event) => {
        if (event.target.value){
            this.setState({ isCompleteRegisted: true })
        }else {
            this.setState({ isCompleteRegisted: false})
        }
    }

    handleChangeCheckSuperAdmin = (event) => {
        if (event.target.value){
            this.setState({ superadmin: true })
        }else {
            this.setState({ superadmin: false})
        }
    }


    handleSelectChange(event) {
        this.setState({ classe: event.target.value.split("/")[1] });
        this.setState({ ecole: event.target.value.split("/")[0] });
        this.setState({ nomCombine: event.target.value.split("/")[2] });
        this.updateClasse( event.target.value.split("/")[0], event.target.value.split("/")[1])
    }

    updateClasse = (idecole, idclasse) => {
        const colletionRefclasse = collection(db, 'ecoles/'+idecole+'/classes');
        const qclasse = query(
            colletionRefclasse, where('id', '==', idclasse));

        onSnapshot(qclasse, (querySnapshot) => {
            const classeAUpdate = [];
            querySnapshot.forEach((doc) => {
                classeAUpdate.push([doc.id, doc.data()]);
            });
            this.setState({classeAUpdate});
        });

    }

    ancienneClasse = (idecole, idclasse) => {
        const colletionRefclasse = collection(db, 'ecoles/'+idecole+'/classes');
        const qclasse = query(
            colletionRefclasse, where('id', '==', idclasse));

        onSnapshot(qclasse, (querySnapshot) => {
            const classeAUpdateAvant = [];
            querySnapshot.forEach((doc) => {
                classeAUpdateAvant.push([doc.id, doc.data()]);
            });
            this.setState({classeAUpdateAvant});
        });

    }


    // ADD FUNCTION
    addeleve = async (event) => {

        const idecole = this.state.ecole
        const idclasse = this.state.classe

        const colletionRefEleve = collection(db, 'utilisateurs');

        const colletionRefclasse = collection(db, 'ecoles/'+idecole+'/classes');

        const id = uuidv4();
        const idrole = uuidv4();
        const parsedNumber = PhoneNumber(this.state.numero_tel);
        const countryCode = getCountryCallingCode(parsedNumber.country);
        const nationalNumber = parsedNumber.nationalNumber;

        const colletionRefrole = collection(db, 'utilisateurs/'+id+'/roles');

        const neweleve = {
            uid : id,
            nom : this.state.nom,
            prenom : this.state.prenom,
            sexe : this.state.sexe,
            email : this.state.email,
            dateNaissance : this.state.dateNaissance,
            numero_tel : nationalNumber,
            codeCountry : "+"+countryCode,
            pays : this.state.pays,
            ville : this.state.ville,
            superadmin : this.state.superadmin,
            isCompleteRegisted : this.state.isCompleteRegisted,
            token : this.state.token,
            imageUrl: this.state.imageUrl,
            photo_profil: this.state.photo_profil,
        };

        const newrole = {
            ecole : idecole,
            role : "eleve"
        };

        try {

            if (this.state.nom === "" && idecole === "" && this.state.numero_tel === ""){

                toast.error("Le nom , le phone ou l'école de l'élève est requis");
                this.handleClosecreate();

            } else {
                const amindes = []
                this.state.classeAUpdate[0][1].eleves.forEach((ad) => {
                    amindes.push(ad)
                })
                amindes.push(id)

                const updatedEcole = {
                    adresse : this.state.classeAUpdate[0][1].adresse,
                    contact : this.state.classeAUpdate[0][1].contact,
                    niveau : this.state.classeAUpdate[0][1].niveau,
                    nom : this.state.classeAUpdate[0][1].nom,
                    pays : this.state.classeAUpdate[0][1].pays,
                    sigle : this.state.classeAUpdate[0][1].sigle,
                    site_web: this.state.classeAUpdate[0][1].site_web,
                    type : this.state.classeAUpdate[0][1].type,
                    ville : this.state.classeAUpdate[0][1].ville,
                    eleves : amindes,
                    enseignants:  this.state.classeAUpdate[0][1].enseignants,
                };

                const eleveRef = doc(colletionRefEleve, id);
                await setDoc(eleveRef, neweleve);

                const ClasseRef = doc(colletionRefclasse, idclasse)
                await updateDoc(ClasseRef, updatedEcole);

                const eleveRoleRef = doc(colletionRefrole, idrole);
                await setDoc(eleveRoleRef, newrole);

                toast.success('Félicitations Elève créé');
                this.handleClosecreate();

            }

        } catch (error) {
            console.error(error);
            this.handleClosecreate();
            toast.error('Une erreur est survenu');
        }


    }


    //DELETE FUNCTION
    deleteeleve = async (event) => {

        try {

            if (this.state.idecoledelete === "" ){

                toast.warning("Problème pas de classe trouvée");
                this.handleClosedelete();

            } else {

                const colletionRefClasse = collection(db, 'ecoles/'+this.state.idecoledelete+'/classes');
                const colletionRefEleve = collection(db, 'utilisateurs');

                const amindes = this.state.classeAUpdate[0][1].eleves.filter((ad) => ad !== this.state.iddelete);

                const updatedClasse = {
                    nom : this.state.classeAUpdate[0][1].nom,
                    eleves : amindes,
                    enseignants:  this.state.classeAUpdate[0][1].enseignants,
                };

                const ClasseRef = doc(colletionRefClasse, this.state.idclassedelete)
                await updateDoc(ClasseRef, updatedClasse);

                const eleveRef = doc(colletionRefEleve, this.state.iddelete);
                await deleteDoc(eleveRef);

                toast.success('Elève supprimé correctement');
                this.handleClosedelete();
                window.location.reload();
            }

        } catch (error) {
            console.error(error);
            this.handleClosedelete();
            toast.error('Une erreur est survenue');
        }
    }


    // EDIT FUNCTION
    editeleve = async (idecole , idecoleUpdate) => {

/*
        const parsedNumber = PhoneNumber(this.state.numero_tel);
        const countryCode = getCountryCallingCode(parsedNumber.country);
        const nationalNumber = parsedNumber.nationalNumber;

        const updatedeleve = {
            uid : this.state.uid,
            nom : this.state.nom,
            prenom : this.state.prenom,
            sexe : this.state.sexe,
            email : this.state.email,
            dateNaissance : this.state.dateNaissance,
            numero_tel : nationalNumber,
            codeCountry : "+"+countryCode,
            pays : this.state.pays,
            ville : this.state.ville,
            superadmin : this.state.superadmin,
            isCompleteRegisted : this.state.isCompleteRegisted,
            token : this.state.token,
            imageUrl: this.state.imageUrl,
            photo_profil: this.state.photo_profil,
        };
*/

        try {

            const colletionRefClasseAvant = collection(db, 'ecoles/'+idecoleUpdate+'/classes');
            const colletionRefClasse = collection(db, 'ecoles/'+idecole+'/classes');

            if (this.state.ecole === this.state.idecoleupdate && this.state.classe === this.state.idclasseupdate ){

                toast.warning("C'est la même classe dans la même école donc RAS");
                this.handleCloseedit();

            } else {
                const amindesavnt = this.state.classeAUpdateAvant[0][1].eleves.filter((ad) => ad !== this.state.idupdate)
                const amindesnew = []
                this.state.classeAUpdate[0][1].eleves.forEach((ad) => {
                    amindesnew.push(ad)
                })
                amindesnew.push(this.state.idupdate)

                const updatedClasseAvant = {
                    nom : this.state.classeAUpdateAvant[0][1].nom,
                    eleves : amindesavnt,
                    enseignants:  this.state.classeAUpdateAvant[0][1].enseignants,
                };

                const updatedClasseNew = {
                    nom : this.state.classeAUpdate[0][1].nom,
                    eleves : amindesnew,
                    enseignants:  this.state.classeAUpdate[0][1].enseignants,
                };

                const ClasseRefavant = doc(colletionRefClasseAvant, this.state.idclasseupdate)
                await updateDoc(ClasseRefavant, updatedClasseAvant);

                const ClasseRefnew = doc(colletionRefClasse, this.state.classe)
                await updateDoc(ClasseRefnew, updatedClasseNew);

                toast.success('Elève dans une autre classe et école Mis à jour');
                this.handleCloseedit();
                window.location.reload();

            }

        } catch (error) {
            console.error(error);
            this.handleCloseedit();
            toast.error('Une erreur est survenue');
        }
    }


    render() {

        return (
            <div>
                <ToastContainer />
                <Sidebarsuper/>

                <main className="main-wrap">
                    <Header history={this.props.history}/>

                    <Container>
                        <Row >
                            <Col sm={12}>
                                <h2 className="title">Liste des Elèves
                                    {/*<Button style={{ marginRight:"5px" }} variant="outline-success" onClick={this.handleShowcreate}>
                                        <i className="fa fa-plus"></i> Affecter un élève à une école/classe
                                    </Button>*/}
                                </h2>
                            </Col>

                            {/*<Modal show={this.state.showcreate} scrollable centered onHide={this.handleClosecreate}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Créer un élève</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Elève</Form.Label>
                                            <Form.Select value={this.state.classe} onChange={this.handleSelectChange} name="classe">
                                                <option>--Choisir--</option>
                                                {this.state.eleves?.map((eleve) => (
                                                    <option value={eleve[5]}>{ eleve[1] }</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Classe et Ecole</Form.Label>
                                            <Form.Select value={this.state.classe} onChange={this.handleSelectChange} name="classe">
                                                <option>--Choisir--</option>
                                                {this.state.classes?.map((classe) => (
                                                    <option value={classe[5]+'/'+classe[2]}>{classe[0] +'--'+ classe[1] }</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Form>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="danger" onClick={this.handleClosecreate}>
                                        Fermer
                                    </Button>
                                    <Button variant="primary" onClick={this.addeleve}>
                                        Enregistrer
                                    </Button>
                                </Modal.Footer>
                            </Modal>*/}

                        </Row>

                        <Row className="justify-content-md-center" style={{paddingTop: "80px"}}>

                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th style={{ color:"black" }}>N°</th>
                                    <th style={{ color:"black" }}>Ecole</th>
                                    <th style={{ color:"black" }}>Classe</th>
                                    <th style={{ color:"black" }}>Nom de lélève</th>
                                    <th style={{ color:"black" }}>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.loading ? <Spinner animation="border" variant="success" /> : null}

                                {this.state.eleves.length ? (
                                    <>
                                        {this.state.eleves.map((eleve, index) => (

                                            <tr>
                                                <td>{index+1}</td>
                                                <td>{eleve[0]}</td>
                                                <td>{eleve[1]}</td>
                                                <td>{eleve[2].nom}</td>
                                                <td>
                                                    <Button style={{ marginRight:"5px" }} variant="outline-primary" onClick={async () => this.handleShowview(eleve[2], eleve[1], eleve[0])}>
                                                        <i className="fa fa-eye"></i>
                                                    </Button>
                                                    <Button style={{ marginRight:"5px" }} variant="outline-info" onClick={async () => this.handleShowedit(eleve[2], eleve[3], eleve[4])}>
                                                        <i className="fa fa-edit"></i>
                                                    </Button>
                                                    <Button style={{ marginRight:"5px" }} variant="outline-danger" onClick={async () => this.handleShowdelete( eleve[2].uid, eleve[3], eleve[4] ) }>
                                                        <i className="fa fa-trash"></i>
                                                    </Button>

                                                </td>
                                            </tr>

                                        ))}
                                    </>
                                ):(
                                    <>

                                        <h1  style={{ display: this.state.trouve ? "block" : "none" }} >Elèves non trouvés</h1>

                                    </>
                                )

                                }

                                </tbody>
                            </Table>


                            <Modal show={this.state.showview} size="lg" scrollable centered onHide={this.handleCloseview}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Détails de l'élève</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row className="justify-content-md-center">
                                        <Col sm={3}>
                                            <Card
                                                className="mb-2 mt-4"
                                            >
                                                <Card.Header className="text-center text-white" style={{ backgroundColor:"darkgreen"}}><Card.Title> Nom de l'élève </Card.Title></Card.Header>
                                                <Card.Body bg="success">
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className="text-center" sm={12}>
                                                                <span>{this.state.nomv}</span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col sm={3}>
                                            <Card
                                                
                                                className="mb-2 mt-4"
                                            >
                                                <Card.Header className="text-center text-white" style={{ backgroundColor:"darkgreen"}}><Card.Title> Prénom de l'élève </Card.Title></Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className="text-center" sm={12}>
                                                                <span>{this.state.prenomv}</span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col sm={3}>
                                            <Card
                                                className="mb-2 mt-4"
                                            >
                                                <Card.Header className="text-center text-white" style={{ backgroundColor:"darkgreen"}}><Card.Title> Sexe </Card.Title></Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className="text-center" sm={12}>
                                                                <span>{JSON.stringify(this.state.sexev)}</span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col sm={3}>
                                            <Card
                                                className="mb-2 mt-4"
                                            >
                                                <Card.Header className="text-center text-white" style={{ backgroundColor:"darkgreen"}}><Card.Title> Email </Card.Title></Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className="text-center" sm={12}>
                                                                <span>{JSON.stringify(this.state.emailv)}</span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-md-center">
                                        <Col sm={3}>
                                            <Card
                                                className="mb-2 mt-4"
                                            >
                                                <Card.Header className="text-center text-white" style={{ backgroundColor:"darkgreen"}}><Card.Title> Numéro téléphone </Card.Title></Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className="text-center" sm={12}>
                                                                <span>{this.state.numero_telv}</span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col sm={3}>
                                            <Card
                                                className="mb-2 mt-4"
                                            >
                                                <Card.Header className="text-center text-white" style={{ backgroundColor:"darkgreen"}}><Card.Title> Code pays </Card.Title></Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className="text-center" sm={12}>
                                                                <span>{this.state.codeCountryv}</span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col sm={3}>
                                            <Card
                                                className="mb-2 mt-4"
                                            >
                                                <Card.Header className="text-center text-white" style={{ backgroundColor:"darkgreen"}}><Card.Title> Pays </Card.Title></Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className="text-center" sm={12}>
                                                                <span>{JSON.stringify(this.state.paysv)}</span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col sm={3}>
                                            <Card
                                                className="mb-2 mt-4"
                                            >
                                                <Card.Header className="text-center text-white" style={{ backgroundColor:"darkgreen"}}><Card.Title> Ville </Card.Title></Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className="text-center" sm={12}>
                                                                <span>{JSON.stringify(this.state.villev)}</span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-md-center">
                                        <Col sm={3}>
                                            <Card
                                                className="mb-2 mt-4"
                                            >
                                                <Card.Header className="text-center text-white" style={{ backgroundColor:"darkgreen"}}><Card.Title> date de naissance </Card.Title></Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className="text-center" sm={12}>
                                                                <span>{this.state.dateNaissancev}</span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col sm={3}>
                                            <Card
                                                className="mb-2 mt-4"
                                            >
                                                <Card.Header className="text-center text-white" style={{ backgroundColor:"darkgreen"}}><Card.Title> Photo de profil </Card.Title></Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className="text-center" sm={12}>
                                                                <span>{this.state.photo_profilv}</span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col sm={3}>
                                            <Card
                                                className="mb-2 mt-4"
                                            >
                                                <Card.Header className="text-center text-white" style={{ backgroundColor:"darkgreen"}}><Card.Title> Image </Card.Title></Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className="text-center" sm={12}>
                                                                <span>{this.state.imageUrlv}</span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col sm={3}>
                                            <Card
                                                className="mb-2 mt-4"
                                            >
                                                <Card.Header className="text-center text-white" style={{ backgroundColor:"darkgreen"}}><Card.Title> Complétèment enregistré ?  </Card.Title></Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className="text-center" sm={12}>
                                                                <span>{this.state.isCompleteRegistedv}</span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="danger" onClick={this.handleCloseview}>
                                        Retour
                                    </Button>
                                </Modal.Footer>
                            </Modal>


                            <Modal show={this.state.showdelete} centered onHide={this.handleClosedelete}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Etes vous sûr de supprimer cet élève ? </Modal.Title>
                                </Modal.Header>
                                <Modal.Footer>
                                    <Button variant="danger" onClick={this.handleClosedelete}>
                                        Non
                                    </Button>
                                    <Button variant="success" onClick={this.deleteeleve}>
                                        Oui
                                    </Button>
                                </Modal.Footer>
                            </Modal>


                            <Modal show={this.state.showedit} scrollable centered onHide={this.handleCloseedit}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Mise à jour de l'eleve</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Nom</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled
                                                autoFocus
                                                value={this.state.nom}
                                                onChange={this.handleChange}
                                                name="nom"
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Classe et Ecole</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled
                                                autoFocus
                                                value={this.state.nomCombine}
                                            />
                                            <Form.Select value={this.state.classe} onChange={this.handleSelectChange} name="classe">
                                                <option>--Choisir--</option>
                                                {this.state.classes?.map((classe) => (
                                                    <option value={ classe[5]+'/'+classe[2]+'/'+classe[0] +'--'+ classe[1] }>{classe[0] +'--'+ classe[1] }</option>
                                                    ))}
                                            </Form.Select>
                                        </Form.Group>

                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="danger" onClick={this.handleCloseedit}>
                                        Fermer
                                    </Button>
                                    <Button variant="primary" onClick={async () => this.editeleve( this.state.ecole, this.state.idecoleupdate ) }>
                                        Enregistrer
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                        </Row>


                    </Container>


                </main>
            </div>
        );
    }

}

export default SuperEleve;
