import React, {useContext, useEffect, useState} from "react";
import app from "./base.js";
import { auth } from "./base.js";
import {getAuth, onAuthStateChanged, RecaptchaVerifier, signInWithPhoneNumber} from "firebase/auth";
import {FirebaseProvider} from "../providers/FirebaseProvider"

export const AuthContext = React.createContext();

export function AuthProvider({ children }) {
    let [firebaseUser, setFirebaseUser] = useState(null);
    let [currentUser, setCurrentUser] = useState(null);
    let [pending, setPending] = useState(true);

    function setUpRecaptha(phone) {
        const auth = getAuth(app)
        const recaptchaVerifier = new RecaptchaVerifier(
            "recaptcha-container",
            {},
            auth
        );
        recaptchaVerifier.render();
        return signInWithPhoneNumber(auth, phone, recaptchaVerifier);
    }

    useEffect(() => {
        console.log("1111111111111111");
        onAuthStateChanged(auth, async (user) => {
            console.log("222222222222222");
            if(user){
                currentUser = await FirebaseProvider.getUtilisateurFromUid(user.uid);
                console.log("================");
                console.log("Utilisateur", currentUser);
            }

            firebaseUser = user;
            pending = false;
            setFirebaseUser(user);
            setPending(false);
            setCurrentUser(currentUser);
        });
    }, [firebaseUser]);

    if(pending){
        return <>Loading...</>
    }

    return (
        <AuthContext.Provider
            value={{
                firebaseUser,
                setUpRecaptha,
                currentUser
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export function useUserAuth() {
    return useContext(AuthContext);
}