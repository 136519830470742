export const colormap = [
    {
        foreground: "rgb(28, 179, 35)",
        background: "rgba(28, 179, 35, 0.7)"
    },
    {
        foreground: "rgb(191, 150, 32)",
        background: "rgba(245, 197, 60, 0.7)"
    },
    {
        foreground: "rgb(104, 186, 240)",
        background: "rgba(104, 186, 240, 0.7)"
    },
    {
        foreground: "rgb(50, 50, 50)",
        background: "rgba(200, 200, 200, 0.1)"
    },
]