import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    Colors
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    Colors,
);



const NotesHistoryChart = ({notesHistory, matiere, backgroundColor, borderColor}) => {
    if(!notesHistory || Object.keys(notesHistory).length==0){
        return <div className="my-5 text-center">Aucune donnée à afficher</div>;
    }

    const options = {
        responsive: true,
        plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Note',
            },
            tooltip: {
                mode: 'index'
            },
        },
        interaction: {
          mode: 'nearest',
          axis: 'x',
          intersect: false
        },
        scales: {
            x: {
                title: {
                  display: true,
                  text: 'Date'
                }
              },
            y: {
                stacked: true,
                title: {
                  display: true,
                  text: "Note (/20)"
                }
              }
          }
    };


    const labels = Object.keys(notesHistory);
    const values = [];
    for(let date of Object.keys(notesHistory)){
        values.push(notesHistory[date] / 5); // Conversion to grades over 20
    }

    const data = {
        labels: labels,
        datasets: [
            {
                label: matiere || 'Global',
                data: values,
                backgroundColor: backgroundColor || 'rgba(65,105,225, 0.4)',
                borderColor: borderColor || 'rgba(65,105,225, 1)',
                borderWidth: 4,
            },
        ],
    };

    return (
        <Bar options={options} data={data}></Bar>
    );
};

export default NotesHistoryChart;
