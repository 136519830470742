import * as React from "react";
import { useState, useEffect, Fragment, useContext, Component } from 'react';
import Header from "../components/Header";
import Sidebar from "./../components/sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import {Link, NavLink, Redirect} from "react-router-dom";
import {Card, Tab, Tabs} from "react-bootstrap";
import Graphiquearea from "../components/chart/Graphiquearea";
import Barrearea from "../components/chart/Barrearea";
import Radararea from "../components/chart/Radararea";
import Evolutionarea from "../components/chart/Evolutionarea";
import Evolutionareamat from "../components/chart/Evolutionareamat";
import app from "./base.js";
import {auth} from "./base.js";
import {db} from "./base.js";
import PhoneNumber from 'libphonenumber-js';
import {
    doc,
    onSnapshot,
    updateDoc,
    setDoc,
    deleteDoc,
    collection,
    serverTimestamp,
    getDocs,
    query,
    where,
    orderBy,
    limit,
} from 'firebase/firestore';
import {AuthContext, useUserAuth} from "./Auth";
import Spinner from "react-bootstrap/Spinner";
import Barreareamat from "../components/chart/Barreareamat";

class HomeScreenEleve extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            totaleleveec: 0,
            totalelevecl: 0,
            roles: [],
            sonRole: "",
            ecole: [],
            classes: [],
            eleves: [],
            elevesliste: [],
            elevesclasse: [],
            elevesuid: [],
            loadingecole : false,
            loadingusers : false,
            loadingeleves : false,
            loadingclasses : false,
            nomec: [],
            statsec: [],

            nomc: [],
            statsc: [],
            moyenneG: 0,
            moyenneMax: 0,
            moyenneMin: 0,
            moyenneGgros: 0,
            displayc: true,

            nomel: [],
            nomvrai: "",
            statsel: [],
            notesel: [],
            matieres: [],
            statscompris: [],
            statssuperfi: [],
            statsnonfaits: [],
            isLoadingcompris: true,
            isLoadingnoncompris: true,
            isLoadingsuperfi: true,
            isLoadingnonfaits: true,
            statsnoncompris: [],
            statscompetences: [],
            statsnotes: [],
            statstemps: [],
            matsolo: "",
            matsolocompe: "",
            matsoloevolu: "",
            displayel: false,
            stateSelect: false,


        };
    }

    componentDidMount() {
        const { ideleve, idecole, idclasse } = this.props.match.params;

        const user = auth.currentUser;

        const parsedNumber = PhoneNumber(user.phoneNumber);
        const countryCode = parsedNumber.country;
        const allNumber = parsedNumber.number;
        const nationalNumber = parsedNumber.nationalNumber;

        const colletionRefecole = collection(db, 'ecoles');
        const colletionRefusers = collection(db, 'utilisateurs');
        const colletionRefclasses = collection(db, 'ecoles/'+idecole+'/classes/');


        const qecole = query(
            colletionRefecole
        );
        const qclasses = query(
            colletionRefclasses
        );
        const qusers = query(
            colletionRefusers,
            where('uid', '==', ideleve)
        );

        onSnapshot(qecole, (querySnapshot) => {
            const ecole = [];
            querySnapshot.forEach((doc) => {
                if(doc.id === idecole){
                    ecole.push(doc.data());
                }else {
                    return false
                }
            });
            this.setState({ecole});
            //mettre le loading
        });

        onSnapshot(qclasses, (querySnapshot) => {
            const classes = [];
            querySnapshot.forEach((doc) => {
                if (doc.id === idclasse){
                    classes.push(doc.data());
                }else {
                    return false
                }
            });
            const nomc = classes[0].nom
            this.setState({classes});
            this.setState({nomc});

        });

        const nomvrai = "";
        onSnapshot(qusers, (querySnapshot) => {
            const users = [];
            querySnapshot.forEach((doc) => {
                users.push(doc.data());
            });
            this.setState({users});
            this.setState({nomvrai: users[0].nom});
            //Mettre les loading

            this.state.users.forEach((user) => {
                onSnapshot(query( collection(db, 'utilisateurs/'+user.uid+'/roles') ), (querySnapshot) => {
                    const roles = [];
                    querySnapshot.forEach((doc) => {
                        roles.push(doc.data());
                    });
                    this.setState({roles});
                });
            });

        });


        onSnapshot(query( collection(db, 'utilisateurs/'+ideleve+'/stats'), orderBy("date","asc") ), (querySnapshot) => {
            const statsel = [];
            const notesel = [];
            const matieres = [];
            let previousValue = null;
            querySnapshot.forEach((doc) => {
                const currentValue = (doc.data().title);
                const isValuePresent = statsel.some(obj => obj['title'] === currentValue);
                const currentmatiere = (doc.data().exo.split("/")[5]);
                const isValuePresentmat = matieres.includes(currentmatiere);

                if (isValuePresent) {
                    return false;
                } else {
                    statsel.push(doc.data());
                    notesel.push(doc.data().note);
                }

                if (!isValuePresentmat) {
                    matieres.push(doc.data().exo.split("/")[5])
                }else {
                    return false
                }

            });
            this.setState({statsel});
            this.setState({notesel});
            this.setState({matieres});

            const statscompris = [];
            const statssuperfi = [];
            const statsnonfaits = [];
            const statscompetences = [];
            const statsnoncompris = [];

            this.state.statsel.forEach((stat) => {
                if (stat.note >= 80){
                    statscompris.push(stat.title);
                    //statscompetences.push([stat.date.substring(0,4) , parseInt(stat.note.toFixed(2)), 0 ]);

                }else if (stat.note < 80 && stat.note >= 50){
                    statssuperfi.push(stat.title);
                }else{
                    statsnoncompris.push(stat.title);
                }
            });

            this.setState({statscompris});
            this.setState({statsnoncompris});
            this.setState({statssuperfi});
            this.setState({statsnonfaits});
            this.setState({statscompetences});
            this.setState({isLoadingnoncompris : false})
            this.setState({isLoadingsuperfi : false})

        });


    }

    changeTempsmat = (matsolo) => {
        this.setState({matsolo});
    }

    changeTempsmatevolu = (matsoloevolu) => {
        this.setState({matsoloevolu});
    }

    changeTempsmatcompe = (matsolocompe) => {
        this.setState({matsolocompe});
    }

    changeTempsTout = () => {
        this.setState({matsolocompe : ""});
    }

    changeTempsToutEvolu = () => {
        this.setState({matsoloevolu : ""});
    }


    render() {
/*        if (this.state.sonRole !== "eleve" ) {
            return <Redirect to="/" />
        }*/


        return (
            <div>
                <Sidebar/>
                <main className="main-wrap">
                    <Header history={this.props.history}/>

                    <Container>
                        <Row>
                            <Col sm={12}>
                                {/*{loadingusers ? <h1>Loading...</h1> : null}*/}
                                <h5 className="">Bonjour, <span style={{color: "#1cb323"}}>
                                {this.state.users.map((user) => (
                                    user.nom
                                ))} /
                                    {this.state.roles.map((role) => (
                                        role.role
                                    ))}
                                </span>
                                </h5>
                            </Col>

                            <Col sm={6}>
                                {/*{loadingecole ? <h1>Loading...</h1> : null}*/}
                                {this.state.ecole.map((ecol) => (
                                    <h2 className="title">{ecol.nom} / niveau {ecol.niveau} / {this.state.nomc}</h2>
                                ))}
                            </Col>

                            {/*{loadingclasses ? <h1>Loading...</h1> : null}*/}

                        </Row>


                        <Row className="justify-content-md-center" style={{paddingTop: "20px"}}>

                            {/*<Col sm={4} >

                                <Card
                                    bg="primary"
                                    text="white"
                                    style={{width: '100%'}}
                                    className="mb-2 mt-4"
                                >
                                    <Card.Header className="text-center"><Card.Title> Statistiques
                                        Globales </Card.Title></Card.Header>
                                    <Card.Body>
                                        <Card.Text>
                                            <Row>
                                                <Col className="text-center" sm={12}>
                                                <span style={{
                                                    fontSize: "12px",
                                                    color: "white"
                                                }}> Moyenne Generale </span> <br></br>
                                                    <span style={{fontSize: "40px", fontWeight: "bold"}}> {this.state.moyenneG.toFixed(2)}/20</span>
                                                </Col>
                                            </Row>
                                            <Row className="text-center" style={{marginTop: "20px"}}>
                                                <Col className="text-center" sm={6}>
                                                <span style={{
                                                    fontSize: "12px",
                                                    color: "#f00020"
                                                }}> Moyenne minimum </span> <br></br>
                                                    <span style={{fontSize: "20px", color: "#f00020"}}> {this.state.moyenneMin.toFixed(2)}/20 </span>
                                                </Col>
                                                <Col className="text-center" sm={6}>
                                                <span style={{
                                                    fontSize: "12px",
                                                    color: "#b0f2b6"
                                                }}> Moyenne maximale </span> <br></br>
                                                    <span style={{fontSize: "20px", color: "#b0f2b6"}}> {this.state.moyenneMax.toFixed(2)} /20 </span>
                                                </Col>
                                            </Row>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>*/}

                            <Col sm={12}>

                                <Tabs
                                    defaultActiveKey="home"
                                    id="fill-tab-example"
                                    className="mb-3"
                                    fill
                                >
                                    <Tab eventKey="home" title="Compétences">
                                        <button onClick={async () => this.changeTempsTout()}
                                                type="button" className="btn btn-seconday" style={{ whiteSpace:"nowrap", marginRight:"10px" , marginBottom:"10px" }}>
                                            Tous les stats
                                        </button>
                                        {this.state.matieres.map((mat) => (
                                            <button onClick={async () => this.changeTempsmatcompe(mat)}
                                                    type="button" className="btn btn-seconday" style={{ whiteSpace:"nowrap", marginRight:"10px" , marginBottom:"10px" }}>
                                                {mat}
                                            </button>
                                        ))}
                                        <Row style={{backgroundColor: "#b0f2b6"}}>
                                            <Col style={{textAlign: "center"}} sm={12}>
                                                <div className="table-responsive card">
                                                    <Graphiquearea
                                                        nomel={this.state.nomvrai}
                                                        notesel={this.state.statsel}
                                                        classeselect={this.state.nomc}
                                                        matsolocompe={this.state.matsolocompe}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab>
                                    <Tab eventKey="profile" title="Notes">
                                        <button onClick={async () => this.changeTempsToutEvolu()}
                                                type="button" className="btn btn-seconday" style={{ whiteSpace:"nowrap", marginRight:"10px" , marginBottom:"10px" }}>
                                            Toutes
                                        </button>
                                        {this.state.matieres.map((mat) => (
                                            <button onClick={async () => this.changeTempsmatevolu(mat)}
                                                    type="button" className="btn btn-seconday" style={{ whiteSpace:"nowrap", marginRight:"10px" , marginBottom:"10px" }}>
                                                {mat}
                                            </button>
                                        ))}
                                        <Row style={{backgroundColor: "#b0f2b6"}}>
                                            <Col sm={6}>
                                                <div className="table-responsive card">
                                                    <Radararea
                                                        nomel={this.state.nomvrai}
                                                        notesel={this.state.statsel}
                                                        classeselect={this.state.nomc}
                                                    />
                                                </div>
                                            </Col>
                                            <Col sm={6}>
                                                <div className="table-responsive card">
                                                    <Evolutionarea
                                                        nomel={this.state.nomvrai}
                                                        notesel={this.state.statsel}
                                                        classeselect={this.state.nomc}
                                                        matsoloevolu={this.state.matsoloevolu}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab>
                                    <Tab eventKey="longer-tab" title="Temps passé">
                                        {this.state.matieres.map((mat) => (
                                            <button onClick={async () => this.changeTempsmat(mat)}
                                                    type="button" className="btn btn-seconday" style={{ whiteSpace:"nowrap", marginRight:"10px" , marginBottom:"10px" }}>
                                                {mat}
                                            </button>
                                        ))}
                                        <Row style={{backgroundColor: "#b0f2b6"}}>
                                            <Col sm={6}>
                                                <div className="table-responsive card">
                                                    <Barrearea nomel={this.state.nomvrai}
                                                               statsel={this.state.statsel}
                                                               classeselect={this.state.nomc}
                                                    />
                                                </div>
                                            </Col>
                                            <Col sm={6}>
                                                <div className="table-responsive card">
                                                    <Barreareamat
                                                        matsolo={this.state.matsolo}
                                                        statsel={this.state.statsel}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab>

                                </Tabs>

                                <Row style={{marginTop: "20px"}}>
                                    <Col sm={6}>
                                        <div
                                            className="card mx-auto"
                                        >
                                            <div className="card-body" style={{ textAlign:"left" }}>

                                                <h4 className="card-title" style={{paddingBottom: "10px"}}>
                                                    <span className="badge bg-dark">
                                                {this.state.statssuperfi.length}
                                            </span>Exercices
                                                    superficiels </h4>
                                                {/*{JSON.stringify(this.state.statsel)}*/}
                                                {this.state.isLoadingsuperfi ? <Spinner style={{ textAlign:"center" }} animation="border" variant="success" /> : null}
                                                <nav style={{paddingTop: "0px", maxHeight: "400px", overflowY: "auto"}}>
                                                    <ul className="menu-aside">
                                                        {this.state.statssuperfi.map((stats) => (
                                                            <li className="menu-item border menu-link">
                                                                <Image src="/images/logo.png" style={{width: "20%"}} fluid/>
                                                                <span className="text" style={{ whiteSpace:"pre-wrap" }}>{stats}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </nav>

                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6}>

                                        <div
                                            className="card mx-auto"
                                        >
                                            <div className="card-body" style={{ textAlign:"left" }}>

                                                <h4 className="card-title" style={{paddingBottom: "10px"}}>
                                                    <span className="badge bg-dark">
                                                {this.state.statsnoncompris.length}
                                            </span>Exercices pas
                                                    compris</h4>
                                                {this.state.isLoadingnoncompris ? <Spinner style={{ textAlign:"center" }} animation="border" variant="success" /> : null}
                                                <nav style={{ textAlign:"left", paddingTop: "0px", maxHeight: "400px", overflowY: "auto"}}>
                                                    <ul className="menu-aside">

                                                        {this.state.statsnoncompris.map((stats) => (
                                                            <li className="menu-item border menu-link">
                                                                <Image src="/images/logo.png" style={{width: "20%"}} fluid/>
                                                                <span className="text" style={{ whiteSpace:"pre-wrap" }}>{stats}</span>
                                                            </li>
                                                        ))}

                                                    </ul>
                                                </nav>

                                            </div>
                                        </div>

                                    </Col>
                                </Row>

                            </Col>

                        </Row>
                    </Container>


                </main>
            </div>
        );
    }


}

export default HomeScreenEleve;
