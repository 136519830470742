import React from "react";
import { Link, NavLink } from "react-router-dom";
import Image from 'react-bootstrap/Image'
import {getAuth} from "firebase/auth";
import app from "../screens/base";
import {Button} from "react-bootstrap";

const Sidebarsuper = () => {
  const out = () => {
    const auth = getAuth(app);
    auth.signOut();
  }
  return (
    <div>
      <aside className="navbar-aside" id="offcanvas_aside">
        <div className="aside-top">
          <Link to="/superadmin" className="brand-wrap">
            <Image src="/images/logo.png" fluid
              style={{ width: "80%" }}
              alt="Skoole dash"
            />
          </Link>
          <div>
            <button className="btn btn-icon btn-aside-minimize">
              <i className="text-muted fas fa-stream"></i>
            </button>
          </div>
        </div>

        <nav style={{ paddingTop:"30px" }}>
          <ul className="menu-aside">

            <li className="menu-item">
              <NavLink
                activeClassName="active"
                className="menu-link"
                to="/superadmin"
                exact={true}
              >
                <i className="icon fas fa-home"></i>
                <span className="text">Tableau de bord</span>
              </NavLink>
            </li>

            <li className="menu-item">
              <NavLink
                  activeClassName="active"
                  className="menu-link"
                  to="/superadmin/ecole"
              >
                <i className="icon fas fa-school"></i>
                <span className="text">Ecoles</span>
              </NavLink>
            </li>

            <li className="menu-item">
              <NavLink
                  activeClassName="active"
                  className="menu-link"
                  to="/superadmin/users"
              >
                <i className="icon fas fa-user"></i>
                <span className="text">Utilisateurs</span>
              </NavLink>
            </li>

            <hr/>

            <li className="menu-item">
                <NavLink
                    activeClassName="active"
                    className="menu-link"
                    to="/superadmin/classe"
                >
                  <i className="icon fas fa-list"></i>
                  <span className="text">Classes</span>
                </NavLink>
              </li>

              <li className="menu-item">
                <NavLink
                    activeClassName="active"
                    className="menu-link"
                    to="/superadmin/eleve"
                >
                  <i className="icon fas fa-list"></i>
                  <span className="text">Eleves</span>
                </NavLink>
              </li>

              <li className="menu-item">
                <NavLink
                    activeClassName="active"
                    className="menu-link"
                    to="/superadmin/teacher"
                >
                  <i className="icon fas fa-list"></i>
                  <span className="text">Enseignants</span>
                </NavLink>
              </li>

              <li className="menu-item">
                <NavLink
                    activeClassName="active"
                    className="menu-link"
                    to="/superadmin/admin"
                >
                  <i className="icon fas fa-list"></i>
                  <span className="text">Admins</span>
                </NavLink>
              </li>

          {
            /*
              <li className="menu-item">
                <NavLink
                    activeClassName="active"
                    className="menu-link"
                    to="/superadmin/classe"
                >
                  <i className="icon fas fa-list"></i>
                  <span className="text">Classes</span>
                </NavLink>
              </li>

              <li className="menu-item">
                <NavLink
                    activeClassName="active"
                    className="menu-link"
                    to="/superadmin/admin"
                >
                  <i className="icon fas fa-list"></i>
                  <span className="text">Admins</span>
                </NavLink>
              </li>

              <li className="menu-item">
                <NavLink
                    activeClassName="active"
                    className="menu-link"
                    to="/superadmin/teacher"
                >
                  <i className="icon fas fa-list"></i>
                  <span className="text">Enseignants</span>
                </NavLink>
              </li>

              <li className="menu-item">
                <NavLink
                    activeClassName="active"
                    className="menu-link"
                    to="/superadmin/eleve"
                >
                  <i className="icon fas fa-list"></i>
                  <span className="text">Eleves</span>
                </NavLink>
              </li>

              <li className="menu-item">
                <NavLink
                    activeClassName="active"
                    className="menu-link"
                    to="/superadmin/usersTotal"
                >
                  <i className="icon fas fa-list"></i>
                  <span className="text">Utilisateurs Total</span>
                </NavLink>
              </li>

              <li className="menu-item">
                <NavLink
                    activeClassName="active"
                    className="menu-link"
                    to="/superadmin/users"
                >
                  <i className="icon fas fa-list"></i>
                  <span className="text">Utilisateurs Non assignes</span>
                </NavLink>
              </li>

              <li className="menu-item">
                <NavLink
                  activeClassName="active"
                  className="menu-link"
                  to="/superadmin/helpsuper"
                >
                  <i className="icon fas fa-question-circle"></i>
                  <span className="text">Aide</span>
                </NavLink>
              </li>
            */}

            <hr/>
            
            <li className="menu-item" onClick={out}>
              <a href="#" className="menu-link text-danger" >
                <i className="icon fas fa-sign-out-alt" style={{ color: "rgb(220, 53, 69)" }}></i>
                <span className="text">Déconnexion</span>
              </a>
            </li>

          </ul>
          <br />
          <br />
        </nav>
      </aside>
    </div>
  );
};

export default Sidebarsuper;
