import React, { useEffect, useContext} from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import {getAuth} from "firebase/auth";
import app from "../screens/base";
import {AuthContext} from "../screens/Auth"

const Header = (props) => {
  const {currentUser} = useContext(AuthContext);

  const out = () => {
    const auth = getAuth(app);
    auth.signOut();
  }

  const goToPage = (page, ecole, classe) => {
      if (page === "superadmin"){
        props.history.push(`/superadmin`);
      }else if (page === "admin"){
          props.history.push(`/admin/${ecole}`);
      }else if (page === "enseignant"){
          props.history.push(`/teacher/${ecole}/${classe}`);
      }else if (page === "eleve" ){
          props.history.push(`/eleve/${ecole}/${currentUser.uid}/${classe}`);
      }else {
          return false;
      }
    }

  let displayName = "";
  let imageUrl = "/images/favicon.png";

  if(currentUser){
    // Prepare display name as Firstname L. (first later from lastname)
    if(currentUser.prenom){
      displayName = currentUser.prenom.charAt(0).toUpperCase() + currentUser.prenom.slice(1);
      if(currentUser.nom){
        displayName += " " + currentUser.nom.charAt(0).toUpperCase() + ".";
      }
    } else {
      displayName = currentUser.nom;
    }

    // Update display image
    imageUrl = currentUser.imageUrl || imageUrl;
  }

  useEffect(() => {
    $("[data-trigger]").on("click", function (e) {
      e.preventDefault();
      e.stopPropagation();
      var offcanvas_id = $(this).attr("data-trigger");
      $(offcanvas_id).toggleClass("show");
    });

    $(".btn-aside-minimize").on("click", function () {
      if (window.innerWidth < 768) {
        $("body").removeClass("aside-mini");
        $(".navbar-aside").removeClass("show");
      } else {
        // minimize sidebar on desktop
        $("body").toggleClass("aside-mini");
      }
    });
  }, []);

  return (
    <header className="main-header navbar sticky-top">
      <div className="col-nav">
        <button
          className="btn btn-icon btn-mobile me-auto"
          data-trigger="#offcanvas_aside">
          <i className="md-28 fas fa-bars"></i>
        </button>

        <ul className="nav">
          <li  className="dropdown nav-item">
            <Link className="dropdown-toggle" data-bs-toggle="dropdown" to="#">
              <img
                className="img-xs rounded-circle"
                src={imageUrl}
                alt="User profile picture"
              />
            </Link>
            <div className="dropdown-menu dropdown-menu-end">
              <Link className="dropdown-item fw-bold" to="">
                {displayName}
              </Link>

              {currentUser && currentUser.roles.length ?
              (
                <>
                  <hr className="dropdown-hr"/>
                  <Link className="dropdown-item fw-bold" to="/profil">
                    Changer de rôle
                  </Link>

                  {currentUser && currentUser.roles.map(function (role, i){
                    return (<button className="dropdown-item" onClick={() => goToPage(role.role, role.ecole, role.classe)} key={role.toString()} >
                      <span className="user-role">{role.toString()}</span>
                    </button>)
                  })}
                </>
              ) : <></>
              }

              <hr className="dropdown-hr"/>
              <Link className="dropdown-item" to="/profil">
                Mon profil
              </Link>

              <hr className="dropdown-hr"/>
              <Link onClick={out} className="dropdown-item text-danger" to="#" >
                <i className="icon fas fa-sign-out-alt"></i>
                Déconnexion
              </Link>
            </div>
          </li>
        </ul>

      </div>
    </header>
  );
};

export default Header;
