import * as React from "react";
import {useState, useEffect, Fragment, useContext, Component} from 'react';
import Header from "../../components/Header";
import Sidebar from "../../components/sidebarsuper";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import {Link, NavLink, Redirect} from "react-router-dom";
import {Card, Form, Tab, Tabs} from "react-bootstrap";

import Table from 'react-bootstrap/Table';

import Spinner from 'react-bootstrap/Spinner';

import {auth} from "../../screens/base";
import {db} from "../../screens/base";
import {
    doc,
    onSnapshot,
    updateDoc,
    setDoc,
    deleteDoc,
    collection,
    serverTimestamp,
    getDocs,
    query,
    where,
    orderBy,
    limit,
} from 'firebase/firestore';
import {AuthContext, useUserAuth} from "../../screens/Auth";
import {useFirestoreDocument, useFirestoreQuery} from "@react-query-firebase/firestore";
import PhoneNumber from "libphonenumber-js";
import Sidebarsuper from "../../components/sidebarsuper";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'
import {v4 as uuidv4} from 'uuid';
import PhoneInput from "react-phone-number-input";
import { getCountryCallingCode } from 'react-phone-number-input'
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class SuperAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            ecoles: [],
            ecolesid: [],
            showcreate: false,
            showedit: false,
            showview: false,
            showdelete: false,
            admins: [],
            userid: "",
            roles: [],
            loading: true,
            trouve: false,

            codeCountry : "",
            dateNaissance : "",
            email : "",
            imageUrl : "",
            isCompleteRegisted : false,
            nom : "",
            numero_tel : "",
            pays : "",
            photo_profil : "",
            prenom : "",
            sexe : "",
            superadmin : false,
            token : "",
            uid : "",
            ville : "",
            ecole : "",

            codeCountryv : "",
            dateNaissancev : "",
            emailv : "",
            imageUrlv : "",
            isCompleteRegistedv : false,
            nomv : "",
            numero_telv : "",
            paysv : "",
            photo_profilv : "",
            prenomv : "",
            sexev : "",
            superadminv : false,
            tokenv : "",
            uidv : "",
            villev : "",
            ecolev : "",

            iddelete: "",
            idroledelete: [],
            idecoledelete: "",
            idupdate: "",
            idecoleupdate: "",
            ecoleAUpdate: [],
            adminsUpdate: [],

        };
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    componentDidMount() {
        const colletionRefusers = collection(db, 'utilisateurs');
        const colletionRefecole = collection(db, 'ecoles');

        const user = auth.currentUser;
        const parsedNumber = PhoneNumber(user.phoneNumber);
        const countryCode = parsedNumber.country;
        const allNumber = parsedNumber.number;
        const nationalNumber = parsedNumber.nationalNumber;
        const userid = ""

        const qecole = query(
            colletionRefecole);

        onSnapshot(qecole, (querySnapshot) => {
            const ecoles = [];
            const ecolesid = [];
            querySnapshot.forEach((doc) => {
                ecoles.push(doc.data());
                ecolesid.push({id: doc.id, nom: doc.data().nom, admin: doc.data().admins});
            });
            this.setState({ecoles});
            this.setState({ecolesid});

            const admins = []
            ecolesid.forEach((ecole) => {

                ecole.admin.forEach((admin) => {

                    onSnapshot(query( collection(db, 'utilisateurs'), where('uid', '==', admin) ), (querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            admins.push([ecole.nom, doc.data(), ecole.id])
                        });
                        this.setState({admins})
                        this.setState({loading : false})
                    });

                });

            });


        });


    }

    handleClosecreate = () => {
        this.setState({showcreate: false})
    }

    handleCloseview = () => {
        this.setState({showview: false})
    }

    handleCloseedit = () => {
        this.setState({showedit: false})
    }

    handleClosedelete = () => {
        this.setState({showdelete: false})
    }

    handleShowcreate = () => {
        this.setState({ showcreate: true });
    }

    handleShowview = (admin, ecole) => {

        this.setState({codeCountryv : admin.codeCountry})
        this.setState({dateNaissancev : admin.dateNaissance})
        this.setState({emailv : admin.email})
        this.setState({imageUrlv : admin.imageUrl})
        this.setState({isCompleteRegistedv : admin.isCompleteRegisted})
        this.setState({nomv : admin.nom})
        this.setState({numero_telv : admin.numero_tel})
        this.setState({paysv : admin.pays})
        this.setState({photo_profilv : admin.photo_profil})
        this.setState({prenomv : admin.prenom})
        this.setState({sexev : admin.sexe})
        this.setState({superadminv : admin.superadmin})
        this.setState({tokenv : admin.token})
        this.setState({uidv : admin.uid})
        this.setState({villev : admin.ville})
        this.setState({ecolev : ecole})

        this.setState({ showview: true });
    }

    handleShowdelete = (iddelete, idecoledelete) => {
        this.setState({iddelete})
        this.setState({idecoledelete})

        this.updateEcole(idecoledelete)

        this.setState({ showdelete: true });
    }

    handleShowedit = (admin, idupdate) => {
        this.setState({codeCountry : admin.codeCountry})
        this.setState({dateNaissance : admin.dateNaissance})
        this.setState({email : admin.email})
        this.setState({imageUrl : admin.imageUrl})
        this.setState({isCompleteRegisted : admin.isCompleteRegisted})
        this.setState({nom : admin.nom})
        this.setState({numero_tel : admin.codeCountry+admin.numero_tel})
        this.setState({pays : admin.pays})
        this.setState({photo_profil : admin.photo_profil})
        this.setState({prenom : admin.prenom})
        this.setState({sexe : admin.sexe})
        this.setState({superadmin : admin.superadmin})
        this.setState({token : admin.token})
        this.setState({uid : admin.uid})
        this.setState({ville : admin.ville})
        //this.setState({ecole : ecole})

        this.setState({ idupdate: admin.uid });
        this.setState({ idecoleupdate: idupdate });

        this.setState({ showedit: true });

    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            [name]: value
        });
    }

    handlePhoneChange = (value) => {
        this.setState({ numero_tel: value })
    }

    handleChangeCheck = (event) => {
        if (event.target.value){
            this.setState({ isCompleteRegisted: true })
        }else {
            this.setState({ isCompleteRegisted: false})
        }
    }

    handleChangeCheckSuperAdmin = (event) => {
        if (event.target.value){
            this.setState({ superadmin: true })
        }else {
            this.setState({ superadmin: false})
        }
    }



    handleSelectChange(event) {
        this.setState({ ecole: event.target.value });
        this.updateEcole( event.target.value)
    }

    updateEcole = (id) => {
        const colletionRefecole = collection(db, 'ecoles');
        const qecole = query(
            colletionRefecole, where('id', '==', id));

        onSnapshot(qecole, (querySnapshot) => {
            const ecoleAUpdate = [];
            querySnapshot.forEach((doc) => {
                ecoleAUpdate.push([doc.id, doc.data()]);
            });
            this.setState({ecoleAUpdate});
        });

    }


    // ADD FUNCTION
    addadmin = async (event) => {

        const idecole = this.state.ecole

        const colletionRefAdmin = collection(db, 'utilisateurs');

        const colletionRefecole = collection(db, 'ecoles');

        const id = uuidv4();
        const idrole = uuidv4();
        const parsedNumber = PhoneNumber(this.state.numero_tel);
        const countryCode = getCountryCallingCode(parsedNumber.country);
        const nationalNumber = parsedNumber.nationalNumber;

        const colletionRefrole = collection(db, 'utilisateurs/'+id+'/roles');

        const newadmin = {
            uid : id,
            nom : this.state.nom,
            prenom : this.state.prenom,
            sexe : this.state.sexe,
            email : this.state.email,
            dateNaissance : this.state.dateNaissance,
            numero_tel : nationalNumber,
            codeCountry : "+"+countryCode,
            pays : this.state.pays,
            ville : this.state.ville,
            superadmin : this.state.superadmin,
            isCompleteRegisted : this.state.isCompleteRegisted,
            token : this.state.token,
            imageUrl: this.state.imageUrl,
            photo_profil: this.state.photo_profil,
        };

        const newrole = {
            ecole : idecole,
            role : "admin"
        };

        try {

            if (this.state.nom === "" && idecole === "" && this.state.numero_tel === ""){

                toast.error("Le nom , le phone ou l'école de l'administrateur est requis");
                this.handleClosecreate();

            } else {
                const amindes = []
                this.state.ecoleAUpdate[0][1].admins.forEach((ad) => {
                    amindes.push(ad)
                })
                amindes.push(id)

                const updatedEcole = {
                    adresse : this.state.ecoleAUpdate[0][1].adresse,
                    contact : this.state.ecoleAUpdate[0][1].contact,
                    niveau : this.state.ecoleAUpdate[0][1].niveau,
                    nom : this.state.ecoleAUpdate[0][1].nom,
                    pays : this.state.ecoleAUpdate[0][1].pays,
                    sigle : this.state.ecoleAUpdate[0][1].sigle,
                    site_web: this.state.ecoleAUpdate[0][1].site_web,
                    type : this.state.ecoleAUpdate[0][1].type,
                    ville : this.state.ecoleAUpdate[0][1].ville,
                    admins : amindes,
                    enseignants:  this.state.ecoleAUpdate[0][1].enseignants,
                };

                const adminRef = doc(colletionRefAdmin, id);
                await setDoc(adminRef, newadmin);

                const EcoleRef = doc(colletionRefecole, idecole)
                await updateDoc(EcoleRef, updatedEcole);

                const adminRoleRef = doc(colletionRefrole, idrole);
                await setDoc(adminRoleRef, newrole);

                toast.success('Félicitations Administrateur créé');
                this.handleClosecreate();

            }

        } catch (error) {
            console.error(error);
            this.handleClosecreate();
            toast.error('Une erreur est survenu');
        }


    }


    //DELETE FUNCTION
    deleteadmin = async (event) => {
        const colletionRefAdmin = collection(db, 'utilisateurs');
        const colletionRefecole = collection(db, 'ecoles');
        //const colletionRefRole = collection(db, 'utilisateurs'/+this.state.iddelete+'/roles');

        try {
            if (this.state.iddelete === "0"){
                toast.error("L\'administrateur n\'a pas été trouvé");
            } else {
                const amindes = this.state.ecoleAUpdate[0][1].admins.filter((ad) => ad !== this.state.iddelete);

                const updatedEcole = {
                    adresse : this.state.ecoleAUpdate[0][1].adresse,
                    contact : this.state.ecoleAUpdate[0][1].contact,
                    niveau : this.state.ecoleAUpdate[0][1].niveau,
                    nom : this.state.ecoleAUpdate[0][1].nom,
                    pays : this.state.ecoleAUpdate[0][1].pays,
                    sigle : this.state.ecoleAUpdate[0][1].sigle,
                    site_web: this.state.ecoleAUpdate[0][1].site_web,
                    type : this.state.ecoleAUpdate[0][1].type,
                    ville : this.state.ecoleAUpdate[0][1].ville,
                    admins : amindes,
                    enseignants:  this.state.ecoleAUpdate[0][1].enseignants,
                };

                onSnapshot(query( collection(db, 'utilisateurs/'+this.state.iddelete+'/roles') ), (querySnapshot) => {
                    const idroledelete = [];
                    querySnapshot.forEach((doc) => {
                        idroledelete.push(doc.data());
                    });
                    this.setState({idroledelete});
                });

                /*const EcoleRef = doc(colletionRefecole, this.state.idecoledelete)
                await updateDoc(EcoleRef, updatedEcole);

                const roleRef = doc(colletionRefRole, this.state.idroledelete);
                await deleteDoc(roleRef);

                const adminRef = doc(colletionRefAdmin, this.state.iddelete);
                await deleteDoc(adminRef);

                toast.success('Administrateur supprimé correctement');*/
                alert(JSON.stringify(this.state.idroledelete))
                this.handleClosedelete();
                window.location.reload();
            }

        } catch (error) {
            console.error(error);
            this.handleClosedelete();
            toast.error('Une erreur est survenue');
        }
    }


    // EDIT FUNCTION
    editadmin = async (event) => {
        const colletionRefAdmin = collection(db, 'utilisateurs');

        const parsedNumber = PhoneNumber(this.state.numero_tel);
        const countryCode = getCountryCallingCode(parsedNumber.country);
        const nationalNumber = parsedNumber.nationalNumber;

        const updatedadmin = {
            uid : this.state.uid,
            nom : this.state.nom,
            prenom : this.state.prenom,
            sexe : this.state.sexe,
            email : this.state.email,
            dateNaissance : this.state.dateNaissance,
            numero_tel : nationalNumber,
            codeCountry : "+"+countryCode,
            pays : this.state.pays,
            ville : this.state.ville,
            superadmin : this.state.superadmin,
            isCompleteRegisted : this.state.isCompleteRegisted,
            token : this.state.token,
            imageUrl: this.state.imageUrl,
            photo_profil: this.state.photo_profil,
        };

        try {

            if (this.state.nom === "" && this.state.idupdate === "" && this.state.numero_tel === ""){

                toast.error("Le nom , le phone ou l'école de l'administrateur est requis");
                this.handleClosecreate();

            } else {

                const adminRef = doc(colletionRefAdmin, this.state.idupdate);
                await updateDoc(adminRef, updatedadmin);

                toast.success('Administrateur Mise à jour');
                this.handleCloseedit();
                window.location.reload();

            }

        } catch (error) {
            console.error(error);
            this.handleCloseedit();
            toast.error('Une erreur est survenue');
        }
    }




    render() {

        return (
            <div>
                <ToastContainer />
                <Sidebarsuper/>

                <main className="main-wrap">
                    <Header/>

                    <Container>
                        <Row >
                            <Col sm={12}>
                                <h2 className="title">Liste des Administrateurs
                                    <Button style={{ marginRight:"5px" }} variant="outline-success" onClick={this.handleShowcreate}>
                                        <i className="fa fa-plus"></i> Créer un administrateur
                                    </Button>
                                </h2>
                            </Col>

                            <Modal show={this.state.showcreate} scrollable centered onHide={this.handleClosecreate}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Créer un administrateur</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Nom</Form.Label>
                                            <Form.Control
                                                type="text"
                                                autoFocus
                                                value={this.state.nom}
                                                onChange={this.handleChange}
                                                name="nom"
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Prénom</Form.Label>
                                            <Form.Control
                                                type="text"
                                                autoFocus
                                                value={this.state.prenom}
                                                onChange={this.handleChange}
                                                name="prenom"
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Sexe</Form.Label>
                                            <Form.Select value={this.state.sexe} onChange={this.handleChange} name="sexe">
                                                <option>--Choisir--</option>
                                                <option value="Homme">Homme</option>
                                                <option value="Femme" >Femme</option>
                                            </Form.Select>
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Pays</Form.Label>
                                            <Form.Control
                                                type="text"
                                                autoFocus
                                                value={this.state.pays}
                                                onChange={this.handleChange}
                                                name="pays"
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Ville</Form.Label>
                                            <Form.Control
                                                type="text"
                                                autoFocus
                                                value={this.state.ville}
                                                onChange={this.handleChange}
                                                name="ville"
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>date de Naissance</Form.Label>
                                            <Form.Control
                                                type="date"
                                                autoFocus
                                                value={this.state.dateNaissance}
                                                onChange={this.handleChange}
                                                name="dateNaissance"
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                autoFocus
                                                value={this.state.email}
                                                onChange={this.handleChange}
                                                name="email"
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Contact</Form.Label>
                                            <PhoneInput
                                                international
                                                defaultCountry="FR"
                                                className="form-control"
                                                value={this.state.numero_tel}
                                                onChange={this.handlePhoneChange}
                                            />
                                        </Form.Group>


                                        <Form.Group className="mb-3">
                                            <Form.Label>URL De l'image</Form.Label>
                                            <Form.Control
                                                type="url"
                                                autoFocus
                                                value={this.state.imageUrl}
                                                onChange={this.handleChange}
                                                name="imageUrl"
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Est-un super Admin ?</Form.Label>
                                            <Form.Check
                                                type="switch"
                                                checked={this.state.superadmin}
                                                onChange={this.handleChangeCheckSuperAdmin}
                                                name="superadmin"
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Est complètement enregistré ?</Form.Label>
                                            <Form.Check
                                                type="switch"
                                                checked={this.state.isCompleteRegisted}
                                                onChange={this.handleChangeCheck}
                                                name="isCompleteRegisted"
                                            />
                                        </Form.Group>


                                        <Form.Group className="mb-3">
                                            <Form.Label>Ecole</Form.Label>
                                            <Form.Select value={this.state.ecole} onChange={this.handleSelectChange} name="ecole">
                                                <option>--Choisir--</option>
                                                {this.state.ecolesid?.map((ecole) => (
                                                    <option value={ecole.id}>{ecole.nom}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>

                                    </Form>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="danger" onClick={this.handleClosecreate}>
                                        Fermer
                                    </Button>
                                    <Button variant="primary" onClick={this.addadmin}>
                                        Enregistrer
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                        </Row>

                        <Row className="justify-content-md-center" style={{paddingTop: "80px"}}>

                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th style={{ color:"black" }}>N°</th>
                                    <th style={{ color:"black" }}>Ecole</th>
                                    <th style={{ color:"black" }}>Nom</th>
                                    <th style={{ color:"black" }}>Prénom</th>
                                    <th style={{ color:"black" }}>Sexe</th>
                                    <th style={{ color:"black" }}>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.loading ? <Spinner animation="border" variant="success" /> : null}

                                {this.state.admins.length ? (
                                    <>
                                        {this.state.admins.map((admin, index) => (

                                            <tr>
                                                <td>{index+1}</td>
                                                <td>{admin[0]}</td>
                                                <td>{admin[1].nom}</td>
                                                <td>{admin[1].prenom}</td>
                                                <td>{admin[1].sexe}</td>
                                                <td>
                                                    <Button style={{ marginRight:"5px" }} variant="outline-primary" onClick={async () => this.handleShowview(admin[1], admin[0])}>
                                                        <i className="fa fa-eye"></i>
                                                    </Button>
                                                    <Button style={{ marginRight:"5px" }} variant="outline-info" onClick={async () => this.handleShowedit(admin[1], admin[2])}>
                                                        <i className="fa fa-edit"></i>
                                                    </Button>
                                                    <Button style={{ marginRight:"5px" }} variant="outline-danger" onClick={async () => this.handleShowdelete( admin[1].uid, admin[2] ) }>
                                                        <i className="fa fa-trash"></i>
                                                    </Button>

                                                </td>
                                            </tr>

                                        ))}
                                    </>
                                ):(
                                    <>

                                        <h1  style={{ display: this.state.trouve ? "block" : "none" }} >Administrateurs non trouvés</h1>

                                    </>
                                )

                                }

                                </tbody>
                            </Table>


                            <Modal show={this.state.showview} size="lg" scrollable centered onHide={this.handleCloseview}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Détails de l'administrateur</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row className="justify-content-md-center">
                                        <Col sm={3}>
                                            <Card
                                                
                                                className="mb-2 mt-4"
                                            >
                                                <Card.Header className="text-center text-white" style={{ backgroundColor:"darkgreen"}}><Card.Title> Nom de l'administrateur </Card.Title></Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className="text-center" sm={12}>
                                                                <span>{this.state.nomv}</span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col sm={3}>
                                            <Card
                                                
                                                className="mb-2 mt-4"
                                            >
                                                <Card.Header className="text-center text-white" style={{ backgroundColor:"darkgreen"}}><Card.Title> Prénom de l'administrateur </Card.Title></Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className="text-center" sm={12}>
                                                                <span>{this.state.prenomv}</span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col sm={3}>
                                            <Card
                                                
                                                className="mb-2 mt-4"
                                            >
                                                <Card.Header className="text-center text-white" style={{ backgroundColor:"darkgreen"}}><Card.Title> Sexe </Card.Title></Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className="text-center" sm={12}>
                                                                <span>{JSON.stringify(this.state.sexev)}</span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col sm={3}>
                                            <Card
                                                
                                                className="mb-2 mt-4"
                                            >
                                                <Card.Header className="text-center text-white" style={{ backgroundColor:"darkgreen"}}><Card.Title> Email </Card.Title></Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className="text-center" sm={12}>
                                                                <span>{JSON.stringify(this.state.emailv)}</span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-md-center">
                                        <Col sm={3}>
                                            <Card
                                                
                                                className="mb-2 mt-4"
                                            >
                                                <Card.Header className="text-center text-white" style={{ backgroundColor:"darkgreen"}}><Card.Title> Numéro téléphone </Card.Title></Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className="text-center" sm={12}>
                                                                <span>{this.state.numero_telv}</span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col sm={3}>
                                            <Card
                                                
                                                className="mb-2 mt-4"
                                            >
                                                <Card.Header className="text-center text-white" style={{ backgroundColor:"darkgreen"}}><Card.Title> Code pays </Card.Title></Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className="text-center" sm={12}>
                                                                <span>{this.state.codeCountryv}</span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col sm={3}>
                                            <Card
                                                
                                                className="mb-2 mt-4"
                                            >
                                                <Card.Header className="text-center text-white" style={{ backgroundColor:"darkgreen"}}><Card.Title> Pays </Card.Title></Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className="text-center" sm={12}>
                                                                <span>{JSON.stringify(this.state.paysv)}</span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col sm={3}>
                                            <Card
                                                
                                                className="mb-2 mt-4"
                                            >
                                                <Card.Header className="text-center text-white" style={{ backgroundColor:"darkgreen"}}><Card.Title> Ville </Card.Title></Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className="text-center" sm={12}>
                                                                <span>{JSON.stringify(this.state.villev)}</span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-md-center">
                                        <Col sm={3}>
                                            <Card
                                                
                                                className="mb-2 mt-4"
                                            >
                                                <Card.Header className="text-center text-white" style={{ backgroundColor:"darkgreen"}}><Card.Title> date de naissance </Card.Title></Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className="text-center" sm={12}>
                                                                <span>{this.state.dateNaissancev}</span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col sm={3}>
                                            <Card
                                                
                                                className="mb-2 mt-4"
                                            >
                                                <Card.Header className="text-center text-white" style={{ backgroundColor:"darkgreen"}}><Card.Title> Photo de profil </Card.Title></Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className="text-center" sm={12}>
                                                                <span>{this.state.photo_profilv}</span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col sm={3}>
                                            <Card
                                                
                                                className="mb-2 mt-4"
                                            >
                                                <Card.Header className="text-center text-white" style={{ backgroundColor:"darkgreen"}}><Card.Title> Image </Card.Title></Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className="text-center" sm={12}>
                                                                <span>{this.state.imageUrlv}</span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col sm={3}>
                                            <Card
                                                className="mb-2 mt-4"
                                            >
                                                <Card.Header className="text-center text-white" style={{ backgroundColor:"darkgreen"}}><Card.Title> Complétèment enregistré ?  </Card.Title></Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className="text-center" sm={12}>
                                                                <span>{this.state.isCompleteRegistedv}</span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="danger" onClick={this.handleCloseview}>
                                        Retour
                                    </Button>
                                </Modal.Footer>
                            </Modal>


                            <Modal show={this.state.showdelete} centered onHide={this.handleClosedelete}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Etes vous sûr de supprimer cette classe ? {JSON.stringify(this.state.idroledelete)} </Modal.Title>
                                </Modal.Header>
                                <Modal.Footer>
                                    <Button variant="danger" onClick={this.handleClosedelete}>
                                        Non
                                    </Button>
                                    <Button variant="success" onClick={this.deleteadmin}>
                                        Oui
                                    </Button>
                                </Modal.Footer>
                            </Modal>


                            <Modal show={this.state.showedit} scrollable centered onHide={this.handleCloseedit}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Mise à jour de l'admin</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Nom</Form.Label>
                                            <Form.Control
                                                type="text"
                                                autoFocus
                                                value={this.state.nom}
                                                onChange={this.handleChange}
                                                name="nom"
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Prénom</Form.Label>
                                            <Form.Control
                                                type="text"
                                                autoFocus
                                                value={this.state.prenom}
                                                onChange={this.handleChange}
                                                name="prenom"
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Sexe</Form.Label>
                                            <Form.Select value={this.state.sexe} onChange={this.handleChange} name="sexe">
                                                <option>--Choisir--</option>
                                                <option value="Homme">Homme</option>
                                                <option value="Femme" >Femme</option>
                                            </Form.Select>
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Pays</Form.Label>
                                            <Form.Control
                                                type="text"
                                                autoFocus
                                                value={this.state.pays}
                                                onChange={this.handleChange}
                                                name="pays"
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Ville</Form.Label>
                                            <Form.Control
                                                type="text"
                                                autoFocus
                                                value={this.state.ville}
                                                onChange={this.handleChange}
                                                name="ville"
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>date de Naissance</Form.Label>
                                            <Form.Control
                                                type="date"
                                                autoFocus
                                                value={this.state.dateNaissance}
                                                onChange={this.handleChange}
                                                name="dateNaissance"
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                autoFocus
                                                value={this.state.email}
                                                onChange={this.handleChange}
                                                name="email"
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Contact</Form.Label>
                                            <PhoneInput
                                                international
                                                defaultCountry="FR"
                                                className="form-control"
                                                value={this.state.numero_tel}
                                                onChange={this.handlePhoneChange}
                                            />
                                        </Form.Group>


                                        <Form.Group className="mb-3">
                                            <Form.Label>URL De l'image</Form.Label>
                                            <Form.Control
                                                type="url"
                                                autoFocus
                                                value={this.state.imageUrl}
                                                onChange={this.handleChange}
                                                name="imageUrl"
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Est-un super Admin ?</Form.Label>
                                            <Form.Check
                                                type="switch"
                                                checked={this.state.superadmin}
                                                onChange={this.handleChangeCheckSuperAdmin}
                                                name="superadmin"
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Est complètement enregistré ?</Form.Label>
                                            <Form.Check
                                                type="switch"
                                                checked={this.state.isCompleteRegisted}
                                                onChange={this.handleChangeCheck}
                                                name="isCompleteRegisted"
                                            />
                                        </Form.Group>

                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="danger" onClick={this.handleCloseedit}>
                                        Fermer
                                    </Button>
                                    <Button variant="primary" onClick={this.editadmin}>
                                        Enregistrer
                                    </Button>
                                </Modal.Footer>
                            </Modal>


                        </Row>


                    </Container>


                </main>
            </div>
        );
    }

}

export default SuperAdmin;
