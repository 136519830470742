import {db} from "../screens/base.js";
import {
    doc,
    collection,
    getDoc,
    setDoc,
    getDocs,
    addDoc,
    deleteDoc,
    query,
    where
} from 'firebase/firestore';
import {Utilisateur} from "../models/Utilisateur";


export const UTILISATEURS_COLLECTION_PATH = 'utilisateurs';
export const ECOLES_COLLECTION_PATH = 'ecoles';
export const ROLES_COLLECTION_PATH = 'roles';
export const CLASSES_COLLECTION_PATH = 'classes';
export const STATS_COLLECTION_PATH = 'stats';

export class FirebaseProvider {
	static async getDocAtPath(path){
        if(!path){
            return;
        }

		const docRef = doc(db, path);
        const snap = await getDoc(docRef);

        if(!snap.exists()){
        	return null;
        }

        return snap.data();
	}

    static async getAllDocsInCollection(path){
        if(!path){
            return;
        }

        const collecRef = collection(db, path);
        const snap = await getDocs(collecRef);

        let collec = {};
        snap.forEach((doc) => {
          collec[doc.id] = doc.data();
        });

        return collec;
    }

    static async queryCollectionWithCondition(path, conditionField, operator, value){
        if(!path){
            return;
        }

        const collecRef = collection(db, path);
        const q = query(collecRef, where(conditionField, operator, value));
        const snap = await getDocs(q);

        let collec = {};
        snap.forEach((doc) => {
          collec[doc.id] = doc.data();
        });

        return collec;
    }

	static async setDocAtPath(path, object){
        if(!path){
            return;
        }

		const docRef = doc(db, path);
		return await setDoc(docRef, object);
	}

    static async addDocToCollection(path, object){
        if(!path){
            return;
        }

        const collecRef = collection(db, path);
        return await addDoc(collecRef, object);
    }

    static async deleteDocAtPath(path){
        if(!path){
            return;
        }

        const docRef = doc(db, path);
        return await deleteDoc(docRef);
    }

    static async deleteAllDocsAtPath(path){
        if(!path){
            return;
        }

        const collObj = FirebaseProvider.getAllDocsInCollection(path);
        let promises = [];

        for(let docId in collObj){
            promises.push(FirebaseProvider.deleteDocAtPath(path + '/' + docId));
        }

        return Promise.all(promises);
    }



	static async getUtilisateurFromUid(uid){
        if(!uid){
            return;
        }

		let params = {ref:UTILISATEURS_COLLECTION_PATH + '/' + uid};
		let utilisateur = new Utilisateur(params);
		await utilisateur.download();
		return utilisateur;
	}
}