import  {React, useEffect, useState} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {onSnapshot, query, where} from "firebase/firestore";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

const Evolutionareamat = ({nomel, notesel, classeselect, matsoloevolu
                       }) => {

    const labels = [];
    const noteDansTemps = [];
    let unenote = 0;

    notesel.forEach((doc) => {

        const matnow = (doc.exo.split("/")[5]);

        if (matnow === matsoloevolu){

            const currentValue = (doc.date.split(" ")[0]);
            const isValuePresent = labels.includes(currentValue);

            if (!isValuePresent) {
                labels.push(doc.date.split(" ")[0])

                unenote = doc.note

                noteDansTemps.push(unenote);

            } else {
                const index = labels.indexOf(currentValue);

                const anciennote = noteDansTemps[index];
                const newnote = anciennote + doc.note

                noteDansTemps.splice(index, 1, newnote);

            }


        }else {

            return false

        }

    });



    const data = {
        labels,
        datasets: [
            {
                fill: true,
                label: 'Note X/100',
                data: noteDansTemps,
                borderColor: 'rgb(65,105,225)',
                backgroundColor: 'rgba(65,105,225, 0.5)',
            },

        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Evolution des notes de '+nomel+' pour '+matsoloevolu,
            },
        },
    };

    return (

        <Line options={options} data={data} />

    );
};

export default Evolutionareamat;
