import React, {useContext, useEffect, useState} from "react";
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {Link, Redirect} from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier  } from "firebase/auth";
import {AuthContext, useUserAuth} from "./Auth";
import app, {auth} from "./base.js";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import OtpInput from 'react-otp-input';
import {Alert} from "@mui/material";
import {db} from "./base.js";
import {
  doc,
  onSnapshot,
  updateDoc,
  setDoc,
  deleteDoc,
  collection,
  serverTimestamp,
  getDocs,
  query,
  where,
  orderBy,
  limit,
} from 'firebase/firestore';



const Login = ({ history }) => {
  window.scrollTo(0, 0);
  const [phone, setPhone] = useState("");

  const [error, setError] = useState("");
  const [flag, setFlag] = useState(false);
  const [otp, setOtp] = useState("");
  const [result, setResult] = useState("");
  const { setUpRecaptha } = useUserAuth();

  const [resulttemp, setResulttemp] = useState("");

  const setupRecaptcha = async (e) => {
    e.preventDefault();
    console.log(phone);
    setError("");
    if (phone === "" || phone === undefined)
      return setError("Please enter a valid phone number!");
    try {
      const response = await setUpRecaptha(phone);
      setResult(response);
      setFlag(true);
    } catch (err) {
      setError(err.message);
    }
  }

  const change = () => {
    window.location.reload(false);
  }

  const onSignInSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (otp === "" || otp === null) return;

    try {
      await result.confirm(otp).then( async () => {
        const user = auth.currentUser;
        history.replace("/");
      }).catch((error) => {
        console.log(error);
      });

    } catch (err) {
      setError(err.message);
    }
  };

  const {currentUser} = useContext(AuthContext);
  
  if (currentUser) {
    return <Redirect to="/" />
  }

  return (
      <>
        <Container>
          <Row className="justify-content-md-center" style={{ paddingTop:"50px" }}>
            <Col sm={4} className="imgback">
              <Image src="/images/back.png" fluid/>
            </Col>

            <Col sm={4} className="marginphone" >
              <div
                  className="card mx-auto"
                  style={{ maxWidth: "100%" , border:"none" }}
              >
                <div className="card-body">

                  <Image style={{ width:"40%" , marginBottom:"50px" }} src="/images/logo.png" fluid />

                  <h4 className="card-title" style={{ display: !flag ? "block" : "none" }}>Bienvenue</h4>
                  <span style={{ display: !flag ? "block" : "none" }}>Connectez-vous</span>
                  {error && <Alert variant="danger">{error}</Alert>}
                  <form onSubmit={setupRecaptcha} style={{ display: !flag ? "block" : "none" }}>
                    <div className="form-floating" style={{ marginTop:"10px" }}>
                      <PhoneInput
                          international
                          defaultCountry="FR"
                          className="form-control"
                          value={phone}
                          onChange={setPhone}/>
                      <label style={{ color:"green", fontSize:"12px"}} htmlFor="phone">Entrez votre numéro de téléphone</label>
                      <div id="recaptcha-container"></div>
                    </div>
                    <span style={{ fontSize:"12px" }}>Nous allons envoyer un code de vérification à ce numéro de téléphone</span>

                    <div style={{ marginTop:"30px" , marginBottom:"20px" }}>
                      <button type="submit" className="btn btn-primary w-100">
                        Connexion
                      </button>
                      <div id="sign-in-button">
                      </div>
                    </div>
                  </form>

                  <h4 className="card-title" style={{ display: flag ? "block" : "none" }}>Vérifier Code</h4>

                  <span style={{ fontSize:"14px",marginTop:"20px", display: flag ? "block" : "none" }}>Vous avez reçu un code ! Entrez le</span>

                  <form onSubmit={onSignInSubmit} style={{ display: flag ? "block" : "none" }}>
                    <div style={{ marginTop:"50px" }}>
                      <OtpInput
                          value={otp}
                          onChange={setOtp}
                          numInputs={6}
                          inputStyle={{ width:"100%", height:"50px", borderRadius:"15px", boxShadow:"1px 1px 1px 1px #1cb323" }}
                          isInputNum={true}
                          renderSeparator={<span>-</span>}
                          renderInput={(props) => <input {...props} />} />
                    </div>

                    <div style={{ marginTop:"30px" , marginBottom:"20px" }}>
                      <button type="submit" className="btn btn-primary w-100">
                        Vérifier
                      </button>
                      <div id="sign-in-button">
                      </div>
                    </div>

                    <span style={{ display: flag ? "block" : "none" , marginTop:"20px"}} >
                    <strong>Ce numéro n'est pas le votre ?</strong> <a onClick={change} href="#">Changer</a> </span>
                  </form>

                  {/*
                <span style={{ display: !flag ? "block" : "none" }}> <strong>Vous n'avez pas de compte ?</strong> <Link to="/register">Inscrivez-vous</Link> </span>
*/}

                  <Row  className="text-center" style={{ marginTop:"10px", display: !flag ? "block" : "none" }}>
                    <span > Ou continuer avec</span>
                    <Col style={{ marginTop:"10px" }}>
                      <Image style={{ width:"10%", marginRight:"20px"  }}  src="/images/facebook.png" fluid/>
                      <Image style={{ width:"10%"  }}  src="/images/google.png" fluid/>
                    </Col>
                  </Row>

                </div>
              </div>
            </Col>

          </Row>




        </Container>


      </>
  );
};

export default Login;
