import  {React, useEffect, useState} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {onSnapshot, query, where} from "firebase/firestore";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

const Evolutionarea = ({nomel, notesel, classeselect, matsoloevolu
                       }) => {

    const labels = [];
    const noteDansTemps = [];
    let unenote = 0;
    let incre = 1 ;
    notesel.forEach((doc) => {

        if (matsoloevolu.length === 0){

            const currentValue = (doc.date.split(" ")[0]);
            const isValuePresent = labels.includes(currentValue);

            if (!isValuePresent) {
                incre = 1;
                labels.push(doc.date.split(" ")[0])

                unenote = (doc.note/5)/incre

                noteDansTemps.push(unenote);

            } else {
                incre = incre + 1;
                const index = labels.indexOf(currentValue);

                const anciennote = noteDansTemps[index];
                const newnote = (anciennote + ((doc.note)/5))/incre

                noteDansTemps.splice(index, 1, newnote);

            }

        }else {
            const matnow = (doc.exo.split("/")[5]);

            if (matnow === matsoloevolu){
                incre = 1
                const currentValue = (doc.date.split(" ")[0]);
                const isValuePresent = labels.includes(currentValue);

                if (!isValuePresent) {
                    labels.push(doc.date.split(" ")[0])

                    unenote = (doc.note/5)/incre

                    noteDansTemps.push(unenote);

                } else {
                    incre = incre + 1;
                    const index = labels.indexOf(currentValue);

                    const anciennote = noteDansTemps[index];
                    const newnote = (anciennote + (doc.note/5))/incre

                    noteDansTemps.splice(index, 1, newnote);

                }


            }else {

                return false

            }
        }


    });



    const data = {
        labels,
        datasets: [
            {
                fill: true,
                label: 'Note X/20',
                data: noteDansTemps,
                borderColor: 'rgb(65,105,225)',
                backgroundColor: 'rgba(65,105,225, 0.5)',
            },

        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Evolution des notes de '+nomel+' '+matsoloevolu,
            },
        },
    };

    return (

        <Line options={options} data={data} />

    );
};

export default Evolutionarea;
