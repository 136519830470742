export const UNDERSTANDING_STATE_VALUES = ["COMPRIS", "SUPERFICIEL", "PAS_COMPRIS"];

export function exoUnderstandingState (exoResult) {
    const note = exoResult['note'];
    if (note >= 80) {
      return "COMPRIS";
    } else if (note >= 50) {
      return "SUPERFICIEL";
    }

    return "PAS_COMPRIS";
}

export function computeProgramCoverage(notesArray){
    const statesHistory = {};
    const exoStatuses = {};

    // Init current statistics as zero
    const currentStatistics = {};
    for (let state of UNDERSTANDING_STATE_VALUES) {
      currentStatistics[state] = 0;
    }

    let date = "";
    let exoId = "";
    let understandingState;
    for (let exoResult of notesArray) {
      date = exoResult['date'];
      exoId = exoResult['exo'];
      understandingState = exoUnderstandingState(exoResult);

      // Increment the number for the current state
      currentStatistics[understandingState] += 1;

      // If exo has already been seen in the past, decrement the number for its previous state
      if (exoId in exoStatuses) {
        currentStatistics[exoStatuses[exoId]['understandingState']] -= 1;
      }

      // Update and save the new state
      exoStatuses[exoId] = {
        'title': exoResult['title'],
        'file': exoId,
        'understandingState': understandingState,
        'date': date,
        'img': exoResult['img']
      };

      console.log();

      // Take a snapshot of the current statistics
      const day = date.split(" ")[0];
      statesHistory[day] = JSON.parse(JSON.stringify(currentStatistics));
    }

    return statesHistory;
}

export function computeDurationHistory(notesArray){
    const durationHistory = {};
    durationHistory['global'] = {};

    let date = "";
    let exoId = "";
    let subject = "";
    let duration = 0;
    for (let exoResult of notesArray) {
      date = exoResult['date'];
      exoId = exoResult['exo'].split("/").slice(4).join("/");
      duration = exoResult['duration'];
      const subject = exoId.split("/").slice(0, -2).join("/");

      if (!(subject in durationHistory)) {
        durationHistory[subject] = {};
      }

      // Update duration with new value
      let previousDuration;
      const day = date.split(" ")[0];
      previousDuration = durationHistory[subject][day] ?? 0;
      durationHistory[subject][day] = previousDuration + duration;

      previousDuration = durationHistory['global'][day] ?? 0;
      durationHistory['global'][day] = previousDuration + duration;
    }

    return durationHistory;
}

export function computeNotesHistory(notesArray) {
    const currentStatistics = {};
    const notesHistory = {};
    notesHistory['global'] = {};
    currentStatistics['global'] = {};

    let date = "";
    let exoId = "";
    let subject = "";
    let note = 0;
    for (let exoResult of notesArray) {
      date = exoResult['date'];
      exoId = exoResult['exo'].split("/").slice(4).join("/");
      note = exoResult['note'];
      const subject = exoId.split("/").slice(0, -2).join("/");

      if (!(subject in notesHistory)) {
        notesHistory[subject] = {};
        currentStatistics[subject] = {};
      }

      currentStatistics[subject][exoId] = note;
      currentStatistics['global'][exoId] = note;

      // Compute the average at the current instant
      let avg;
      let tmp;
      let vals;
      const day = date.split(" ")[0];

      tmp = currentStatistics[subject];
      vals = Object.values(tmp);
      avg = 0;
      for (let note of vals) {
        avg += note;
      }
      avg = avg / vals.length;
      notesHistory[subject][day] = avg;

      tmp = currentStatistics['global'];
      vals = Object.values(tmp);
      avg = 0;
      for (let note of vals) {
        avg += note;
      }
      avg = avg / vals.length;
      notesHistory['global'][day] = avg;
    }

    return notesHistory;
}