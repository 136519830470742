import React, { PureComponent } from 'react';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

const Radararea = ({nomel, notesel, classeselect
                   }) => {

    const labels = []
    const matmoy = []
    const matmoyincre = []
    let moy = 0
    let incre = 0

    notesel.forEach((doc) => {
        const currentmatiere = (doc.exo.split("/")[5]);
        const isValuePresent = labels.includes(currentmatiere);

        if (!isValuePresent) {
            labels.push(doc.exo.split("/")[5])

            moy = (doc.note)/5

            matmoy.push(moy);
            matmoyincre.push(1);

        } else {
            const index = labels.indexOf(currentmatiere);

            const ancienmoy = matmoy[index];
            const ancienum = matmoyincre[index];
            const newnum = ancienum + 1;

            const newmoy = (ancienmoy + ((doc.note)/5))/ newnum

            matmoy.splice(index, 1, newmoy);
            matmoyincre.splice(index, 1, newnum);

        }


    });

    matmoy.sort((a, b) => a - b);


    const data = {
        labels,
        datasets: [
            {
                label: 'Moyenne cumulée /20 des matières de '+nomel,
                data: matmoy,
                backgroundColor: 'rgba(65,105,225, 0.2)',
                borderColor: 'rgba(65,105,225, 1)',
                borderWidth: 1,
            },
        ],
    };

    return (

        <Radar data={data}></Radar>
    );
};

export default Radararea;
