import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    Colors
} from 'chart.js';
import { Bar } from 'react-chartjs-2';


ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    Colors,
);


export const MAX_NB_EXOS = 25;

export const colors = [
    {
        foreground: "rgb(28, 179, 35)",
        background: "rgba(28, 179, 35, 0.7)"
    },
    {
        foreground: "rgb(191, 150, 32)",
        background: "rgba(245, 197, 60, 0.7)"
    },
    {
        foreground: "rgb(104, 186, 240)",
        background: "rgba(104, 186, 240, 0.7)"
    },
    {
        foreground: "rgb(50, 50, 50)",
        background: "rgba(200, 200, 200, 0.1)"
    },
]

export const options = {
    responsive: true,
    plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Temps passé',
        },
        tooltip: {
            mode: 'index'
        },
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false
    },
    scales: {
        x: {
            title: {
              display: true,
              text: 'Date'
            }
          },
        y: {
            stacked: true,
            title: {
              display: true,
              text: "Durée (mn)"
            }
          }
      }
};

const DurationHistoryChart = ({durationHistory, matiere, backgroundColor, borderColor}) => {
    if(!durationHistory || Object.keys(durationHistory).length==0){
        return <div className="my-5 text-center">Aucune donnée à afficher</div>;
    }

    const labels = Object.keys(durationHistory);
    const values = [];
    for(let label of labels){
        values.push(durationHistory[label] / 60); // Conversion from seconds to minutes
    }

    const data = {
        labels: labels,
        datasets: [
            {
                label: matiere || 'Global',
                data: values,
                backgroundColor: backgroundColor || 'rgba(65,105,225, 0.4)',
                borderColor: borderColor || 'rgba(65,105,225, 1)',
                borderWidth: 4,
            },
        ],
    };

    return (
        <Bar options={options} data={data}></Bar>
    );
};

export default DurationHistoryChart;
