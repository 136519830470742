import  {React, useEffect, useState} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {onSnapshot, query, where} from "firebase/firestore";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

const Graphiquearea = ({nomel, notesel, classeselect, matsolocompe
                       }) => {
    const labels = [];
    const statscompris = [];
    const statsnoncompris = [];
    const statssuperfi = [];
    let increcomp = 0;
    let increnoncomp = 0;
    let incresuperfi = 0;
    let count = 0;
    let countcomp = 0;
    let countsuperfi = 0;
    let countnoncomp = 0;

    notesel.forEach((doc) => {

        if (matsolocompe.length === 0){

            const currentValue = (doc.date.split(" ")[0]);
            const isValuePresent = labels.includes(currentValue);

            if (!isValuePresent) {
                countcomp = 1;
                countsuperfi = 1;
                countnoncomp = 1;
                increcomp = 0;
                incresuperfi = 0;
                increnoncomp = 0;
                labels.push(doc.date.split(" ")[0])

                if (doc.note >= 80){
                    increcomp = increcomp +  1;

                    statscompris.push(increcomp);
                    statsnoncompris.push(increnoncomp);
                    statssuperfi.push(incresuperfi);

                }else if (doc.note < 80 && doc.note >= 50){

                    incresuperfi = incresuperfi +  1;

                    statssuperfi.push(incresuperfi);
                    statscompris.push(increcomp);
                    statsnoncompris.push(increnoncomp);

                }else{

                    increnoncomp = increnoncomp +  1;

                    statscompris.push(increcomp);
                    statsnoncompris.push(increnoncomp);
                    statssuperfi.push(incresuperfi);

                }

            } else {
                const index = labels.indexOf(currentValue);

                if (doc.note >= 80){
                    countcomp = countcomp + 1;
                    statscompris.splice(index, 1, countcomp)
                }else if (doc.note < 80 && doc.note >= 50){
                    countsuperfi = countsuperfi + 1;
                    statssuperfi.splice(index, 1, countsuperfi)
                }else{
                    countnoncomp = countnoncomp + 1;
                    statsnoncompris.splice(index, 1, countnoncomp)
                }

            }

        }else {
            const matnow = (doc.exo.split("/")[5]);

            if (matnow === matsolocompe){

                const currentValue = (doc.date.split(" ")[0]);
                const isValuePresent = labels.includes(currentValue);

                if (!isValuePresent) {
                    countcomp = 1;
                    countsuperfi = 1;
                    countnoncomp = 1;
                    increcomp = 0;
                    incresuperfi = 0;
                    increnoncomp = 0;
                    labels.push(doc.date.split(" ")[0])

                    if (doc.note >= 80){
                        increcomp = increcomp +  1;

                        statscompris.push(increcomp);
                        statsnoncompris.push(increnoncomp);
                        statssuperfi.push(incresuperfi);

                    }else if (doc.note < 80 && doc.note >= 50){

                        incresuperfi = incresuperfi +  1;

                        statssuperfi.push(incresuperfi);
                        statscompris.push(increcomp);
                        statsnoncompris.push(increnoncomp);

                    }else{

                        increnoncomp = increnoncomp +  1;

                        statscompris.push(increcomp);
                        statsnoncompris.push(increnoncomp);
                        statssuperfi.push(incresuperfi);

                    }

                } else {
                    const index = labels.indexOf(currentValue);

                    if (doc.note >= 80){
                        countcomp = countcomp + 1;
                        statscompris.splice(index, 1, countcomp)
                    }else if (doc.note < 80 && doc.note >= 50){
                        countsuperfi = countsuperfi + 1;
                        statssuperfi.splice(index, 1, countsuperfi)
                    }else{
                        countnoncomp = countnoncomp + 1;
                        statsnoncompris.splice(index, 1, countnoncomp)
                    }

                }

            }else {

                return false;
            }


        }




    });



    const data = {
        labels,
        datasets: [
            {
                fill: true,
                label: 'Exos Superficiels',
                data: statssuperfi,
                borderColor: 'rgb(65,105,225)',
                backgroundColor: 'rgba(65,105,225, 0.5)',
            },
            {
                fill: true,
                label: 'Exos Compris',
                data: statscompris,
                borderColor: 'rgb(176, 242, 182)',
                backgroundColor: 'rgba(176, 242, 182, 0.5)',
            },
            {
                fill: true,
                label: 'Exos non compris',
                data: statsnoncompris,
                borderColor: 'rgb(187,11,11)',
                backgroundColor: 'rgba(187,11,11, 0.5)',
            },

            /*
                        {
                            fill: true,
                            label: 'Stat Non faits',
                            data: [100, 50, 14, 25, 45, 66, 78, 96, 150, 22, 12, 13],
                            borderColor: 'rgb(250,128,114)',
                            backgroundColor: 'rgba(250,128,114, 0.5)',
                        },*/

        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Graphique de compétence de '+nomel,
            },
        },
    };

    return (

        <Line options={options} data={data} />

    );
};

export default Graphiquearea;
