import React, { useEffect, useState } from "react";
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {Link} from "react-router-dom";

const Register = ({ history }) => {
  window.scrollTo(0, 0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();

  };
  return (
    <>
      <Container>
        <Row className="justify-content-md-center" style={{ paddingTop:"50px" }}>
          <Col sm={4} className="imgback">
            <Image src="/images/back.png" fluid/>
          </Col>

          <Col sm={4}>
            <div
                className="card mx-auto"
                style={{ maxWidth: "380px" , border:"none" }}
            >
              <div className="card-body">
                <Image style={{ width:"40%" , marginBottom:"30px" }} src="/images/logo.png" fluid />

                <h4 className="card-title">Complétez vos informations</h4>

                <form onSubmit={submitHandler}>
                  <div className="form-floating" style={{ marginTop:"10px" }}>
                    <input
                        className="form-control"
                        type="text"
                        id="name"
                    />
                    <label style={{ color:"green", fontSize:"16px"}} htmlFor="name">Nom d'utilisateur</label>
                  </div>

                  <div className="form-floating" style={{ marginTop:"10px" }}>
                    <input
                        className="form-control"
                        type="text"
                        id="surname"
                    />
                    <label style={{ color:"green", fontSize:"16px"}} htmlFor="surname">Prénom de l'utilisateur</label>
                  </div>

                  <div className="form-floating" style={{ marginTop:"10px" }}>
                    <input
                        className="form-control"
                        type="date"
                        id="age"
                    />
                    <label style={{ color:"green", fontSize:"16px"}} htmlFor="age">Entrez votre date de naissance</label>
                  </div>

                  <div className="form-floating" style={{ marginTop:"10px" }}>
                    <input
                        className="form-control"
                        type="text"
                        id="sexe"
                    />
                    <label style={{ color:"green", fontSize:"16px"}} htmlFor="sexe">Sexe ( M / F )</label>
                  </div>

                  <div className="form-floating" style={{ marginTop:"10px" }}>
                    <input
                        className="form-control"
                        type="email"
                        id="email"
                    />
                    <label style={{ color:"green", fontSize:"16px"}} htmlFor="email">Adresse mail</label>
                  </div>

                  <div style={{ marginTop:"30px" , marginBottom:"20px" }}>
                    <button type="submit" className="btn btn-primary w-100">
                      S'inscrire
                    </button>
                  </div>
                </form>
                <span > <strong>Déjà inscrit ?</strong> <Link to="/login">Connectez-vous</Link> </span>

              </div>
            </div>
          </Col>

        </Row>




      </Container>


    </>
  );
};

export default Register;
