import React, { useEffect } from "react";
import "./App.css";
import "./responsive.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import Login from "./screens/LoginScreen";
import PrivateRouter from "./PrivateRouter";
import Register from "./screens/RegisterScreen";
import { AuthProvider } from "./screens/Auth.js";
import HelpScreen from "./screens/HelpScreen";
import SuperEcole from "./components/demiPage/SuperEcole.js";
import ProfilScreen from "./screens/ProfilScreen";
import HomeScreenTeacher from "./screens/HomeScreenTeacher";
import HomeScreenEleve from "./screens/HomeScreenEleve";
import HomeScreenAdmin from "./screens/HomeScreenAdmin";
import HomeScreenSuperAdmin from "./screens/HomeScreenSuperAdmin";
import HomeInter from "./screens/HomeInter";
import SuperTeacher from "./components/demiPage/SuperTeacher";
import SuperClasse from "./components/demiPage/SuperClasse";
import SuperEleve from "./components/demiPage/SuperEleve";
import SuperAdmin from "./components/demiPage/SuperAdmin";
import HelpScreenSuper from "./screens/HelpScreenSuper";
import SuperUser from "./components/demiPage/SuperUser";
import SuperUserTotal from "./components/demiPage/SuperUserTotal";


function App() {

  return (
    <>
      <AuthProvider>
        <Router>
          <PrivateRouter path="/" component={HomeInter} exact />
          <PrivateRouter path="/teacher/:idecole/:idclasse" component={HomeScreenTeacher} exact />
          <PrivateRouter path="/eleve/:idecole/:ideleve/:idclasse" component={HomeScreenEleve} exact />
          <PrivateRouter path="/admin/:idecole" component={HomeScreenAdmin} exact />
          <PrivateRouter path="/superadmin" component={HomeScreenSuperAdmin} exact />
          <PrivateRouter path="/superadmin/ecole" component={SuperEcole} exact />
          <PrivateRouter path="/superadmin/teacher" component={SuperTeacher} exact />
          <PrivateRouter path="/superadmin/admin" component={SuperAdmin} exact />
          <PrivateRouter path="/superadmin/classe" component={SuperClasse} exact />
          <PrivateRouter path="/superadmin/eleve" component={SuperEleve} exact />
          <PrivateRouter path="/superadmin/users" component={SuperUser} exact />
          <PrivateRouter path="/superadmin/usersTotal" component={SuperUserTotal} exact />
          <PrivateRouter path="/ce" component={HomeScreen} exact />
          <PrivateRouter path="/help" component={HelpScreen} />
          <PrivateRouter path="/superadmin/helpsuper" component={HelpScreenSuper} />
          <PrivateRouter path="/profil" component={ProfilScreen} />
          <PrivateRouter path="/profil/:id/edit" component={ProfilScreen}/>
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
        </Router>
      </AuthProvider>
    </>
  );
}

export default App;
