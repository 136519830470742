import React, {useContext} from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {AuthContext} from "./screens/Auth.js";

const PrivateRouter = ({ component: RouteComponent, ...rest }) => {
    const {firebaseUser} = useContext(AuthContext);
    return (
        <Route
            {...rest}
            render={routeProps =>
                !!firebaseUser ? (
                    <RouteComponent {...routeProps} />
                ) : (
                    <Redirect to={"/login"} />
                )
            }
        />
    );
};

export default PrivateRouter;
