import * as React from "react";
import {useState, useEffect, Fragment, useContext, Component} from 'react';
import Header from "../../components/Header";
import Sidebar from "../../components/sidebarsuper";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import {Link, NavLink, Redirect} from "react-router-dom";
import {Card, Form, Tab, Tabs} from "react-bootstrap";

import Table from 'react-bootstrap/Table';

import Spinner from 'react-bootstrap/Spinner';

import {auth} from "../../screens/base";
import {db} from "../../screens/base";
import {
    doc,
    onSnapshot,
    updateDoc,
    setDoc,
    deleteDoc,
    collection,
    serverTimestamp,
    getDocs,
    query,
    where,
    orderBy,
    limit,
} from 'firebase/firestore';
import {AuthContext, useUserAuth} from "../../screens/Auth";
import {useFirestoreDocument, useFirestoreQuery} from "@react-query-firebase/firestore";
import PhoneNumber from "libphonenumber-js";
import Sidebarsuper from "../../components/sidebarsuper";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'
import {toast, ToastContainer} from "react-toastify";
import {v4 as uuidv4} from 'uuid';

class SuperClasse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            ecoles: [],
            ecolesid: [],
            showcreate: false,
            showedit: false,
            showview: false,
            showdelete: false,
            classes: [],
            userid: "",
            roles: [],
            loading: true,
            trouve: false,


            nom : "",
            enseignants : [],
            eleves : [],
            ecole : "",


            nomv : "",
            enseignantsv : [],
            elevesv : [],
            ecolev : "",

            iddelete: "",
            idecoledelete: "",
            idupdate: "",
            idecoleupdate: "",

        };
    }

    componentDidMount() {
        const colletionRefusers = collection(db, 'utilisateurs');
        const colletionRefecole = collection(db, 'ecoles');

        const user = auth.currentUser;
        const parsedNumber = PhoneNumber(user.phoneNumber);
        const countryCode = parsedNumber.country;
        const allNumber = parsedNumber.number;
        const nationalNumber = parsedNumber.nationalNumber;
        const userid = ""

        const qecole = query(
            colletionRefecole);

        onSnapshot(qecole, (querySnapshot) => {
            const ecoles = [];
            querySnapshot.forEach((doc) => {
                ecoles.push([doc.id,doc.data()]);
            });
            this.setState({ecoles});

            const classes = [];
            ecoles.forEach((ecol) => {

                onSnapshot(query( collection(db, 'ecoles/'+ecol[0]+'/classes/') ), (querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        classes.push([ecol[0],ecol[1].nom, doc.data(), doc.id]);
                    });
                    this.setState({classes});
                    this.setState({loading : false})
                });

            });

            if (this.state.classes.length){
                this.setState({trouve : true})
            }else {
                this.setState({trouve : false})
            }

        });

    }


    handleClosecreate = () => {
        this.setState({showcreate: false})
    }

    handleCloseview = () => {
        this.setState({showview: false})
    }

    handleCloseedit = () => {
        this.setState({showedit: false})
    }

    handleClosedelete = () => {
        this.setState({showdelete: false})
    }

    handleShowcreate = () => {
        this.setState({ showcreate: true });
    }

    handleShowview = (classe, ecole) => {
        this.setState({nomv : classe.nom})
        this.setState({elevesv: classe.eleves})
        this.setState({ecolev: ecole})

        const ensei = []
        this.setState({enseignantsv: ensei})
        classe.enseignants.forEach((id) => {

            onSnapshot(query( collection(db, 'utilisateurs'), where('uid' ,'==', id) ), (querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    ensei.push(doc.data().nom);
                });
                this.setState({enseignantsv: ensei})
            });

        });

        const elev = []
        this.setState({elevesv: elev})
        classe.eleves.forEach((id) => {

            onSnapshot(query( collection(db, 'utilisateurs'), where('uid' ,'==', id) ), (querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    elev.push(doc.data().nom);
                });
                this.setState({elevesv: elev})
            });

        });

        this.setState({ showview: true });
    }

    handleShowdelete = (iddelete, idecoledelete) => {
        this.setState({iddelete})
        this.setState({idecoledelete})
        this.setState({ showdelete: true });
    }

    handleShowedit = (classe, ecole, idupdate, idecoleupdate) => {
        this.setState({nom : classe.nom})
        this.setState({enseignants: this.state.enseignants})
        this.setState({eleves: this.state.eleves})
        this.setState({ecole: ecole})

        this.setState({ idupdate: idupdate });
        this.setState({ idecoleupdate: idecoleupdate });
        this.setState({ showedit: true });
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            [name]: value
        });
    }


    // ADD FUNCTION
    addclasse = async (event) => {

        const idecole = this.state.ecole

        const colletionRefclasse = collection(db, 'ecoles/'+idecole+'/classes');
        const id = uuidv4();

        const newclasse = {
            id : id,
            nom : this.state.nom,
            eleves : this.state.eleves,
            enseignants:  this.state.enseignants,
        };

        try {

            if (newclasse.nom === ""){
                toast.error('Le nom de la classe est requis');
            } else {
                const classeRef = doc(colletionRefclasse, id);
                await setDoc(classeRef, newclasse);
                toast.success('Félicitations classe créée');
                this.handleClosecreate();
                window.location.reload();
            }

        } catch (error) {
            console.error(error);
            this.handleClosecreate();
            toast.error('Une erreur est survenu');
        }

    }

    //DELETE FUNCTION
    deleteclasse = async (event) => {
        const colletionRefclasse = collection(db, 'ecoles/'+this.state.idecoledelete+'/classes');

        try {
            if (this.state.iddelete === "0"){
                toast.error("La classe n\'a pas été trouvé");
            } else {
                const classeRef = doc(colletionRefclasse, this.state.iddelete);
                await deleteDoc(classeRef);
                toast.success('Classe supprimée correctement');
                this.handleClosedelete();
                window.location.reload();
            }

        } catch (error) {
            console.error(error);
            this.handleClosedelete();
            toast.error('Une erreur est survenue');
        }
    }


    // EDIT FUNCTION
    editclasse = async (event) => {
        const colletionRefclasse = collection(db, 'ecoles/'+this.state.idecoleupdate+'/classes');

        const updatedclasse = {
            nom : this.state.nom,
            eleves : this.state.eleves,
            enseignants:  this.state.enseignants
        };

        try {

            const classeRef = doc(colletionRefclasse, this.state.idupdate);
            await updateDoc(classeRef, updatedclasse);

            toast.success('classe Mise à jour');
            this.handleCloseedit();
            window.location.reload();

        } catch (error) {
            console.error(error);
            this.handleCloseedit();
            toast.error('Une erreur est survenue');
        }
    }




    render() {

        return (
            <div>
                <ToastContainer />
                <Sidebarsuper/>

                <main className="main-wrap">
                    <Header history={this.props.history}/>

                    <Container>
                        <Row >
                            <Col sm={12}>
                                <h2 className="title">Liste des Classes
                                    <Button style={{ marginRight:"5px" }} variant="outline-success" onClick={this.handleShowcreate}>
                                        <i className="fa fa-plus"></i> Créer une classe
                                    </Button>
                                </h2>
                            </Col>

                            <Modal show={this.state.showcreate} scrollable centered onHide={this.handleClosecreate}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Créer une classe</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>

                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Nom</Form.Label>
                                            <Form.Control
                                                type="text"
                                                autoFocus
                                                value={this.state.nom}
                                                onChange={this.handleChange}
                                                name="nom"
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Ecole</Form.Label>
                                            <Form.Select value={this.state.ecole} onChange={this.handleChange} name="ecole">
                                                <option>--Choisir--</option>
                                                {this.state.ecoles?.map((ecole) => (
                                                    <option value={ecole[0]}>{ecole[1].nom}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>

                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="danger" onClick={this.handleClosecreate}>
                                        Fermer
                                    </Button>
                                    <Button variant="primary" onClick={this.addclasse}>
                                        Enregistrer
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                        </Row>

                        <Row className="justify-content-md-center" style={{paddingTop: "80px"}}>

                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th style={{ color:"black" }}>N°</th>
                                    <th style={{ color:"black" }}>Nom</th>
                                    <th style={{ color:"black" }}>Ecole</th>
                                    <th style={{ color:"black" }}>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.loading ? <Spinner animation="border" variant="success" /> : null}

                                {this.state.classes.length ? (
                                    <>
                                        {this.state.classes.map((classe, index) => (

                                            <tr>
                                                <td>{index+1}</td>
                                                <td>{classe[2].nom}</td>
                                                <td>{classe[1]}</td>
                                                <td>
                                                    <Button style={{ marginRight:"5px" }} variant="outline-primary" onClick={async () => this.handleShowview(classe[2], classe[1])}>
                                                        <i className="fa fa-eye"></i>
                                                    </Button>
                                                    <Button style={{ marginRight:"5px" }} variant="outline-info" onClick={async () => this.handleShowedit(classe[2], classe[1], classe[3], classe[0] )}>
                                                        <i className="fa fa-edit"></i>
                                                    </Button>
                                                    <Button style={{ marginRight:"5px" }} variant="outline-danger" onClick={async () => this.handleShowdelete(classe[3], classe[0]) }>
                                                        <i className="fa fa-trash"></i>
                                                    </Button>

                                                </td>
                                            </tr>

                                        ))}
                                    </>
                                ):(
                                    <>

                                        <h1  style={{ display: this.state.trouve ? "block" : "none" }} >Classes non trouvés</h1>

                                    </>
                                )

                                }

                                </tbody>
                            </Table>


                            <Modal show={this.state.showview} size="lg" scrollable centered onHide={this.handleCloseview}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Détails de la classe</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row className="justify-content-md-center">
                                        <Col sm={3}>
                                            <Card
                                                className="mb-2 mt-4"
                                            >
                                                <Card.Header className="text-center text-white" style={{ backgroundColor:"darkgreen"}}><Card.Title> Nom de la classe </Card.Title></Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className="text-center" sm={12}>
                                                                <span>{this.state.nomv}</span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col sm={3}>
                                            <Card
                                                className="mb-2 mt-4"
                                            >
                                                <Card.Header className="text-center text-white" style={{ backgroundColor:"darkgreen"}}><Card.Title> Nom de l'école </Card.Title></Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className="text-center" sm={12}>
                                                                <span>{this.state.ecolev}</span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col sm={3}>
                                            <Card
                                                className="mb-2 mt-4"
                                            >
                                                <Card.Header className="text-center text-white" style={{ backgroundColor:"darkgreen"}}><Card.Title> Elèves </Card.Title></Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className="text-center" sm={12}>
                                                                <span>{JSON.stringify(this.state.elevesv)}</span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col sm={3}>
                                            <Card
                                                className="mb-2 mt-4"
                                            >
                                                <Card.Header className="text-center text-white" style={{ backgroundColor:"darkgreen"}}><Card.Title> Enseignants </Card.Title></Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col className="text-center" sm={12}>
                                                                <span>{JSON.stringify(this.state.enseignantsv)}</span>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>

                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="danger" onClick={this.handleCloseview}>
                                        Retour
                                    </Button>
                                </Modal.Footer>
                            </Modal>


                            <Modal show={this.state.showdelete} centered onHide={this.handleClosedelete}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Etes vous sûr de supprimer cette classe ? </Modal.Title>
                                </Modal.Header>
                                <Modal.Footer>
                                    <Button variant="danger" onClick={this.handleClosedelete}>
                                        Non
                                    </Button>
                                    <Button variant="success" onClick={this.deleteclasse}>
                                        Oui
                                    </Button>
                                </Modal.Footer>
                            </Modal>


                            <Modal show={this.state.showedit} scrollable centered onHide={this.handleCloseedit}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Mise à jour de la classe</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Nom</Form.Label>
                                            <Form.Control
                                                type="text"
                                                autoFocus
                                                value={this.state.nom}
                                                onChange={this.handleChange}
                                                name="nom"
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Ecole</Form.Label>
                                            <Form.Select value={this.state.ecole} onChange={this.handleChange} name="ecole">
                                                <option>--Choisir--</option>
                                                {this.state.ecoles?.map((ecole) => (
                                                    <option value={ecole[0]}>{ecole[1].nom}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>


                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="danger" onClick={this.handleCloseedit}>
                                        Fermer
                                    </Button>
                                    <Button variant="primary" onClick={this.editclasse}>
                                        Enregistrer
                                    </Button>
                                </Modal.Footer>
                            </Modal>


                        </Row>


                    </Container>


                </main>
            </div>
        );
    }

}

export default SuperClasse;
