import * as React from "react";
import {Component} from "react";
import Header from "../../components/Header";
import Sidebarsuper from "../../components/sidebarsuper";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Form} from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import DataTableBase from '../DataTableBase';
import {
    FirebaseProvider,
    ECOLES_COLLECTION_PATH,
} from "../../providers/FirebaseProvider";
import {Ecole} from '../../models/Ecole';


class SuperEcole extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ecoles: [],
            currentEcole: new Ecole(),
            shouldShowModal: false,
            modalEditable: false,
            shouldShowDelete: false,
        };

        this.dataTableCols = [
            {
                name: 'Abbréviation',
                selector: row => row.sigle,
            },
            {
                name: 'Nom',
                selector: row => row.nom,
                grow: 2,
            },
            {
                name: 'Niveau',
                selector: row => row.niveau,
            },
            {
                name: 'Type',
                selector: row => row.type,
            },
            {
                name: 'Pays',
                selector: row => row.pays,
            },
            {
                name: 'Ville',
                selector: row => row.ville,
            },
            // {
            //     name: 'Site web',
            //     selector: row => row.site_web,
            // },
            {
                name: 'Actions',
                grow: 1.5,
                selector: row => {
                    return (
                        <div className="action-btns">
                            <Button variant="success" onClick={async () => this.showModal(row, false)}>
                                <i className="fa fa-eye"></i>
                            </Button>
                            <Button variant="secondary" onClick={async () => this.showModal(row, true)}>
                                <i className="fa fa-edit"></i>
                            </Button>
                            <Button variant="danger" onClick={async () => this.showDeleteModal(row)}>
                                <i className="fa fa-trash"></i>
                            </Button>
                        </div>
                    );
                }
            }
        ];
    }

    componentDidMount() {
        FirebaseProvider.getAllDocsInCollection(ECOLES_COLLECTION_PATH).then((ecolesObj) => {
            let ecoles = [];
            let ecole = null;
            for(let ecoleId in ecolesObj){
                ecole = new Ecole(ecolesObj[ecoleId]);
                ecole.ref = ECOLES_COLLECTION_PATH + '/' + ecoleId;

                ecoles.push(ecole);
            }

            this.setState({ecoles: ecoles});
        });
    }

    showModal = (ecole, editable) => {
        this.setState({
            currentEcole: ecole,
            shouldShowModal: true,
            modalEditable: editable
        });
    }

    closeModal = () => {
        this.setState({ shouldShowModal: false })
    }

    closeDeleteModal = () => {
        this.setState({ shouldShowDelete: false })
    }

    showDeleteModal = (ecole) => {
        this.setState({ currentEcole: ecole })
        this.setState({ shouldShowDelete: true });
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        const ecole = this.state.currentEcole;
        ecole[name] = value;

        this.setState({
            currentEcole: ecole
        });
    }

    saveEcole = async () => {
        const creation = !this.state.currentEcole.ref;
        let successMessage = "Ecole mise à jour avec succès";
        if(creation){
            successMessage = "Ecole créée avec succès";
        }

        try {
            await this.state.currentEcole.save();
            const ecoles = [...this.state.ecoles];
            if(creation){
                ecoles.push(this.state.currentEcole);
            }

            this.setState({ ecoles: ecoles });
            toast.success(successMessage);
            this.closeModal();
        } catch (error) {
            console.error(error);
            toast.error('Une erreur est survenue');
        }
    }

    deleteEcole = async () => {
        const ecole = this.state.currentEcole;

        if(!ecole){
            return;
        }

        if(!ecole.ref){
            if(!ecole.id){
                return;
            }

            ecole.ref = ECOLES_COLLECTION_PATH + '/' + ecole.id;
        }

        try {
            const res = await ecole.delete();
            if(res){
                const ecoles = this.state.ecoles.filter((item) => {
                            const eq = (item.ref && item.ref === ecole.ref)
                                || (item.id && item.id === ecole.id);

                            return !eq;
                        }
                    );
                
                this.setState({ ecoles: ecoles });
                toast.success("Ecole supprimée avec succès");
            } else {
                toast.error('Une erreur est survenue');
            }
            this.closeDeleteModal();
        } catch (error) {
            console.error(error);
            toast.error('Une erreur est survenue');
        }
    }


    render() {
        return (
            <div>
                <ToastContainer />
                <Sidebarsuper/>

                <main className="main-wrap">
                    <Header history={this.props.history}/>

                    <Container>
                        <Row className="welcome-greeting">
                            <Col sm={9}>
                                <h2 className="title">Liste des écoles</h2>
                            </Col>

                            <Col sm={3}>
                                <Button style={{ marginRight:"5px" }} variant="outline-success" className="float-end" onClick={async () => this.showModal(new Ecole(), true)}>
                                    <i className="fa fa-plus"></i> Créer une école
                                </Button>
                            </Col>
                        </Row>

                        <div className="justify-content-md-center" style={{paddingTop: "80px"}}>

                            <DataTableBase style={{marginBottom: "40px"}}
                                columns={this.dataTableCols}
                                data={this.state.ecoles}
                            />

                            <Modal show={this.state.shouldShowDelete && this.state.currentEcole} centered onHide={this.closeDeleteModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Confirmation</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>
                                        Etes vous sûr de supprimer l'école&nbsp; 
                                        <span className="fw-bold text-sucess">
                                            {this.state.currentEcole.nom}
                                        </span>
                                        &nbsp;?
                                    </p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="success" onClick={this.closeDeleteModal}>
                                        Non
                                    </Button>
                                    <Button variant="danger" onClick={this.deleteEcole}>
                                        Oui
                                    </Button>
                                </Modal.Footer>
                            </Modal>


                            <Modal show={this.state.shouldShowModal} size="lg" scrollable centered onHide={this.closeModal}>
                                <Modal.Header closeButton closeVariant="white" className="bg-success text-white">
                                    <Modal.Title className="fw-bold">{this.state.currentEcole && this.state.currentEcole.ref ? this.state.currentEcole.nom : "Créer école"}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form id="form-ecole" onSubmit={(e) => {e.preventDefault(); this.saveEcole();}} action="#">
                                        <Form.Group className="mb-3">
                                            <Form.Label className="fw-bold">Nom</Form.Label>
                                            <Form.Control
                                                type="text"
                                                autoFocus
                                                value={this.state.currentEcole.nom}
                                                onChange={this.handleChange}
                                                disabled={!this.state.modalEditable}
                                                name="nom"
                                                required
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="fw-bold">Abbréviation</Form.Label>
                                            <Form.Control
                                                type="text"
                                                autoFocus
                                                value={this.state.currentEcole.sigle}
                                                onChange={this.handleChange}
                                                disabled={!this.state.modalEditable}
                                                name="sigle"
                                                required
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="fw-bold">Pays</Form.Label>
                                            <Form.Control
                                                type="text"
                                                autoFocus
                                                value={this.state.currentEcole.pays}
                                                onChange={this.handleChange}
                                                disabled={!this.state.modalEditable}
                                                name="pays"
                                                required
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="fw-bold">Ville</Form.Label>
                                            <Form.Control
                                                type="text"
                                                autoFocus
                                                value={this.state.currentEcole.ville}
                                                onChange={this.handleChange}
                                                disabled={!this.state.modalEditable}
                                                name="ville"
                                                required
                                            />
                                        </Form.Group>                                        
                                        
                                        <Form.Group className="mb-3">
                                            <Form.Label className="fw-bold">Adresse</Form.Label>
                                            <Form.Control
                                                type="text"
                                                autoFocus
                                                value={this.state.currentEcole.adresse}
                                                onChange={this.handleChange}
                                                disabled={!this.state.modalEditable}
                                                name="adresse"
                                            />
                                        </Form.Group>                                        
                                        <Form.Group className="mb-3">
                                            <Form.Label className="fw-bold">Type</Form.Label>
                                            <Form.Select value={this.state.currentEcole.type} onChange={this.handleChange} disabled={!this.state.modalEditable} name="type" required>
                                                <option>--Choisir--</option>
                                                <option value="publique" >Publique</option>
                                                <option value="privee" >Privée</option>
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="fw-bold">Niveau</Form.Label>
                                            <Form.Select value={this.state.currentEcole.niveau} onChange={this.handleChange} disabled={!this.state.modalEditable} name="niveau" required>
                                                <option>--Choisir--</option>
                                                <option value="Primaire" >Primaire</option>
                                                <option value="Secondaire" >Secondaire</option>
                                                <option value="Universitaire" >Universitaire</option>
                                            </Form.Select>
                                        </Form.Group>
                                        {/*
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Niveau</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    autoFocus
                                                    value={this.state.currentEcole.niveau}
                                                    onChange={this.handleChange}
                                                    disabled={!this.state.modalEditable}
                                                    name="niveau"
                                                />
                                            </Form.Group>
                                        */}
                                        <Form.Group className="mb-3">
                                            <Form.Label className="fw-bold">Contact</Form.Label>
                                            <Form.Control
                                                type="text"
                                                autoFocus
                                                value={this.state.currentEcole.contact}
                                                onChange={this.handleChange}
                                                disabled={!this.state.modalEditable}
                                                name="contact"
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="fw-bold">Site web</Form.Label>
                                            <Form.Control
                                                type="text"
                                                autoFocus
                                                value={this.state.currentEcole.site_web}
                                                onChange={this.handleChange}
                                                disabled={!this.state.modalEditable}
                                                name="site_web"
                                            />
                                        </Form.Group>
                                    </Form>
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="danger" onClick={this.closeModal}>
                                        Fermer
                                    </Button>
                                    <Button variant="success" form="form-ecole" type="submit" className={this.state.modalEditable ? "" : "d-none"}>
                                        Enregistrer
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                        </div>


                    </Container>


                </main>
            </div>
        );
    }

}

export default SuperEcole;
